@import './variables';

.task-generic-container {
  .mobile-deadline-box {
    display: none;

    @media (max-width: $mobile) {
      position: absolute;
      right: 12px;
      top: 70px;
      width: 94px;
      height: 50px;
      background: #ebfbf4;
      border: 1px solid #c5e4fd;
      border-radius: 5px;
      font-size: 12px;
      @apply text-blackText;
      padding: 15px;
      text-align: center;
    }

    &.warning {
      background-color: #fae2e6;
    }

    &.info {
      background-color: #fff3d8;
    }

    &.success {
      background-color: #ebfbf4;
    }
  }

  .header-box {
    margin-bottom: 20px;

    .icon {
      margin-right: 20px;
      cursor: pointer;
    }

    label {
      margin: 0px;
      @apply text-blackText flex;
      font-size: 18px;

      .status-box {
        padding: 2px;
        height: 25px;
        font-size: 13px;
        color: white;
        align-self: center;
        margin-left: 10px;
        justify-content: center;
        display: flex;
      }

      &.stuck {
        background: #e2445c;
      }
    }
  }

  .ant-checkbox {
    & + span {
      display: inline-block;
      vertical-align: middle;
    }
  }

  .button {
    padding: 5px;
    width: 90px;
    height: 30px;
    background: #3aa4f3;
    text-align: center;
    border-radius: 23px;
    font-size: 15px;
    color: white;
    line-height: 18px;
    cursor: pointer;
    display: inline-block;
  }

  .tgc-tab-container {
    width: 100%;

    .tab-head {
      border-bottom: solid 1px #d6dee8;
      height: 30.5px;

      @media (max-width: $mobile) {
        margin-bottom: 20px;
      }
    }

    .task-tab-content {
      @media (max-width: $mobile) {
        margin: 0px !important;
      }
    }

    .tab-item {
      @apply text-blackText relative;
      font-size: 15px;
      margin-right: 30px;
      padding: 0px 0px 8px 0px;

      &.active {
        border-bottom: solid 3px #3aa4f3;
      }
    }

    .tgc-tab-content {
      margin-top: 20px;
      padding: 0px !important;
      border-radius: 0px !important;
      box-shadow: none !important;
      // height: 420px !important;
      width: 100% !important;
      overflow: auto;
      display: block;

      height: auto !important;

      @media (max-width: $mobile) {
        height: auto !important;
        overflow: hidden;
      }

      .desc-text {
        border: 1px solid #d0e9fd;
        border-radius: 10px;
        padding: 13px 10px 13px 10px;
        font-size: 13px;
        color: #576271;
        width: 100%;

        ol {
          list-style: decimal;
          padding-left: 12px;
        }
        ul {
          list-style: disc;
          padding-left: 15px;
        }
      }

      .update-editor {
        margin-bottom: 24px;

        &.with-top-divider {
          border-top: 1px solid #d0e9fd;
        }

        textarea {
          min-height: 0px;
          height: 43px;
          background: #ffffff;
          border: 1px solid #c5e4fd;
          border-radius: 10px;
          resize: none;
          transition: 0.3s all ease-in-out;
          width: 100%;

          &.has-content,
          &:focus {
            height: 120px;

            &.long {
              height: 250px;
            }
          }

          &.has-files {
            height: 120px;
            border-bottom: none;
            border-radius: 10px 10px 0px 0px;
          }
        }

        .attachments {
          margin: -6px 0px 0px 0px;
          position: relative;
          z-index: 999;
          border: 1px solid #c5e4fd;
          padding: 10px 10px 0px 10px;
          border-radius: 0px 0px 10px 10px;

          .file-item {
            margin-right: 5px;

            .remove-file {
              width: 10px;
              margin-right: 8px;
              cursor: pointer;
            }
          }
        }

        .ck-editor {
          border: 1px solid #c5e4fd;
          border-radius: 10px;

          .ck-toolbar {
            border-color: #c5e4fd;
            background: transparent;
          }
          .ck-content {
            min-height: 120px;
            border: none;
            box-shadow: none;
            border-radius: 0px 0px 10px 10px;
            border-top: solid 1px #c5e4fd;
          }
        }
      }

      .attachments {
        margin-top: 15px;

        .file-item {
          width: fit-content;
          margin-bottom: 10px;

          &.initial-file {
            background: #eee;
            padding: 3px 10px;
            border-radius: 10px;
            margin-right: 5px;
            max-width: 300px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;

            img {
              margin-right: 5px;
            }
          }

          img {
            margin-right: 8px;
          }

          .file-name {
            max-width: 100px;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
          }

          &.removable {
            background-color: #ebfcf4;
            padding: 10px;
            @apply rounded-full;

            .remove-file {
              width: 10px;
              margin-left: 10px;
              margin-right: 15px;
              cursor: pointer;
            }
          }
        }
      }

      .thread-container {
        border: 1px solid #d0e9fd;
        border-radius: 10px;
        width: 100%;
        height: auto;
        overflow: hidden;
        margin-top: 24px;
        transition: background 0.3s ease-in;

        &.highlighted {
          background: #fed;
        }

        .header {
          padding: 10px;
          display: flex;
          border-bottom: 1px solid #d0e9fd;
          height: 50px;
          .profile-pic {
            width: 30px;
            height: 30px;
            border-radius: 100%;
            overflow: hidden;
          }

          .name {
            @apply text-blackText;
            font-size: 14px;
            padding: 4px 10px;
            display: flex;
          }

          .right-panel {
            margin: 0 0 0 auto;

            .status-box {
              border-radius: 5px;
              padding: 1px;
              height: 20px;
              font-size: 11px;
              color: white;
              align-self: center;
              margin-right: 10px;
              justify-content: center;
              @apply flex;

              img {
                width: 11px;
                height: 11px;
                align-self: center;
                margin: 0px 5px 0px 0px;
              }

              &.submitted {
                background: #336ad3;
              }
              &.revise {
                background: #b553c7;
              }
              &.stuck {
                background: #e2445c;
              }
              &.completed {
                background: #33d390;
                padding: 1px 2px 1px 2px;

                .rate-stars {
                  width: 10px;
                  height: 10px;
                  margin: 0px 2px 0px 2px;
                }
              }
            }

            .timebox {
              margin: 0 15px 0 0;
              color: #aebbc9;
              font-size: 12px;

              span {
                padding-top: 6px;
              }

              img {
                margin-right: 5px;
              }
            }

            .menu {
              cursor: pointer;
              padding: 0px;
              border: none;
            }
          }
        }

        .content {
          padding: 20px;
          color: #576271;
          font-size: 13px;
          border-bottom: 1px solid #d0e9fd;
          white-space: break-spaces;

          a {
            @apply text-qureosBlue block;
          }
        }

        .reply-box {
          border-top: 1px solid #d0e9fd;
          color: #3aa4f3;
          padding: 8px;
          text-align: center;
          display: flex;
          font-size: 14px;

          img {
            margin-right: 7px;
          }
        }

        .message-box {
          background: transparent;
          box-shadow: none;
          align-items: normal;
          margin-top: 10px;
          padding: 10px 0px;

          &.empty {
            display: none;
            padding: 0px;
            border: none;
          }

          .message-item {
            &.sender {
              .message {
                align-self: flex-end;
                border-radius: 10px 10px 0px 10px;
                background: #f3f8fd;

                .user {
                  color: #3aa4f3;
                }
              }

              .time {
                align-self: flex-end;
              }
            }

            .message {
              padding: 5px 9px;
              background: #ebfbf4;
              border-radius: 10px 10px 10px 0px;
              max-width: 400px;
              align-self: flex-start;
              margin: 0px 10px;
              word-break: break-word;

              .user {
                color: #50d498;
                font-size: 10px;
              }

              .text {
                font-size: 13px;
                @apply text-blackText;
                white-space: break-spaces;
              }
            }
            .time {
              align-self: start;
              margin: 0px 10px;
              font-size: 10px;
            }
          }
        }
      }

      .thread-footer-options {
        margin-top: 8px;
        width: 100%;
        justify-content: space-evenly;

        .footer-btn {
          height: 33px;
          background: #aebbc9;
          border-radius: 5px;
          color: white;
          font-size: 15px;
          flex: 1;
          text-align: center;
          padding: 5px;
          cursor: pointer;

          &:first-child {
            margin-right: 11px;
          }

          &.active {
            &.accept {
              background-color: #33d390;
            }
            &.revise {
              background-color: #b553c7;
            }
          }
        }
      }
      .thread-footer-actions {
        @apply flex flex-col;

        textarea {
          min-height: 0px;
          height: 64px;
          border: 1px solid #d0e9fd;
          border-radius: 10px;
          padding: 9px 10px;
          font-size: 13px;
          color: #8898aa;
          background: white;
          resize: none;
          margin: 13px 0px 0px 0px;
        }

        .action-btn {
          @apply bg-qureosBlue;
          border-radius: 23px;
          color: white;
          cursor: pointer;
          padding: 5px;
          text-align: center;
          font-size: 15px;
          margin: 8px 0px 0px 0px;
          align-self: flex-end;
          justify-content: center;
          width: 115px;
        }

        .rate-box {
          margin-top: 15px;

          .title {
            width: 120px;
          }

          .star-container {
            margin: 5px 0px 18px 0px;
            align-self: flex-end;

            .star-box {
              width: 18px;
              height: 18px;
              margin: 0px 6px 0px 0px;

              img {
                height: 100%;
                width: 100%;
              }
            }
          }
        }
      }

      .editor-footer {
        .footer-links {
          padding: 8px 0px 8px 0px;
          font-size: 12px;
          color: #3aa4f3;
          margin-right: 18px;
          display: flex;
          cursor: pointer;
          border: none;
          box-shadow: none;
          outline: none;

          img {
            margin: -5px 8px 0px 0px;
          }
        }

        button {
          &.footer-links {
            img {
              margin: 0px 8px 0px 0px;
            }
          }
        }

        .footer-btn {
          margin-top: 10px;
          margin-left: auto;

          &.cancel-button {
            background: #e9ecef;
            margin-left: 10px;
            @apply text-gray-600;
          }
        }
      }

      .qa-container {
        .header {
          width: 100%;
          margin-bottom: 20px;
          border: 1px solid #d0e9fd;
          border-radius: 10px;
          text-align: center;
          padding: 10px;
          font-size: 14px;
          color: #3aa4f3;

          img {
            margin-right: 9px;
            display: inline-block;
          }
        }

        .qa-item {
          transition: background 0.3s ease-in;

          &.highlighted {
            background: #fed;
            padding: 5px;
          }
        }

        .qa-description {
          border-radius: 10px 10px 0px 0px;
          border: 1px solid #d0e9fd;
          padding: 10px 8px 10px 8px;
          font-size: 13px;
          color: #576271;
        }

        .qa-notes {
          color: #aebbc9;
          font-size: 10px;
          margin: 5px 0px;

          .user {
            color: #3aa4f3;
            margin: 0px 3px;
          }
          .company {
            color: #50d498;
            margin: 0px 3px;
          }

          .dot {
            margin: 0px 8px;
            font-size: 30px;
            line-height: 12px;
          }
        }

        .qa-box {
          min-height: 80px;
          border: 1px solid #d0e9fd;
          border-radius: 10px;
          height: auto;

          &.connectable {
            border-top: none;
            border-radius: 0px 0px 10px 10px;
          }

          .qa-label {
            width: 60px;
            justify-content: center;
            border-right: solid 1px #d0e9fd;

            img {
              margin-bottom: 8px;
            }

            .counter {
              @apply text-blackText;
              font-size: 14px;
              text-align: center;
            }
          }

          .qa-content {
            flex: 1;

            .content {
              @apply relative w-full text-sm;
              min-height: 40px;
              height: auto;

              strong {
                padding: 10px 0px 10px 10px;
              }

              input,
              textarea {
                border: none;
                background: transparent;
                border-radius: 0px;
                color: #3aa4f3;
                resize: none;
                height: 40px;
                min-height: 0;
                padding: 10px;
              }

              .text {
                padding: 10px;
              }

              .edit {
                color: #8898aa;
                font-size: 10px;
                align-self: center;
                text-transform: uppercase;
                cursor: pointer;
                margin: 0px 10px 0 auto;
              }

              &.qa-green {
                color: #50d498;

                .edit-question {
                  color: #50d498;
                }
              }

              &.qa-blue {
                color: #3aa4f3;
              }

              &:first-child {
                border-bottom: solid 1px #d0e9fd;
              }

              .cancel-edit {
                @apply flex rounded-full cursor-pointer;
                margin-right: 10px;
                height: 22px;
                background: #dfdfdf;
                padding: 3px 7px;
                margin-top: 8px;

                img {
                  width: 8px;
                }
              }
            }
          }
        }

        .post-btn {
          margin: 10px 0px 0px auto;
        }
      }
    }
  }

  .submit-form {
    width: 100%;
    margin: 0px;
    overflow: auto;
    height: 465px;

    @media (max-width: $mobile) {
      height: auto;
    }

    .form-content {
      margin: 0px 0px 30px 0px;

      .form-head {
        @apply text-blackText;
        font-size: 12px;
        margin: 0px 0px 10px 0px;
      }

      .project-file {
        padding: 4px 4px 4px 12px !important;
      }

      textarea {
        border: 1px solid #d0e9fd;
        border-radius: 10px;
        width: 100;
        height: 64px;
        padding: 10px;
        font-size: 13px;
        color: #8898aa;
        resize: none;
        background: #fff;
        width: 100%;
      }

      .submit-btn {
        margin: 8px 0px 0px auto;
      }

      .file-selector {
        width: 160px;

        @media (max-width: $mobile) {
          width: 100%;
          height: auto;
        }

        .file-list {
          .ant-checkbox-wrapper {
            margin-left: 0px;

            .ant-checkbox {
              margin-top: 5px;
            }

            .ant-checkbox-checked {
              &::after {
                border: none;
              }
            }

            & > span {
              margin-bottom: 0;

              &:last-child {
                margin-top: -4px;
              }
            }
          }

          .file-viewer {
            width: auto;
            border-radius: 0px;
            box-shadow: none;

            .files {
              padding: 0px;
            }

            .project-file {
              padding: 0px 5px 0px 5px;
              font-size: 10px;
              line-height: 17px;
              color: #3aa4f3;
              margin: 3px 0px 0px 5px;
            }
          }
        }
      }

      .dropzone {
        width: 200px;
        height: 75px;
        border: 1px solid #c5e4fd;
        border-radius: 5px;
        justify-content: center;
        margin: 0px 0px 0px auto;
        padding: 12px;

        @media (max-width: $mobile) {
          width: 100%;
        }

        img {
          margin: 0px 0px 3px 0px;
        }

        .text {
          color: #576271;
          font-size: 11px;
          text-align: center;
        }
      }
    }
  }
}
@import './variables';

.thread-container {
  @apply w-full mx-auto;
}

.tab-content {
  &.auto-height {
    height: auto !important;
  }
}

.thread-wrapper {
  max-height: 250px;
  @apply overflow-auto relative;
  scroll-behavior: smooth;
}

.thread {
  .message {
    @apply mb-4;
    width: fit-content;

    .message-content {
      background-color: #ebfbf4;
      padding: 4px 9px;
      @apply text-sm rounded-xl;

      .sender {
        color: #50d498;
        @apply text-xs font-semibold;
      }

      .file {
        @apply flex;
        margin-bottom: 8px;
        margin-top: 3px;

        img {
          margin-right: 8px;
        }
      }
    }

    .time {
      @apply text-xs;
      margin-top: 3.5px;
      color: #aebbc9;
    }

    &.iam {
      @apply self-end;

      .message-content {
        background-color: #f3f8fd;

        .sender {
          color: #3aa4f3;
        }

        .text {
          a {
            @apply text-qureosBlue;
          }
        }
      }

      .time {
        @apply text-right;
      }
    }
  }
}

.thread-attachments {
  .file-item {
    background: #f3f8fd;
    padding: 4px 10px;
    border-radius: 20px;

    .remove-file {
      width: 12px;
      margin-right: 15px;
      @apply cursor-pointer;
    }
  }
}

.tab-content {
  &.auto-height {
    height: auto !important;
  }
}

.thread-wrapper {
  max-height: 250px;
  @apply overflow-auto relative;
  scroll-behavior: smooth;
}

.thread {
  .message {
    @apply mb-4;
    width: fit-content;

    .message-content {
      background-color: #ebfbf4;
      padding: 4px 9px;
      @apply text-sm rounded-xl;

      .sender {
        color: #50d498;
        @apply text-xs font-semibold;
      }

      .file {
        @apply flex;
        margin-bottom: 8px;
        margin-top: 3px;

        img {
          margin-right: 8px;
        }
      }
    }

    .time {
      @apply text-xs;
      margin-top: 3.5px;
      color: #aebbc9;
    }

    &.iam {
      @apply self-end;

      .message-content {
        background-color: #f3f8fd;

        .sender {
          color: #3aa4f3;
        }

        .text {
          a {
            @apply text-qureosBlue;
          }
        }
      }

      .time {
        @apply text-right;
      }
    }
  }
}

.thread-attachments {
  .file-item {
    background: #f3f8fd;
    padding: 4px 10px;
    border-radius: 20px;

    .remove-file {
      width: 12px;
      margin-right: 15px;
      @apply cursor-pointer;
    }
  }
}
