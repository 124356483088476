@import './variables';

$wizard-gray: #b0bbc8;
$wizard-light-gray: #8898aa;
$wizard-dirty-white: #eaeaea;
$wizard-blue: #34a5f3;
$wizard-light-blue: #d0e9fd;
$wizard-white-blue: #e8f5ff;
$wizard-step-box-size: 140px;

.wizard-container {
  @apply flex relative justify-center;
  width: 100%;
  // width: calc(100% - 200px);
  left: 0;
  height: auto;
  min-height: 460px;
  // left: -$wizard-step-box-size;

  @media (max-width: $tablet) {
    left: 0px;
    flex-direction: column;
    margin: 0px !important;
    overflow: hidden;

    .step-box {
      flex-direction: row !important;
      position: relative;
      width: 100% !important;

      &::after {
        content: '';
        position: absolute;
        top: 36px;
        right: 8px;
        // width: 100%;
        height: 3px;
        width: 327px;
        left: 0;
        background: $wizard-gray;
        transition: all 0.2s ease-in;
      }

      .step-item {
        flex-direction: column;
        font-size: 14px !important;
        margin-right: 1px;
        padding: 0px 6px;
        margin-bottom: 15px !important;

        .step-indicator {
          position: relative;
          z-index: 2;
          background: #f5f6f7 !important;
          margin-top: 6px;
        }

        &::after {
          height: 3px !important;
          width: 100px !important;
          top: 36px !important;
          right: -70px !important;
          z-index: 1;
          display: none;
        }
      }
    }

    .wizard-content {
      margin: 0 auto;
    }
  }

  &.basic {
    left: 0px;

    .wizard-content {
      box-shadow: none;
    }
  }

  .step-box {
    @apply flex flex-col;
    width: $wizard-step-box-size;
    margin-right: 50px;

    .step-item {
      @apply flex justify-end items-center relative;
      color: $wizard-gray;
      font-size: 18px;
      margin-bottom: 36px;
      transition: all 0.2s ease-in;

      @media (max-width: $tablet) {
        &:nth-child(1) {
          padding: 0px 6px 0px 0px;

          .step-indicator {
            left: -16px;
          }
        }
      }

      &.active {
        color: $wizard-blue;

        .step-indicator {
          border-color: $wizard-blue;
          background: transparent;
        }
      }

      &.passed {
        margin-bottom: 50px;
        color: $wizard-blue;
        cursor: pointer;

        &::after {
          background: $wizard-blue;
          height: 60px;
        }

        .step-indicator {
          border-color: $wizard-blue;
          background: $wizard-blue !important;
          padding: 2px 0px;

          .icon {
            display: flex;
          }
        }
      }

      &:last-of-type {
        &::after {
          display: none;
        }
      }

      .step-indicator {
        width: 20px;
        height: 20px;
        border-radius: 20px;
        background: $wizard-gray;
        border: solid 3px $wizard-gray;
        margin-left: 12px;
        transition: all 0.2s ease-in;

        .icon {
          display: none;
        }
      }

      &::after {
        content: '';
        position: absolute;
        top: 24px;
        right: 8px;
        width: 3px;
        height: 45px;
        background: $wizard-gray;
        transition: all 0.2s ease-in;
      }
    }
  }

  &.quick-task-wizard {
    .wizard-content {
      padding: 20px 25px;
    }
  }

  .wizard-content {
    @apply flex flex-col justify-start items-center;
    width: 100%;
    max-width: 869px;
    height: auto;
    min-height: 430px;
    border-radius: 15px;
    box-shadow: #6a708026 0 0 30px;
    background: white;

    .content-icon {
      margin: 30px 0 45px 0;
    }
    .content-title {
      @apply flex font-bold;
      font-size: 18px;
      text-align: center;
    }
    .content-sub-title {
      @apply flex font-light;
      font-size: 15px;
      color: #4d5f72;
    }

    .content-input {
      @apply flex flex-col items-center w-full;

      input {
        @apply font-bold text-qureosBlue text-center;
        width: 100%;
        max-width: 781px;
        border: none;
        border-bottom: solid 1px #c5e4fd;
        font-size: 30px;
        padding: 0 0 18px 0;
        border-radius: 0px;
        margin-bottom: 6px;

        // &::placeholder {
        //   @apply text-qureosBlue;
        // }
      }

      .subtext {
        font-size: 14px;
        color: $wizard-light-gray;
        text-align: center;
      }
    }

    .task-form {
      @apply flex flex-col;
    }

    .form-input {
      @apply flex w-full relative;
      margin-top: 36px;

      .tasks-details-form {
        @apply flex -mt-2;

        @media (max-width: $mobile) {
          @apply flex-col;
        }
      }

      input,
      textarea.autosize {
        @apply flex w-full;
        border-radius: 10px;
        border: solid 1px $wizard-light-blue;
        padding: 14px 12px;
        font-size: 13px;
        color: $wizard-light-gray;
        height: 46px;
        resize: none;
        margin-bottom: 20px;
      }

      input {
        height: 50px;
        margin-bottom: 12px;
      }

      .link-item {
        @apply flex;
        color: #3aa4f3;
        font-size: 12px;
        cursor: pointer;
        border: none;
        box-shadow: none;

        img {
          margin-right: 5px;
        }
      }

      .files-box {
        @apply flex flex-col;
        margin-top: 10px;

        @media (max-width: $mobile) {
          margin-top: -30px;
          margin-bottom: 20px;
        }

        .file-item {
          @apply flex;

          &:hover {
            .file-remove {
              display: flex;
            }
          }

          .file-icon {
            margin-right: 5px;
          }
          .file-name {
            @apply text-qureosBlue;
            font-size: 13px;
            max-width: 200px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }

          .file-remove {
            display: none;
            background: #808080;
            width: 15px;
            height: 15px;
            cursor: pointer;
            border-radius: 20px;
            padding: 4px;
            align-self: center;
            margin-left: 5px;
          }
        }
      }
    }

    .alert-content {
      @apply flex w-full justify-center;
      margin: 20px 0px;
      font-size: 20px;
      color: #e2445c;
    }

    .content-btn {
      @apply text-center cursor-pointer mt-auto font-semibold;
      font-size: 18px;
      min-width: 163px;
      height: 46px;
      cursor: pointer;
      color: white;
      background: $wizard-blue;
      border-radius: 23px;
      transition: all 0.5s ease-in;

      &:disabled {
        background: #808080;
      }
    }

    .save-draft-btn {
      @apply flex cursor-pointer;
      margin-top: 10px;
      font-size: 15px;
      color: #555f6e;
    }

    .content-back {
      @apply flex self-start;

      .back-btn {
        @apply flex cursor-pointer items-center;
        font-size: 15px;

        img {
          width: 5px;
          height: 10px;
          margin-right: 10px;
        }
      }
    }

    .content-full-section {
      @apply flex flex-col relative justify-center items-center;
      padding: 0px 0px 25px 0px;
      width: calc(100% + 50px);
      left: 0px;
      border-bottom: solid 1px $wizard-dirty-white;
      padding: 20px;

      &.no-push-top {
        padding-top: 0px;
      }

      &.push {
        margin-top: 20px;
      }

      &.no-line {
        border: none !important;
      }

      hr {
        border: none;
        border-bottom: solid 1px #eaeaea;
        width: calc(100% + 40px);
        position: relative;
        height: 5px;
        margin-bottom: 20px;

        &.form {
          left: -20px;
          margin-top: 20px;
          width: calc(100% + 40px);
        }
      }

      .title {
        @apply font-bold text-qureosBlue text-center;
        width: 100%;
        font-size: 30px;
      }

      .content-input {
        margin-bottom: -10px;

        input {
          border: none;
        }

        .subtext {
          display: none;
        }
      }
    }

    .form-actions {
      @apply flex text-qureosBlue font-semibold cursor-pointer self-start;
      font-size: 14px;

      img {
        margin-right: 10px;
      }
    }

    .generic-remove-btn {
      @apply flex self-end cursor-pointer;
      width: 20px;
      height: 20px;
      border-radius: 20px;
      background: #808080;
      padding: 5px;
      margin-bottom: 10px;
    }

    .done-box {
      @apply flex flex-col justify-center items-center;

      .title {
        @apply text-qureosBlue font-semibold;
        font-size: 15px;
        margin-bottom: 12px;
      }

      .desc {
        @apply font-light;
        color: #4d5f72;
        font-size: 15px;
      }
    }
  }

  &.quick-task-wizard {
    margin-top: 60px;
  }

  .wizard-category-container {
    margin-top: 32px;
    margin-left: 0px;

    @media (max-width: $mobile) {
      width: 100%;

      .box-selection-container {
        height: auto;
        flex-direction: column;
        align-items: center;
      }
    }

    .template-selection-container {
      margin: 0px;

      .simple-slider {
        .slider-body {
          width: 630px;

          .default-selection {
            @apply flex justify-center items-center font-semibold text-center;
            width: 165px;
            height: 175px;
            border-radius: 10px;
            background: #aebbc9;
            color: white;
            font-size: 15px;
            margin-right: 15px;
            cursor: pointer;
            transition: all 0.2s ease-in;
            flex: none;

            &.active {
              background: $wizard-blue;
            }
          }

          .template-selection {
            width: 215px;
            height: 175px;

            .template-body {
              .title {
                text-overflow: ellipsis;
                width: 140px;
                white-space: nowrap;
                overflow: hidden;
                text-align: left;
              }
            }
          }
        }
      }
    }
  }
}

.quick-task-resource-menu {
  li {
    font-size: 11px;
  }
}

// Effort Picker

.dropdown-menu {
  @apply flex overflow-hidden cursor-pointer;
  border-radius: 10px;
  border: solid 1px #d0e9fd;
  // min-width: 125px;
  width: auto;
  height: 36px;
  margin-right: 20px;
  user-select: none;

  @media (max-width: $mobile) {
    margin-bottom: 10px;
  }
  @media (max-width: $small-mobile) {
    width: 100%;
  }

  &.deadline {
    .icon-item {
      padding: 0px 10px;
    }
  }

  &.effort {
    .icon-item {
      padding: 0px 8px;
    }

    // &.custom {
    height: 50px;
    margin-top: 5px;
    align-items: center;

    .text-item {
      height: 50px;
      align-items: center;
      display: flex;
    }
    // }
  }

  .text-item {
    padding: 7px 11px;
    color: $wizard-light-gray;
    font-size: 13px;
    position: relative;

    .custom-input {
      position: absolute;
      top: 0px;
      left: 0px;
      width: 85px !important;
      height: 100% !important;
      border: none !important;
    }
  }

  .icon-item {
    @apply flex ml-auto justify-center items-center h-full w-auto;
    min-width: 26px;
    background: $wizard-white-blue;
  }
}
