@import './variables';

.corporate-radio {
  .ant-radio-checked {
    .ant-radio-inner {
      border-color: $qrs-primary !important;
    }
    .ant-radio-inner:after {
      background-color: $qrs-primary !important;
    }
  }
  .space-none {
    span {
      top: 0 !important;
    }
  }

  .ant-radio:hover .ant-radio-inner {
    border-color: $qrs-primary !important;
  }

  label span {
    margin-bottom: 0;
  }
}

.no-content .ant-radio-wrapper::after {
  content: none;
}