@import './variables';

.admin-cohort-container {
  @apply flex flex-col w-full bg-white;
  max-width: $qrs-col-8;
  margin: 0 auto;
  padding: 20px;
  border-radius: 5px;

  .ant-steps-icon {
    span[role='img'] {
      position: relative;
      top: -4px;
    }
  }

  .ant-steps {
    margin-bottom: 20px;
  }

  .ant-picker-separator {
    position: relative;
    top: -4px;
  }

  .ant-picker,
  .ant-picker-range {
    width: 100%;
  }

  .ant-btn,
  .ant-select-multiple {
    span[role='img'] {
      position: relative;
      top: -3px;
    }
  }

  .steps-content {
    @apply flex w-full;
    margin: 20px 0px;
    min-height: 200px;
  }

  .steps-action {
    @apply flex;
  }

  .form-container {
    @apply flex flex-wrap w-full;

    &.module {
      border: solid 1px #ccc;
      border-radius: 5px;
      padding: 10px;
      background: #eee;
      margin-bottom: 10px;
    }

    &.program {
      border: solid 1px #eee;
      padding: 10px;
      background: #fff;
      margin: 5px 0px;
    }

    .form-item {
      @apply flex flex-col;
      width: 50%;
      height: fit-content;
      flex: none;
      padding-right: 10px;
      margin-bottom: 10px;

      &.full {
        width: 100%;
      }

      label {
        @apply font-semibold;
        font-size: 16px;
        margin: 0px 0px 10px 0px;
      }

      textarea {
        resize: none;
      }

      .field-box {
        @apply flex;

        .ant-select {
          width: 100%;
        }
      }
    }

    .meeting-item {
      @apply flex flex-col w-full;

      .ant-page-header {
        width: 100%;
      }
    }

    .tags-container {
      margin-top: 10px;

      .ant-tag {
        cursor: pointer;
      }
    }
  }

  .header-container {
    @apply flex;
    margin-bottom: 10px;
  }

  .cohort-page-header {
    margin-left: -20px;
  }

  .ant-card {
    margin-right: 10px;

    .ant-card-cover {
      img {
        height: 100px;
        margin: 10px;
        width: 100%;
        object-fit: scale-down;
      }
    }
  }

  .cohort-project-builder {
    @apply flex flex-col w-full;

    .close-form-btn {
      @apply flex;
      width: fit-content;
    }

    .wizard-container {
      left: 70px;
      margin: 30px;
      max-width: 800px;
    }

    .content-btn {
      justify-content: center !important;
    }

    .content-input {
      input {
        background-color: transparent !important;
      }
    }
  }
}

.modal-cohort-module {
  .ant-modal-content {
  }
}
