@import './variables';

.rewards-page {
  @media (max-width: $mobile) {
    @apply w-full px-4;
    padding-top: 20px;
    text-align: center;

    .gift-cards-container {
      justify-content: center;
    }

    & > .flex-row {
      @apply flex;

      .reward-card {
        width: 200px;
        margin: 0 auto;
        margin-bottom: 20px;

        &:nth-child(even) {
          padding-right: 0px;
          // padding-left: 5px;
        }
      }

      .qrs-reward-card {
        @apply w-full;
      }
    }

    .reward-container {
      display: inline-block;
      padding-left: 16px;
    }

    .header-infobox {
      display: none;

      @media (max-width: $mobile) {
        // display: inline;
      }

      .user-points {
        float: none;
        width: 100%;
        margin-top: 16px;
        padding-bottom: 0px;
      }
    }

    section {
      &.inline-block {
        .reward-card {
          .qrs-reward-card {
            width: 200px;
            margin: 0 auto;
            margin-bottom: 20px;
          }
        }

        & > div {
          width: 200px;
          margin: 0 auto;

          &:nth-child(even) {
            @apply mr-0;
          }
        }
      }
    }
  }

  @media (max-width: $small-mobile) {
    .reward-container {
      display: flex;
      padding-left: 0px;
      justify-content: center;
    }
  }

  .title-section {
    @apply text-black font-semibold;
    font-size: 18px;
    margin-bottom: 25px;
  }
}

.reward-card {
  @apply mb-8;
  width: auto;
  height: auto;
  margin-right: 0;
}

.qrs-reward-card {
  margin-right: 14px;
  width: 200px;
  height: 185px;
  background: white;
  border-radius: 10px;
  box-shadow: #ccc 0px 0px 10px;
  position: relative;
  overflow: hidden;
  display: inline-block;
  vertical-align: top;

  .icon-badge {
    position: absolute;
    top: 10px;
    right: 10px;

    img {
      width: 20px;
      height: 20px;
    }
  }

  .icon-price {
    position: absolute;
    top: 0px;
    right: 10px;
    color: $qrs-blue;

    .unit {
      display: inline-block;
      font-size: 10px;
    }

    .amount {
      font-weight: bold;
      vertical-align: sub;
      display: inline-block;
    }
  }

  .upper-deck {
    text-align: center;
    padding: 12px 10px 10px 10px;
    height: 110px;

    .main-icon {
      margin: 0 auto;
      width: 65px;
      height: 65px;
      object-fit: scale-down;

      &.large {
        width: 100px;
        height: 90px;
        margin-top: 10px;
      }
    }

    .main-text {
      &.no-push {
        padding-top: 0px;
      }

      display: block;
      padding-top: 0px;
      font-size: 15px;
    }

    .main-value {
      font-size: 35px;
      font-weight: bold;
      color: $qrs-blue;
    }
  }

  .lower-deck {
    box-shadow: #bbb 0px 0px 8px;
    padding: 20px 10px 10px 10px;
    border-radius: 6px;
    height: calc(100% - 110px);
    text-align: center;

    &.no-push {
      padding-top: 10px;
    }

    .action-btn {
      border: solid 1px $qrs-blue;
      display: inline-block;
      width: auto;
      cursor: pointer;
      color: $qrs-blue;
      border-radius: 10px;
      padding: 5px 15px 5px 15px;

      &:hover {
        color: white;
        background-color: $qrs-blue;
      }
    }

    .sub-label {
      color: #feb201;
      display: block;

      &.solo-label {
        font-size: 22px;
        margin-top: 10px;

        img {
          width: 20px;
          height: 20px;
        }
      }

      img {
        width: 15px;
        height: 15px;
        display: inline;
        margin-left: 3px;
      }
    }
  }
}

.reward-redeem-modal {
  max-width: 400px;

  @media (max-width: $mobile) {
    @apply pl-0;

    .qrs-reward-card {
      margin-left: 30px;
    }
  }
}

.qrs-referral-box {
  @media (max-width: $mobile) {
    width: 100%;

    .right-side {
      .copy-box {
        margin-top: 50px;
      }

      .more-infobox {
        margin: 0 auto;
      }
    }

    .form-container {
      width: 100%;

      input,
      textarea {
        width: 100%;
      }
    }
  }
}

.confirm-modal {
  .card-info {
    @apply flex px-4 py-2 rounded-full text-mutedBlack bg-qureosGray items-center text-center mx-auto w-auto;

    .close {
      @apply -mr-2 ml-3 rounded-full p-3 bg-gray-200;

      img {
        height: 10px;
      }
    }
  }
}

.gift-card-box {
  @apply flex flex-col overflow-hidden;
  background: white;
  border-radius: 10px;
  box-shadow: #3e44571a 0px 0px 20px;
  width: 226px;
  height: 217px;
  margin: 0px 15px 15px 0px;

  .content {
    @apply flex flex-col;
    padding: 5px 8px;

    .icon-head {
      width: 90px;
      margin: 0 auto;
      margin-bottom: 10px;
    }

    .item-label {
      @apply flex flex-col justify-center w-full text-center;
      margin-bottom: 10px;

      .item-title {
        @apply font-semibold text-qureosPrimary;
        font-size: 20px;
      }

      .item-subtitle {
        @apply font-light;
        color: #576271;
        font-size: 14px;
      }
    }

    .amount {
      @apply text-qureosPrimary flex self-end font-bold;
      font-size: 30px;
      line-height: 1;
      margin-bottom: 25px;

      .unit {
        font-size: 15px;
        @apply font-normal;
        line-height: 22px;
      }
    }

    .icon {
      object-fit: scale-down;
      height: 60px;
      width: 120px;
      align-self: center;
      margin-bottom: 5px;
    }

    .button {
      border-radius: 24px;
      background: var(--theme-primary-light);
      text-align: center;
      padding: 5px 20px;
      font-size: 18px;
      color: var(--theme-primary);
      width: fit-content;
      @apply self-center cursor-pointer;
    }
  }

  .footer-card {
    @apply mt-auto w-full flex justify-center;
    border-top: solid 1px #d6dee8;
    height: 38px;
  }
}

.cashout-container {
  @apply flex flex-col w-full;
  max-width: 576px;

  @media (max-width: $mobile) {
    padding: 0 20px;

    .body-content {
      height: auto !important;
    }
  }

  .header-title {
    @apply font-semibold text-blackText;
    font-size: 18px;
    margin-bottom: 25px;
  }

  .body-content {
    @apply flex flex-col w-full;
    background: #fff;
    border-radius: 10px;
    box-shadow: #3e44571a 0px 0px 20px;
    height: 340px;
    padding: 32px;
    margin-bottom: 23px;

    .table-section {
      @apply flex justify-between w-full;
      height: fit-content;

      @media (max-width: $mobile) {
        @apply flex-col justify-center;

        .table-box {
          margin-bottom: 20px;
        }
      }

      .table-box {
        @apply flex flex-col;
        width: 250px;
        height: 186px;
        border-radius: 10px;
        border: #d6dee8 solid 0.5px;

        .table-item {
          width: 100%;
          height: 62px;
          border-top: #d6dee8 solid 0.5px;
          padding: 20px 15px 20px;
          @apply flex;

          &:nth-child(1) {
            border-top: none;
          }

          .currency-box {
            background: #edfbf5;
            border: 0.5px solid #00cc6f;
            border-radius: 5px;
            padding: 0px 5px;
            @apply text-center font-bold uppercase;
            font-size: 14px;
            color: #00cc6f;
          }

          .label-item {
            font-size: 15px;
            color: #6a7a8b;
            @apply flex items-center;
            width: fit-content;
            flex: 0 0 auto;

            .cashout-input-box {
              width: 190px;
              height: 36px;
              background: #f2f9ff;
              border-radius: 5px;
              margin-left: 10px;
              @apply flex items-center;

              input {
                padding: 7px;
                font-size: 15px;
                color: #6a7a8b;
                width: calc(100% - 40px);
                background: transparent;
              }

              button {
                @apply font-light justify-center;
                width: 30px;
                height: 19px;
                border: 0.5px solid #d6dee8;
                border-radius: 2px;
                font-size: 14px;
                color: #6a7a8b;
                background: transparent;
                padding: 0px;
              }
            }

            img {
              width: 20px;
              height: 20px;
            }

            .icon-currency {
              margin-right: 11px;
            }
          }

          .value-item {
            @apply flex ml-auto;

            .text-value {
              font-size: 15px;
              margin-right: 16px;
              color: #6a7a8b;
            }
          }
        }
      }
    }

    .footer {
      margin: 5px 0px 10px 0px;

      .note {
        width: 246px;
        font-size: 12px;
        color: #6a7a8b;

        &.error {
          color: red;
        }
      }
    }

    .action-btn {
      height: 44px;
      background: var(--theme-primary);
      box-shadow: 0px 3px 6px #2c28281c;
      border-radius: 5px;
      padding: 12px 19px;
      font-size: 15px;
      color: #ffffff;
    }
  }

  .bank-container {
    @apply flex flex-wrap;

    .bank-item {
      @apply flex items-center;
      width: fit-content;
      min-width: 182px;
      height: 64px;
      background: #ffffff;
      box-shadow: 0px 0px 20px #3e44571a;
      border-radius: 10px;
      color: #576271;
      font-size: 15px;
      padding: 15px 10px 20px 20px;
      margin-right: 15px;
      margin-bottom: 15px;

      &:nth-child(3n) {
        margin-right: 0px;
      }

      .bank-details {
        @apply flex flex-col;
        margin-left: 10px;

        .name {
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
          width: 110px;
        }

        .account {
          font-size: 10px;
          color: gray;
          letter-spacing: 2px;
        }
      }
    }
  }
}

.add-bank-menu {
  .menu-item {
    @apply flex items-center;

    img {
      margin-right: 16px;
      width: 15px;
      height: 15px;
    }

    .bank-details {
      @apply flex flex-col;
      margin-left: 16px;

      .name {
      }

      .account {
        font-size: 10px;
        color: gray;
        letter-spacing: 2px;
      }
    }
  }
}

.new-bank-modal-wrapper {
  &.ant-modal {
    width: 685px !important;

    .ant-modal-body {
      height: 633px !important;
      background: #ffffff;
      box-shadow: 0px 0px 30px #6a708026;
      border-radius: 10px;
      padding: 25px 35px;

      .modal-title {
        @apply font-semibold text-blackText;
        font-size: 18px;
        margin-bottom: 10px;
      }

      .modal-subtitle {
        font-size: 15px;
        color: #576271;
        margin-bottom: 10px;
      }

      .modal-form-section {
        @apply flex flex-wrap;
        margin-bottom: 18px;

        .form-item {
          @apply flex flex-col;
          width: fit-content;
          flex: 0 0 auto;
          margin-left: 10px;

          @media (max-width: $mobile) {
            margin-left: 0px !important;
            margin-top: 12px;
          }

          &:nth-child(1) {
            margin-left: 0px;
          }

          &.pull-right {
            margin-left: auto !important;

            @media (max-width: $mobile) {
              margin-left: 0px !important;
            }
          }

          &.full {
            width: 100%;
          }

          label {
            font-size: 12px;
            color: #6a7a8b;
            @apply uppercase;
            margin: 0px 0px 4px 0px;
          }

          .action-btn {
            padding: 11px 24px;
            font-size: 18px;
            color: #ffffff;
            height: 46px;
            background: var(--theme-primary);
            box-shadow: 0px 3px 6px #2c28281c;
            border-radius: 5px;

            &:hover {
              background: #3aa4e1;
            }
          }

          & > input,
          .text-input {
            height: 50px;
            background: #ffffff;
            border: 1px solid #ebeef1;
            border-radius: 5px;
            min-width: 173px;

            &.long {
              width: 356px;
            }

            &.medium {
              width: 220px;
            }

            &.phone {
              width: 128px;
              min-width: 128px;
              margin-left: 10px;
            }
          }

          .modal-select {
            height: 50px;
            background: #ffffff;
            border: 1px solid #ebeef1;
            border-radius: 5px;

            .ant-select-selector {
              height: 100%;
              outline: none;
              border: none;
            }

            &.mobile-prefix {
              .ant-select-selector {
                width: 82px;
              }
            }
            &.country {
              .ant-select-selector {
                width: 220px;
              }
            }

            .ant-select-selection-search {
              input {
                visibility: hidden;
              }
            }

            .ant-select-selection-placeholder,
            .ant-select-selection-item {
              padding-top: 10px;
            }
          }
        }
      }
    }
  }
}

.admin-cashout-modal-wrapper {
  &.short {
    &.ant-modal {
      .ant-modal-body {
        height: 250px !important;
      }
    }
  }

  &.ant-modal {
    width: 685px !important;

    .ant-modal-body {
      height: 450px !important;

      .form-item {
        min-width: 120px;
        height: 50px;
      }
    }
  }
}

.store-items {
  margin-bottom: 60px;

  & > .title {
    @apply text-xl text-black font-semibold;
    margin-bottom: 25px;
  }

  .buy-session-card {
    @apply flex flex-col items-center justify-center bg-white rounded-lg shadow-lg relative;
    width: 226px;
    height: 217px;
    margin-right: 15px;

    & > .title {
      @apply text-black font-semibold;
      margin-top: 13px;
      font-size: 30px;
    }

    .subtitle {
      @apply text-base font-thin;
      color: #576271;
    }

    .action-btn {
      @apply text-xl text-qureosBlue font-semibold text-center rounded-full;
      margin-top: 25px;
      padding: 5px 25px;
      background: #f2f9ff;
    }

    img {
      &.as-badge {
        @apply absolute;
        top: 8px;
        right: 8px;
      }

      &.main-icon {
        margin-bottom: 21px;
      }
    }
  }
}

.purchase-modal {
  max-width: 800px;
}
