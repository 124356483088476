.ck-editor__corporate {
  .ck-content:focus {
    border: 1px solid var(--theme-primary) !important;
  }

  &.auto-height {
    .ck-editor__main {
      max-height: none;
    }
  }
}

.ck-read-only {
  background-color: #f3f4f6 !important;
}

.editor-container {
  position: relative;
  overflow: hidden;
  border-bottom: 1px solid #c4c4c4;
  border-radius: 2px;
  .ck-sticky-panel {
    border-bottom: 5px solid #000;
  }
}

.ck-editor__main {
  max-height: 160px;
  overflow-y: auto;
  border-bottom: none;
}

.ck-toolbar {
  position: sticky;
  top: 0;
  background-color: #fff;
  z-index: 1;
}
