.textarea-popup {
  @apply p-6;
  width: 100vw;
  max-width: 900px;
}

.certificate-preview {
  @apply border border-qureosGray w-full rounded-lg flex items-center justify-center;
  height: 290px;

  .pg-viewer-wrapper {
    @apply w-full;
    overflow-y: hidden;
  }

  &.full-view {
    height: fit-content;
  }
}

.ant-checkbox-wrapper {
  @apply flex;
}

.edit-question {
  padding-top: 10px;
  margin: 0 13px;
}

.cancel-edit {
  @apply absolute rounded-full cursor-pointer;
  right: 43px;
  top: 8px;
  background: #ececec;
  padding: 6px;
  border-radius: 100%;

  img {
    width: 10px;
  }
}

.footer-links {
  &.emoji {
    display: none !important;
  }
}

.item-tags {
  width: auto;
  min-width: 70px;
  text-align: center;
  border: 1px solid #3fa3f3;
  border-radius: 25px;
  font-size: 12px;
  color: #3fa3f3;
  padding: 5px 8px;
  display: inline-block;
  text-transform: capitalize;

  &.secondary-tags {
    border: 1px solid #b443d0;
    color: #b443d0;
  }

  margin: 5px;

  &.counter {
    background: #ebeef1;
    color: #1a1e2c;
    font-size: 15px;
    min-width: 50px;
    border: none;
    padding: 5px;
  }
}

.qrs-widget-box {
  box-shadow: 0px 0px 20px #3e44571a;
  border-radius: 10px;
  width: 320px;
  height: 107px;
  display: inline-block;
  background: #fff;
  margin: 5px;
  text-align: left;
  overflow: hidden;

  @media (max-width: $mobile) {
    // height: auto;
  }

  @media (max-width: $xs-mobile) {
    width: calc(100% - 12px);
  }

  .left-box,
  .right-box,
  .arrow-box {
    display: inline-block;
    vertical-align: top;
    height: 100%;
    position: relative;
  }

  $left-box-size: 72px;

  .left-box {
    width: $left-box-size;

    .icon {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }

    .percentage {
      font-size: 15px;
      font-weight: 600;
      color: #ffb100;
      width: 100%;
      text-align: center;
      display: block;
      padding: 10px 0px 0px 0px;
    }
  }

  .right-box {
    width: calc(100% - #{$left-box-size});

    &.has-arrow {
      width: calc((100% - #{$left-box-size}) - 30px);
    }

    .body {
      padding: 10px 10px 10px 0px;

      .title {
        color: #1a1e2c;
        font-size: 15px;
        font-weight: 600;
        margin-bottom: 8px;
      }

      .content {
        color: #576271;
        font-size: 13px;
        font-weight: normal;

        .skills {
          margin-top: -5px;
          .item-tags {
            color: #576271;
            border: 1px solid #576271;
            padding: 4px 10px 4px 10px;
            margin: 5px 5px 0px 0px;
            height: 26px;
            max-width: 200px;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;

            @media (max-width: $xs-mobile) {
              padding: 4px 5px 4px 5px;
            }

            &.counter {
              font-size: 13px;
              min-width: 35px;
              border: none;
              padding: 4px !important;
              margin-left: 0px !important;
            }
          }
        }
      }
    }

    .info-highlight {
      background: #3aa4f3;
      color: white;
      font-size: 15px;
      font-weight: 600;
      width: 100%;
      height: 100%;
      padding: 15px;
    }
  }

  .arrow-box {
    background: #c5e4fd;
    width: 30px;

    img {
      margin: 0 auto;
      margin-top: 135%;
    }
  }
}

.qrs-fade-slider {
  position: relative;
  width: 100%;
  display: flex;

  .slide-item {
    opacity: 0;
    transition: all ease-in-out 0.3s;
    display: none;
    // position: absolute;

    &.active {
      opacity: 1;
      display: block;
    }
  }

  .switcher {
    cursor: pointer;
    float: left;
    transition: all ease-in-out 0.3s;

    &.flip {
      transform: rotate(180deg);
    }
  }
}

.workspace-container {
  width: 100%;
  max-width: 1165px;
  height: auto;
  min-height: 500px;
  margin-top: -20px;
  // background-color: #fff;

  @media (max-width: $desktop-5) {
    max-width: none;

    .tab-container {
      .contents {
        .tab-content {
          flex: 1;
        }
      }
    }
  }

  @media (max-width: $mobile) {
    margin-top: 0px;
  }

  &.challenge-workspace {
    .tab-container {
      .tab-wrapper {
        @apply mb-10;
      }
    }
  }

  .tab-container {
    width: 100%;
    display: flex;
    flex-direction: column;

    .tab-wrapper {
      @apply w-full flex justify-center items-center;
    }

    .tab-header {
      width: fit-content;
      height: 52px;
      border: 0.5px solid #8898aa;
      border-radius: 26px;
      overflow: hidden;
      align-self: center;

      &.no-start {
        .tab-item {
          &:first-child {
            padding-left: 30px;
          }
        }
      }

      @media (max-width: $mobile) {
        width: auto;
        margin: 20px 10px -10px 10px;
        align-self: flex-start;

        .tab-handle {
          padding: 2px 0px 0px 12px;
          margin-right: 0px;
          flex-basis: 110px;
        }

        .tab-item {
          &.selected {
            display: flex;
            flex: 1;
            margin-right: 2px;

            img {
              display: flex;
            }
          }
        }
      }

      .tab-item {
        &.mentorship {
          @apply flex items-center;
          border-left: 0.5px solid #8898aa;

          img {
            @apply rounded-full absolute;
            max-width: none;
            flex: none;
            width: 42px;
            height: 42px;
            object-fit: cover;
            display: block !important;
            left: 4px;
          }
        }
      }
    }

    .tab-handle {
      min-width: 120px;
      height: 100%;
      background: #50d498;
      margin-right: 15px;
      color: #fff;
      font-size: 15px;
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 2px 15px 0px 15px;

      strong {
        font-weight: 600;
      }

      .feedback {
        font-size: 10px;
        cursor: pointer;
      }

      &.warning {
        background: #ffb100;
      }

      &.danger {
        background: #fa6767;
      }

      &.completed {
        @apply bg-qureosBlue;
      }
    }

    .tab-item {
      padding: 12px;
      color: #8898aa;
      font-size: 18px;
      cursor: pointer;
      margin-right: 30px;

      img {
        display: none;
      }

      &.selected {
        color: #1a1e2c;
        font-weight: 600;
        cursor: default;
      }
    }

    .contents {
      display: flex;
      margin-top: -5px;

      .tab-content {
        position: relative;
        width: 574px !important;
        height: 550px;
        box-shadow: 0px 0px 20px #3e44571a;
        border-radius: 10px;
        background: white;
        width: 50%;
        padding: 15px;
        overflow: auto;

        .tab-arrows {
          position: absolute;
          right: 10px;
          top: 0px;
          display: flex;
          margin: 12px 0px 0px 0px;

          @media (max-width: $mobile) {
            display: none;
          }

          img {
            margin: 0px 7.5px;
            cursor: pointer;
          }
        }

        @media (max-width: $mobile) {
          width: 100% !important;
          height: auto !important;
          min-height: 574px;
          margin: 10px;
          overflow: hidden;
        }

        &:nth-child(1) {
          // width: calc(50% - 15px);
          margin-right: 15px;

          @media (max-width: $mobile) {
            margin-right: 10px;
          }
        }

        .content-title {
          @apply text-blackText;
          font-size: 18px;
          text-transform: uppercase;
          font-weight: normal;
        }

        .content-desc {
          @apply overflow-auto;
          margin-top: 20px;
          font-size: 15px;
          color: #576271;
          height: 100%;

          &.reward-desc {
            img {
              width: 23px;
              height: 23px;
              margin: 0px 5px;
            }

            .currency-box {
              width: 36px;
              height: 23px;
              text-align: center;
              color: white;
              font-size: 13px;
              font-weight: bold;
              background: #50d498;
              border-radius: 5px;
              margin: 0 5px;
              padding: 2px 5px;
            }

            .currency-text {
              color: #50d498;
              font-weight: 600;
            }
          }
        }
      }
    }
  }

  .workspace-footer {
    width: 100%;
    height: 156px;
    border-radius: 10px;
    margin-top: 18px;
    background: #fff;
    overflow: hidden;

    @media (max-width: $mobile) {
      display: none;
    }

    .ws-body {
      padding: 16px 10px 0px 25px;

      .footer-title {
        color: #172b4d;
        font-size: 18px;
        text-transform: uppercase;
        font-weight: normal;
        margin-bottom: 20px;
      }

      .icon-box {
        &:after {
          content: ' ';
          border: 1px solid #d0d3de;
          width: 0px;
          height: 63px;
          margin: 0px 25px 0px 25px;
        }
      }

      .step-item {
        display: flex;
        flex: 1;
        margin-right: 10px;

        &:last-of-type {
          margin-right: 0px;
        }

        .number-tag {
          min-width: 50px;
          height: 50px;
          display: block;
          border: 1px solid #3aa4f3;
          border-radius: 100%;
          text-align: center;
          color: #3aa4f3;
          font-size: 25px;
          font-weight: 600;
          padding: 5px;
          margin-right: 10px;
        }

        .item-content {
          .title {
            font-size: 18px;
            font-weight: 600;
            color: #576271;
            margin: 0px;
            text-transform: uppercase;
            margin-bottom: 5px;
          }

          .subtitle {
            font-size: 15px;
            color: #8898aa;
          }
        }
      }
    }

    .footer-btn {
      height: 100%;
      width: 140px;
      background: #3aa4f3;
      color: white;
      text-transform: uppercase;
      margin-left: auto;
      text-align: center;
      cursor: pointer;

      .text {
        align-self: center;
        width: 100%;
        display: block;
      }
    }
  }
}

.task-generic-container {
  .mobile-deadline-box {
    display: none;

    @media (max-width: $mobile) {
      position: absolute;
      right: 12px;
      top: 70px;
      width: 94px;
      height: 50px;
      background: #ebfbf4;
      border: 1px solid #c5e4fd;
      border-radius: 5px;
      font-size: 12px;
      @apply text-blackText;
      padding: 15px;
      text-align: center;
    }

    &.warning {
      background-color: #fae2e6;
    }

    &.info {
      background-color: #fff3d8;
    }

    &.success {
      background-color: #ebfbf4;
    }
  }

  .header-box {
    margin-bottom: 20px;

    .icon {
      margin-right: 20px;
      cursor: pointer;
    }

    label {
      margin: 0px;
      @apply text-blackText flex;
      font-size: 18px;

      .status-box {
        padding: 2px;
        height: 25px;
        font-size: 13px;
        color: white;
        align-self: center;
        margin-left: 10px;
        justify-content: center;
        display: flex;
      }

      &.stuck {
        background: #e2445c;
      }
    }
  }

  .ant-checkbox {
    & + span {
      display: inline-block;
      vertical-align: middle;
    }
  }

  .button {
    padding: 5px;
    width: 90px;
    height: 30px;
    background: #3aa4f3;
    text-align: center;
    border-radius: 23px;
    font-size: 15px;
    color: white;
    line-height: 18px;
    cursor: pointer;
    display: inline-block;
  }

  .tgc-tab-container {
    width: 100%;

    .tab-head {
      border-bottom: solid 1px #d6dee8;
      height: 30.5px;

      @media (max-width: $mobile) {
        margin-bottom: 20px;
      }
    }

    .task-tab-content {
      @media (max-width: $mobile) {
        margin: 0px !important;
      }
    }

    .tab-item {
      @apply text-blackText relative;
      font-size: 15px;
      margin-right: 30px;
      padding: 0px 0px 8px 0px;

      &.active {
        border-bottom: solid 3px #3aa4f3;
      }
    }

    .tgc-tab-content {
      margin-top: 20px;
      padding: 0px !important;
      border-radius: 0px !important;
      box-shadow: none !important;
      // height: 420px !important;
      width: 100% !important;
      // overflow: auto;
      display: block;
      height: auto;

      @media (max-width: $mobile) {
        height: auto !important;
        overflow: hidden;
      }

      .desc-text {
        border: 1px solid #d0e9fd;
        border-radius: 10px;
        padding: 13px 10px 13px 10px;
        font-size: 13px;
        color: #576271;
        width: 100%;

        ol {
          list-style: decimal;
          padding-left: 12px;
        }
        ul {
          list-style: disc;
          padding-left: 15px;
        }
      }

      .update-editor {
        margin-bottom: 24px;

        &.with-top-divider {
          border-top: 1px solid #d0e9fd;
        }

        textarea {
          min-height: 0px;
          height: 43px;
          background: #ffffff;
          border: 1px solid #c5e4fd;
          border-radius: 10px;
          resize: none;
          transition: 0.3s all ease-in-out;
          width: 100%;

          &.has-content,
          &:focus {
            height: 120px;

            &.long {
              height: 250px;
            }
          }

          &.has-files {
            height: 120px;
            border-bottom: none;
            border-radius: 10px 10px 0px 0px;
          }
        }

        .attachments {
          margin: -6px 0px 0px 0px;
          position: relative;
          z-index: 999;
          border: 1px solid #c5e4fd;
          padding: 10px 10px 0px 10px;
          border-radius: 0px 0px 10px 10px;
          display: flex;
          flex-wrap: wrap;

          .file-item {
            margin-right: 5px;

            .remove-file {
              width: 10px;
              margin-right: 8px;
              cursor: pointer;
            }
          }
        }

        .ck-editor {
          border: 1px solid #c5e4fd;
          border-radius: 10px;

          .ck-toolbar {
            border-color: #c5e4fd;
            background: transparent;
          }
          .ck-content {
            height: 120px;
            border: none;
            box-shadow: none;
            border-radius: 0px 0px 10px 10px;
            border-top: solid 1px #c5e4fd;
          }
        }
      }

      .attachments {
        margin-top: 15px;

        .file-item {
          width: fit-content;
          margin-bottom: 10px;

          &.initial-file {
            background: #eee;
            padding: 3px 10px;
            border-radius: 10px;
            margin-right: 5px;
            max-width: 300px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;

            img {
              margin-right: 5px;
            }
          }

          img {
            margin-right: 8px;
          }

          .file-name {
            max-width: 100px;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
          }

          &.removable {
            background-color: #ebfcf4;
            padding: 10px;
            @apply rounded-full;

            .remove-file {
              width: 10px;
              margin-left: 10px;
              margin-right: 15px;
              cursor: pointer;
            }
          }
        }
      }

      .thread-container {
        border: 1px solid #d0e9fd;
        border-radius: 10px;
        width: 100%;
        height: auto;
        overflow: hidden;
        margin-top: 24px;
        transition: background 0.3s ease-in;

        &.highlighted {
          background: #fed;
        }

        .header {
          padding: 10px;
          display: flex;
          border-bottom: 1px solid #d0e9fd;

          .profile-pic {
            width: 30px;
            height: 30px;
            border-radius: 100%;
            overflow: hidden;
            object-fit: scale-down;
          }

          .name {
            @apply text-blackText;
            font-size: 14px;
            padding: 4px 10px;
            display: flex;
          }

          .right-panel {
            margin: 0 0 0 auto;

            .status-box {
              border-radius: 5px;
              padding: 1px;
              height: 20px;
              font-size: 11px;
              color: white;
              align-self: center;
              margin-right: 10px;
              justify-content: center;
              @apply flex;

              img {
                width: 11px;
                height: 11px;
                align-self: center;
                margin: 0px 5px 0px 0px;
              }

              &.submitted {
                background: #336ad3;
              }
              &.revise {
                background: #b553c7;
              }
              &.stuck {
                background: #e2445c;
              }
              &.completed {
                background: #33d390;
                padding: 1px 2px 1px 2px;

                .rate-stars {
                  width: 10px;
                  height: 10px;
                  margin: 0px 2px 0px 2px;
                }
              }
            }

            .timebox {
              margin: 0 15px 0 0;
              color: #aebbc9;
              font-size: 12px;

              span {
                padding-top: 6px;
              }

              img {
                margin-right: 5px;
              }
            }

            .menu {
              cursor: pointer;
              padding: 0px;
              border: none;
            }
          }
        }

        .content {
          padding: 20px;
          color: #576271;
          font-size: 13px;
          border-bottom: 1px solid #d0e9fd;
          white-space: break-spaces;

          a {
            @apply text-qureosBlue;
          }
        }

        .reply-box {
          border-top: 1px solid #d0e9fd;
          color: #3aa4f3;
          padding: 8px;
          text-align: center;
          display: flex;
          font-size: 14px;

          img {
            margin-right: 7px;
          }
        }

        .message-box {
          padding: 10px 0px;

          &.empty {
            padding: 0px;
            border: none;
          }

          .message-item {
            &.sender {
              .message {
                align-self: flex-end;
                border-radius: 10px 10px 0px 10px;
                background: #f3f8fd;

                .user {
                  color: #3aa4f3;
                }
              }

              .time {
                align-self: flex-end;
              }
            }

            .message {
              padding: 5px 9px;
              background: #ebfbf4;
              border-radius: 10px 10px 10px 0px;
              max-width: 400px;
              align-self: flex-start;
              margin: 0px 10px;
              word-break: break-word;

              .user {
                color: #50d498;
                font-size: 10px;
              }

              .text {
                font-size: 13px;
                @apply text-blackText;
                white-space: break-spaces;
              }
            }
            .time {
              align-self: start;
              margin: 0px 10px;
              font-size: 10px;
            }
          }
        }
      }

      .thread-footer-options {
        margin-top: 8px;
        width: 100%;
        justify-content: space-evenly;

        .footer-btn {
          height: 33px;
          background: #aebbc9;
          border-radius: 5px;
          color: white;
          font-size: 15px;
          flex: 1;
          text-align: center;
          padding: 5px;
          cursor: pointer;

          &:first-child {
            margin-right: 11px;
          }

          &.active {
            &.accept {
              background-color: #33d390;
            }
            &.revise {
              background-color: #b553c7;
            }
          }
        }
      }
      .thread-footer-actions {
        @apply flex flex-col;

        textarea {
          min-height: 0px;
          height: 64px;
          border: 1px solid #d0e9fd;
          border-radius: 10px;
          padding: 9px 10px;
          font-size: 13px;
          color: #8898aa;
          background: white;
          resize: none;
          margin: 13px 0px 0px 0px;
        }

        .action-btn {
          @apply bg-qureosPrimary;
          border-radius: 23px;
          color: white;
          cursor: pointer;
          padding: 5px;
          text-align: center;
          font-size: 15px;
          margin: 8px 0px 0px 0px;
          align-self: flex-end;
          justify-content: center;
          width: 115px;

          &.normal {
            margin-top: 0;
            align-self: unset;
          }
        }

        .rate-box {
          margin-top: 15px;

          .title {
            width: 120px;
          }

          .star-container {
            margin: 5px 0px 18px 0px;
            align-self: flex-end;

            .star-box {
              width: 18px;
              height: 18px;
              margin: 0px 6px 0px 0px;

              img {
                height: 100%;
                width: 100%;
              }
            }
          }
        }
      }

      .editor-footer {
        .footer-links {
          padding: 8px 0px 8px 0px;
          font-size: 12px;
          color: #3aa4f3;
          margin-right: 18px;
          display: flex;
          cursor: pointer;
          border: none;
          box-shadow: none;
          outline: none;

          img {
            margin: -5px 8px 0px 0px;
          }
        }

        button {
          &.footer-links {
            img {
              margin: 0px 8px 0px 0px;
            }
          }
        }

        .footer-btn {
          margin-top: 10px;
          margin-left: auto;

          &.cancel-button {
            background: #e9ecef;
            margin-left: 10px;
            @apply text-gray-600;
          }
        }
      }

      .qa-container {
        .header {
          width: 100%;
          margin-bottom: 20px;
          border: 1px solid #d0e9fd;
          border-radius: 10px;
          text-align: center;
          padding: 10px;
          font-size: 14px;
          color: #3aa4f3;

          img {
            margin-right: 9px;
            display: inline-block;
          }
        }

        .qa-item {
          transition: background 0.3s ease-in;

          &.highlighted {
            background: #fed;
            padding: 5px;
          }
        }

        .qa-description {
          border-radius: 10px 10px 0px 0px;
          border: 1px solid #d0e9fd;
          padding: 10px 8px 10px 8px;
          font-size: 13px;
          color: #576271;
        }

        .qa-notes {
          color: #aebbc9;
          font-size: 10px;
          margin: 5px 0px;

          .user {
            color: #3aa4f3;
            margin: 0px 3px;
          }
          .company {
            color: #50d498;
            margin: 0px 3px;
          }

          .dot {
            margin: 0px 8px;
            font-size: 30px;
            line-height: 12px;
          }
        }

        .qa-box {
          min-height: 80px;
          border: 1px solid #d0e9fd;
          border-radius: 10px;
          height: auto;

          &.connectable {
            border-top: none;
            border-radius: 0px 0px 10px 10px;
          }

          .qa-label {
            width: 60px;
            justify-content: center;
            border-right: solid 1px #d0e9fd;

            img {
              margin-bottom: 8px;
            }

            .counter {
              @apply text-blackText;
              font-size: 14px;
              text-align: center;
            }
          }

          .qa-content {
            flex: 1;

            .content {
              @apply relative w-full text-sm;
              min-height: 40px;
              height: auto;

              strong {
                padding: 10px 0px 10px 10px;
              }

              input,
              textarea {
                border: none;
                background: transparent;
                border-radius: 0px;
                color: #3aa4f3;
                resize: none;
                height: 40px;
                min-height: 0;
                padding: 10px;
              }

              .text {
                padding: 10px;
              }

              .edit {
                color: #8898aa;
                font-size: 10px;
                align-self: center;
                text-transform: uppercase;
                cursor: pointer;
                margin: 0px 10px 0 auto;
              }

              &.qa-green {
                color: #50d498;

                .edit-question {
                  color: #50d498;
                }
              }

              &.qa-blue {
                color: #3aa4f3;
              }

              &:first-child {
                border-bottom: solid 1px #d0e9fd;
              }
            }
          }
        }

        .post-btn {
          margin: 10px 0px 0px auto;
        }
      }
    }
  }

  .submit-form {
    width: 100%;
    margin: 0px;
    overflow: auto;
    height: 465px;

    @media (max-width: $mobile) {
      height: auto;
    }

    .form-content {
      margin: 0px 0px 30px 0px;

      .form-head {
        @apply text-blackText;
        font-size: 12px;
        margin: 0px 0px 10px 0px;
      }

      .project-file {
        padding: 4px 4px 4px 12px !important;
      }

      textarea {
        border: 1px solid #d0e9fd;
        border-radius: 10px;
        width: 100;
        height: 64px;
        padding: 10px;
        font-size: 13px;
        color: #8898aa;
        resize: none;
        background: #fff;
        width: 100%;
      }

      .submit-btn {
        margin: 8px 0px 0px auto;
      }

      .file-selector {
        width: 160px;

        @media (max-width: $mobile) {
          width: 100%;
          height: auto;
        }

        .file-list {
          .ant-checkbox-wrapper {
            margin-left: 0px;

            .ant-checkbox {
              margin-top: 5px;
            }

            .ant-checkbox-checked {
              &::after {
                border: none;
              }
            }

            & > span {
              margin-bottom: 0;

              &:last-child {
                margin-top: -4px;
              }
            }
          }

          .file-viewer {
            width: auto;
            border-radius: 0px;
            box-shadow: none;

            .files {
              padding: 0px;
            }

            .project-file {
              padding: 0px 5px 0px 5px;
              font-size: 10px;
              line-height: 17px;
              color: #3aa4f3;
              margin: 3px 0px 0px 5px;
            }
          }
        }
      }

      .dropzone {
        width: 200px;
        height: 75px;
        border: 1px solid #c5e4fd;
        border-radius: 5px;
        justify-content: center;
        margin: 0px 0px 0px auto;
        padding: 12px;

        @media (max-width: $mobile) {
          width: 100%;
        }

        img {
          margin: 0px 0px 3px 0px;
        }

        .text {
          color: #576271;
          font-size: 11px;
          text-align: center;
        }
      }
    }
  }
}

.fileviewer-container {
  @apply flex flex-col justify-center rounded-lg p-4 w-full h-full overflow-auto relative;
  padding: 70px;
  padding-right: 80px;
  max-width: 900px;

  .actions {
    @apply absolute;
    z-index: 1;
    right: 20px;
    height: 100%;
    padding: 75px 0;
  }
}

.qrs-file-viewer {
  @apply rounded-lg overflow-hidden;
  overflow: auto;

  .pg-viewer {
    display: flex;
    justify-content: center;
  }

  .photo-viewer-container {
    // height: 300px !important;
    height: auto !important;
    width: calc(100vw - 30%) !important;

    img {
      height: 100% !important;
      width: 100% !important;
      object-fit: scale-down;
    }
  }
}

.generic-slide-container {
  @apply w-full;

  @media (max-width: $mobile) {
    padding-left: 20px;
  }

  .slider-case {
    margin-bottom: 40px;

    .slider-header {
      display: flex;
      // margin-bottom: 25px;

      .title {
        font-size: 18px;
        font-weight: 600;
        @apply text-blackText;
        margin-right: 20px;
      }

      .slider-tools {
        .pagination {
          li {
            a {
              width: 28px;
              height: 28px;

              img {
                width: 8px;
              }
            }
          }
        }
      }
    }

    .slider-body {
      &.no-slide {
        .slider-content {
          flex-wrap: wrap;

          @media (max-width: $small-mobile) {
            justify-content: center;
            margin-left: -15px;
          }
        }
      }

      .slider-content {
        width: 100%;
        display: flex;
        overflow: auto;
        scroll-behavior: smooth;
        padding-bottom: 20px;

        &.active-sessions {
          // margin-left: 15px;
          width: calc(100% - 300px);
        }
      }
    }
  }
}

.qrs-page-widget {
  @apply cursor-pointer;
  width: 226px;
  height: 228px;
  box-shadow: 0px 0px 20px #3e44571a;
  border-radius: 10px;
  display: flex;
  overflow: hidden;

  &.mentorship-banner {
    background: #74bff6;

    &:not(.small) {
      .left-box {
        .title {
          padding-bottom: 25px;
        }
      }
    }

    &.as-card {
      margin-right: 15px;
      flex: none;
    }

    .left-box {
      @apply flex justify-center items-center;
      flex: 1;

      .title {
        @apply flex flex-col;
        color: #fff;
        font-size: 15px;
        font-weight: 600;
        text-align: center;
      }

      .extra-title {
        margin-bottom: 10px;
      }
    }
    .right-box {
      flex-basis: 28px;
      height: 100%;
      background: #c5e4fd;
      cursor: pointer;

      img {
        align-self: center;
        margin: 0 auto;
        width: 20px;
        height: 20px;
      }
    }
  }

  &.mentorship-session-card {
    background: #fff;
    @apply flex flex-col;
    margin-right: 15px;

    &::before {
      content: ' ';
      width: 100%;
      height: 10px;
      background: #74bff6;
      margin-bottom: 10px;
    }

    .skill {
      font-size: 15px;
      font-weight: 600;
      @apply text-blackText;
      text-align: center;
      width: 100%l;
      margin-bottom: 10px;
    }

    .details {
      width: 100%;
      font-size: 14px;
      @apply text-blackText;
      padding: 0px 10px;
      text-align: center;
    }

    .card-footer {
      height: 50px;
      margin-top: auto;
      padding: 10px;

      .mentoring-arrow {
        margin: 0px 7px;
      }
    }
  }
}

.qrs-badge {
  display: flex;
  width: fit-content;
  height: 34px;
  background: #f2f9ff;
  border: 1px solid #cce9ff;
  border-radius: 18px;
  padding: 3px 5px;

  .dp {
    width: 26px;
    height: 26px;
    border-radius: 26px;
  }
}

.qrs-generic-badge {
  display: flex;
  width: fit-content;
  height: 24px;
  border-radius: 18px;
  padding: 2px 0px 2px 6px;
  font-size: 15px;
  font-weight: 600;
  align-items: center;
  align-self: center;

  .value {
    margin-right: 5px;
  }

  &.xp {
    border: solid 1px #fdeecb;
    color: #feb200;

    .icon {
      width: 24px;
      height: 24px;
    }
  }

  &.session {
    background: #193755;
    border: solid 1px #4e9ff3;
    color: #4e9ff3;

    .icon {
      width: 24px;
      height: 24px;
    }
  }

  &.time {
    @apply text-qureosBlue flex-row-reverse;
    border: solid 1px #feb200;
    padding: 2px 6px 2px 0px;
    border-left: none;

    .value {
      font-weight: normal;
      margin: 0px 0px 0px 5px;
      // text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }

    .icon {
      margin: 0px auto 0px 0px;
    }
  }

  &.status {
    .value {
      @apply uppercase;
      color: #feb200;
    }

    &.shortlisted {
      .value {
        color: #4e9ff3;
      }
    }

    &.offered {
      .value {
        color: #50d498;
      }
    }
  }

  &.currency {
    border: solid 1px #51d498;
    color: #51d498;

    .c-tag {
      background: #51d498;
      color: white;
      padding: 0px 5px;
      border-radius: 0px 10px 10px 0px;
    }
  }
}

.with-custom-pagination {
  .modal-content {
    .pagination {
      @apply absolute flex justify-between w-full;
      top: 50%;
      padding: 0 25px;
    }
  }
}

.qureos-slider {
  width: 100%;

  .slider-body {
    width: 100%;

    .slider-content {
      overflow: auto;
      width: 100%;
      display: flex;
      scroll-behavior: smooth;

      &::-webkit-scrollbar {
        height: 0px;
      }
      &::-webkit-scrollbar {
        background: transparent !important;
      }
      &::-webkit-scrollbar-thumb {
        background: transparent !important;
      }
    }
  }
}

.certificate-viewer {
  max-width: 1100px;
  padding-left: 100px;

  .qrs-file-viewer {
    max-height: initial;
  }
}

.ant-select-selection-search input[type='search']:focus {
  @apply ring-0 border-0 outline-0;
}

.notification-bubble {
  @apply absolute text-white bg-qureosBlue;
  font-size: 10px;
  top: -1px;
  padding: 1px 5px;
  border-radius: 99px;
  right: -17px;
}
.ant-drawer-header-title {
  flex-direction: row-reverse;
  justify-content: space-between;
  width: 100%;
  & button {
    margin-right: 0 !important;
    &:hover {
      background-color: transparent;
    }
  }
}
.webinar-col2 {
  @apply flex;

  img {
    height: 220px;
    margin: 0 10px;
  }

  @media (max-width: $mobile) {
    @apply flex-col flex-col-reverse;

    img {
      width: 160px;
      margin: 0 auto;
    }
  }
}

.ant-pagination-item {
  margin: 0;
  border-collapse: collapse;
}
.ant-pagination-item-active {
  background: rgba(29, 190, 101, 0.05);
  border-color: #1dbe65;
}
.ant-pagination {
  display: flex;
  .ant-pagination-prev {
    margin-left: auto;
  }
  .ant-pagination-item:focus-visible,
  .ant-pagination-item:hover {
    border-color: #1dbe65;
  }
  .ant-pagination-item-link {
    @apply flex items-center justify-center;
  }
  .ant-pagination-jump-prev
    .ant-pagination-item-container
    .ant-pagination-item-link-icon,
  .ant-pagination-jump-next
    .ant-pagination-item-container
    .ant-pagination-item-link-icon {
    color: #1dbe65;
  }
  .ant-pagination-jump-prev .ant-pagination-item-container,
  .ant-pagination-jump-next .ant-pagination-item-container {
    display: grid;
    place-items: center;
    height: 100%;
  }
  .ant-pagination-prev,
  .ant-pagination-jump-prev,
  .ant-pagination-jump-next {
    margin-right: 0;
  }
}

.dont-break-out {
  overflow-wrap: break-word;
  word-wrap: break-word;
  -ms-word-break: break-all;
  word-break: break-all;
  word-break: break-word;
  -ms-hyphens: auto;
  -moz-hyphens: auto;
  -webkit-hyphens: auto;
  hyphens: auto;
}
