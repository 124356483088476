.qrs-top-menu {
  @apply flex justify-end;

  .right-qrs-top-menu {
    @apply flex justify-end;

    .notification-icon {
      @apply flex items-center justify-center relative;
      width: 80px;

      img {
        width: 25px;
      }

      &.unread {
        &:before {
          @media (max-width: $mobile) {
            right: 28px;
            top: 5px;
          }

          @media (max-width: $small-mobile) {
            right: 15px;
          }

          border-radius: 100%;
          width: 8px;
          height: 8px;
          background-color: #ff418c;
          content: ' ';
          right: 25px;
          position: absolute;
          top: 5px;
        }
      }
    }
  }
}
