@import './variables';

$inprogress: #ffb100;
$revise: #b553c7;
$submitted: #336ad3;
$stuck: #e2445c;
$completed: #33d390;

.new-workspace {
  .sidebar {
    z-index: 999;
  }

  @include workspace-drawer;

  .content-wrapper {
    .content {
      background: #fff;
      position: relative;

      @include workspace-drawer;
    }
  }

  &.open-drawer {
    @include workspace-drawer-open;

    .content-wrapper {
      .content {
        @include workspace-drawer-open;

        .new-workspace-container {
          width: calc(100% - 524px);
          min-width: $qrs-col-4;

          @media (max-width: $mobile) {
            width: 100%;
          }
        }
      }
    }
  }
}

.new-workspace-container {
  @apply flex flex-col w-full;
  transition: all 0.3s ease-in;
  padding: 0px 15px;

  @media (max-width: $mobile) {
    padding: 0px;
  }

  .header-column,
  .table-header-column {
    @apply flex w-full;
    padding: 15px 0px;
  }

  .header-column {
    @apply flex flex-wrap justify-between items-center;
    min-height: 65px;
    height: auto;
    border-bottom: #eaeaea solid 1px;

    .left-menu,
    .right-menu {
      @apply items-center;
      flex: none;

      @media (max-width: $tablet) {
        max-width: 100% !important;
        justify-content: center !important;
      }

      @media (max-width: $small-mobile) {
        max-width: 100%;
        justify-content: center !important;
        width: fit-content !important;
      }
    }

    .left-menu {
      @apply flex;
      max-width: $qrs-col-3;
      width: 100%;
      height: 100%;
    }

    .right-menu {
      @apply flex flex-wrap justify-end;
      max-width: $qrs-col-3;
      width: 100%;
      height: 100%;
    }

    .workspace-menu {
      @apply flex cursor-pointer;
      margin: 0px 20px;

      &:first-child {
        margin-left: 0px;
      }

      .icon {
        margin-right: 10px;
      }
      .text {
        @apply text-blackText;
        font-size: 15px;
      }
      .dropdown-arrow {
        margin-left: 15px;
      }

      .chat-bubble {
        @apply flex bg-qureosRed text-white ml-auto justify-center items-center self-center;
        border-radius: 100%;
        padding: 5px;
        width: fit-content;
        min-width: 25px;
        height: 25px;
        margin-left: 10px;
      }

      @media (max-width: $small-mobile) {
        width: fit-content;
        margin: 0px 10px;

        .icon {
          display: none;
        }

        .dropdown-arrow {
          margin-left: 10px;
        }
      }
    }

    .workspace-menu-btn {
      @apply flex justify-center items-center cursor-pointer;
      width: $qrs-col-1;
      height: 30px;
      margin: 4px;
      border-radius: 25px;
      border: solid 0.5px $qrs-black;
      padding: 5px 0px;

      @media (max-width: $small-mobile) {
        max-width: 110px;

        &.xs-hide {
          display: none;
        }
        &.xs-shrink {
          order: 2;
          width: 40px;

          .text {
            display: none;
          }
          .icon {
            margin-right: 0px;
          }
        }
      }

      &:first-child {
        margin-left: 0px;
      }

      &.time-status-btn {
        background: #50d498;
        border-color: #50d498;
        width: auto;
        min-width: $qrs-col-1;
        padding: 5px;

        @media (max-width: $small-mobile) {
          max-width: 88px;

          .icon {
            display: none;
          }
        }

        &.warning {
          background: #ffb100;
          border-color: #ffb100;
        }

        &.danger {
          background: #fa6767;
          border-color: #fa6767;
        }

        &.completed {
          background: $qrs-blue;
          border-color: $qrs-blue;
        }

        .icon {
          margin-right: 5px !important;
        }

        .text {
          @apply flex;
          color: white;

          @media (max-width: $small-mobile) {
            font-size: 12px;
          }
        }
      }

      &.highlight-btn {
        &.info {
          border-color: $qrs-blue;

          .text {
            color: $qrs-blue;
          }
        }
      }

      .icon {
        margin-right: 12px;
      }
      .text {
        font-size: 15px;
        color: $qrs-black;
      }
      .dropdown-arrow {
        margin-left: 15px;
      }
    }
  }

  .table-header-column {
    @apply flex justify-between;
    min-height: 55px;
    height: auto;

    .project-title {
      @apply flex font-semibold text-blackText;
      font-size: 18px;
    }

    .table-header {
      @apply flex w-full justify-center;
      max-width: $qrs-col-2;

      @media (max-width: $mobile) {
        display: none;
      }

      .header-item {
        @apply flex justify-center w-full items-center cursor-pointer;

        &.sorted {
          .icon {
            transform: rotate(180deg);
          }
        }

        .text {
          margin-right: 6px;
        }
        .icon {
          transition: transform 0.2s ease-in;
        }
      }
    }
  }

  .task-container {
    @apply flex flex-col w-full;

    .task-item {
      @apply flex;
      border: solid 1px $qrs-light-blue;
      border-radius: 5px 0px 0px 5px;

      &.selected {
        background: #e7e9ee;
      }

      .option-bar {
        @apply flex h-full cursor-pointer justify-center;
        overflow: hidden;
        width: 20px;
        height: auto;
        border-radius: 5px 0px 0px 5px;
        background: #8b98a8;
        flex: none;

        img {
          width: 6px;
        }
      }

      .task-title {
        @apply flex flex-col justify-center w-full h-auto;
        padding-left: 15px;
        min-height: 50px;

        @media (max-width: $small-mobile) {
          min-height: 60px;
          justify-content: space-evenly;

          .mini-icon-bar {
            display: flex !important;
            justify-content: flex-start;
            padding: 0px;

            img {
              height: 16px;
            }

            .xp-box {
              background: transparent;
              border: none;
              margin: 0px;
              align-items: center;

              .xp-value {
                font-size: 12px;
                margin: 0px;
                padding: 0px;
              }

              .xp-icon {
                height: 15px;
              }
            }
          }
        }

        .text {
          @apply flex items-center text-blackText w-full cursor-pointer;
          font-size: 14px;

          @media (max-width: $mobile) {
            &.limit {
              display: -webkit-box;
              -webkit-line-clamp: 2;
              -webkit-box-orient: vertical;
              overflow: hidden;
              text-overflow: ellipsis;
              padding-top: 2px;
            }
          }
        }

        .mini-icon-bar {
          display: none;
        }
      }

      .task-elements {
        @apply flex ml-auto w-full justify-end;
        max-width: $qrs-col-3;
        height: auto;
        flex: none;

        @media (max-width: $mobile) {
          flex: 1;
        }

        .status-bar,
        .duration-bar {
          width: 100%;
          max-width: $qrs-col-1;
        }

        .status-bar {
          @apply flex justify-center h-auto items-center;
          background: #79affc;
          color: white;

          @media (max-width: $mobile) {
            max-width: none;
            width: 45px;
          }

          .status-item {
            @apply flex w-full h-full justify-center items-center cursor-pointer;

            .icon {
              display: none;
            }

            .caret {
              margin-left: 5px;
            }

            @media (max-width: $mobile) {
              .icon {
                display: flex;
              }

              .caret {
                display: none;
              }

              span {
                display: none;
              }
            }

            &.inprogress {
              background: $inprogress;
            }
            &.revise {
              background: $revise;
            }
            &.submitted {
              background: $submitted;
            }
            &.stuck {
              background: $stuck;
            }
            &.completed {
              background: $completed;

              .rate-stars {
                width: 10px;
                height: 10px;
                margin: 0px 2px 0px 2px;
              }
            }
          }
        }

        .duration-bar {
          @apply flex justify-center h-auto items-center;

          @media (max-width: $mobile) {
            max-width: none;
            width: 70px;
          }

          &.danger {
            .text {
              color: red;
            }
          }
          &.warning {
            .text {
              color: #ffb100;
            }
          }
          &.ontime {
            .text {
              color: #33d390;
            }
          }

          .text {
            @apply text-blackText;
            font-size: 14px;
          }
        }
      }

      .mini-icon-bar {
        @apply flex justify-end h-auto items-center;
        padding: 0px 5px;

        .icon {
          margin: 0px 5px;
          cursor: pointer;
        }

        .xp-box {
          @apply flex;
          background: #fdf9f0;
          border: solid 1px #fdeecb;
          border-radius: 18px;
          margin: 0px 5px;
          height: 24px;

          .xp-icon {
            @apply ml-auto;
            width: 22px;
          }
          .xp-value {
            @apply self-center;
            color: #feb200;
            font-size: 15px;
            padding: 2px 5px 2px 10px;
          }
        }

        @media (max-width: $mobile) {
          display: none;
        }
      }
    }
  }
}

.ant-dropdown {
  &.workspace-main-menu-dropdown {
    width: 132px;
    overflow: hidden;
    box-shadow: #6a708033 0 0 20px;
    border-radius: 12px;

    .ant-dropdown-menu {
      .mm-item {
        @apply flex;
        cursor: pointer;
        padding: 5px;

        .icon {
          margin-right: 10px;
        }

        .text {
          font-size: 11px;
          color: #576271;
        }
      }
    }
  }
}

// Style Injection

.new-workspace {
  .thread-container {
    border: 1px solid $qrs-gray;
    border-radius: 10px;
    width: 100%;
    height: auto;
    overflow: hidden;
    margin-top: 24px;
    transition: background 0.3s ease-in;

    &.highlighted {
      background: #fed;
    }

    .header {
      padding: 10px;
      display: flex;
      border-bottom: 1px solid $qrs-gray;
      height: 50px;
      .profile-pic {
        width: 30px;
        height: 30px;
        border-radius: 100%;
        overflow: hidden;
      }

      .name {
        @apply text-blackText;
        font-size: 14px;
        padding: 4px 10px;
        display: flex;
      }

      .right-panel {
        margin: 0 0 0 auto;

        .status-box {
          border-radius: 5px;
          padding: 1px;
          height: 20px;
          font-size: 11px;
          color: white;
          align-self: center;
          margin-right: 10px;
          justify-content: center;
          @apply flex;

          &.flow {
            width: fit-content;
          }

          img {
            width: 11px;
            height: 11px;
            align-self: center;
            margin: 0px 5px 0px 0px;
          }

          &.submitted {
            background: $submitted;
          }
          &.revise {
            background: $revise;
          }

          &.completed {
            background: $completed;
            padding: 1px 2px 1px 2px;

            .rate-stars {
              width: 10px;
              height: 10px;
              margin: 0px 2px 0px 2px;
            }
          }
        }

        .timebox {
          margin: 0 15px 0 0;
          color: #aebbc9;
          font-size: 12px;

          span {
            padding-top: 6px;
          }

          img {
            margin-right: 5px;
          }
        }

        .menu {
          cursor: pointer;
          padding: 0px;
          border: none;
        }
      }
    }

    .content {
      padding: 20px;
      color: #576271;
      font-size: 13px;
      border-bottom: 1px solid $qrs-gray;
      white-space: break-spaces;

      a {
        @apply text-qureosBlue block;
      }
    }

    .reply-box {
      border-top: 1px solid $qrs-gray;
      color: #3aa4f3;
      padding: 8px;
      text-align: center;
      display: flex;
      font-size: 14px;

      img {
        margin-right: 7px;
      }
    }

    .message-box {
      background: transparent;
      box-shadow: none;
      align-items: normal;
      margin-top: 10px;
      padding: 10px 0px;

      &.empty {
        display: none;
        padding: 0px;
        border: none;
      }

      .message-item {
        &.sender {
          .message {
            align-self: flex-end;
            border-radius: 10px 10px 0px 10px;
            background: #f3f8fd;

            .user {
              color: #3aa4f3;
            }
          }

          .time {
            align-self: flex-end;
          }
        }

        .message {
          padding: 5px 9px;
          background: #ebfbf4;
          border-radius: 10px 10px 10px 0px;
          max-width: 400px;
          align-self: flex-start;
          margin: 0px 10px;
          word-break: break-word;

          .user {
            color: #50d498;
            font-size: 10px;
          }

          .text {
            font-size: 13px;
            @apply text-blackText;
            white-space: break-spaces;
          }
        }
        .time {
          align-self: start;
          margin: 0px 10px;
          font-size: 10px;
        }
      }
    }
  }

  .thread-footer-options {
    margin-top: 8px;
    width: 100%;
    justify-content: space-evenly;

    .footer-btn {
      height: 33px;
      background: #aebbc9;
      border-radius: 5px;
      color: white;
      font-size: 15px;
      flex: 1;
      text-align: center;
      padding: 5px;
      cursor: pointer;

      &:first-child {
        margin-right: 11px;
      }

      &.active {
        &.accept {
          background-color: #33d390;
        }
        &.revise {
          background-color: #b553c7;
        }
      }
    }
  }

  .thread-footer-actions {
    @apply flex flex-col;

    textarea {
      min-height: 0px;
      height: 64px;
      border: 1px solid #d0e9fd;
      border-radius: 10px;
      padding: 9px 10px;
      font-size: 13px;
      color: #8898aa;
      background: white;
      resize: none;
      margin: 13px 0px 0px 0px;
    }

    .action-btn {
      @apply bg-qureosBlue;
      border-radius: 23px;
      color: white;
      cursor: pointer;
      padding: 5px;
      text-align: center;
      font-size: 15px;
      margin: 8px 0px 0px 0px;
      align-self: flex-end;
      justify-content: center;
      width: 115px;
    }

    .rate-box {
      margin-top: 15px;

      .title {
        width: 120px;
      }

      .star-container {
        margin: 5px 0px 18px 0px;
        align-self: flex-end;

        .star-box {
          width: 18px;
          height: 18px;
          margin: 0px 6px 0px 0px;

          img {
            height: 100%;
            width: 100%;
          }
        }
      }
    }
  }

  .editor-footer {
    .footer-links {
      padding: 8px 0px 8px 0px;
      font-size: 12px;
      color: #3aa4f3;
      margin-right: 18px;
      display: flex;
      cursor: pointer;
      border: none;
      box-shadow: none;
      outline: none;

      img {
        margin: -5px 8px 0px 0px;
      }
    }

    button {
      &.footer-links {
        img {
          margin: 0px 8px 0px 0px;
        }
      }
    }

    .footer-btn {
      margin-top: 10px;
      margin-left: auto;

      @apply bg-qureosBlue text-white px-8 py-2 rounded-full;

      &.cancel-button {
        background: #e9ecef;
        margin-left: 10px;
        @apply text-gray-600;
      }
    }
  }

  .attachments {
    margin-top: 15px;

    .file-item {
      width: fit-content;
      margin-bottom: 10px;
      max-width: 100%;

      &.initial-file {
        background: #eee;
        padding: 3px 10px;
        border-radius: 10px;
        margin-right: 5px;
        max-width: 300px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;

        img {
          margin-right: 5px;
        }
      }

      img {
        margin-right: 8px;
      }

      .file-name {
        width: 100%;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
      }

      &.removable {
        background-color: #ebfcf4;
        padding: 10px;
        @apply rounded-full;

        .remove-file {
          width: 10px;
          margin-left: 10px;
          margin-right: 15px;
          cursor: pointer;
        }
      }
    }
  }

  .show-more-tick {
    @apply flex cursor-pointer;
    color: $qrs-blue;
    font-size: 15px;
    margin-top: 10px;
  }

  .status-box {
    width: 82px;
    height: 33px;
    border-radius: 5px;
    text-align: center;
    font-size: 11px;
    color: #ffffff;
    padding: 9px;
    text-transform: capitalize;

    &.flow {
      width: fit-content;
    }

    &.submitted {
      background: $submitted;
    }
    &.revise {
      background: $revise;
    }

    &.completed {
      background: $completed;
      padding: 1px 2px 1px 2px;
    }
  }

  .task-generic-container {
    padding: 15px;
  }

  // Chat Box
  $chat-gray: #d6dee8;
  $chat-blue: #3aa4f3;
  $chat-dark: #444444;
  $chat-light-gray: #6a7a8b;

  .chat-box {
    @apply flex flex-col w-full overflow-hidden bg-white;
    height: 100%;
    max-width: 100%;
    border-radius: 0px !important;
    transition: all 0.3s ease;
    position: absolute;
    box-shadow: none;
    top: 0px;
    left: 0px;

    .chat-header {
      @apply flex w-full;
      height: 54px;
      padding: 10px 0px 10px 22px;
      box-shadow: #3e44571a 0 5px 5px;
      flex: none;

      .back-icon {
        @apply flex self-center cursor-pointer;
        margin-right: 15px;
      }

      .user-photo {
        @apply flex;
        margin-right: 15px;
        width: 34px;
        height: 34px;
        border-radius: 34px;
      }
      .chat-title {
        @apply flex text-qureosBlack self-center font-semibold;
        font-size: 18px;
      }

      .chat-name {
        @apply flex text-qureosBlack self-center w-full;
        font-size: 15px;

        .verified {
          @apply flex self-center;
          margin-left: 5px;
        }
      }

      .header-icons {
        @apply flex ml-auto;

        .icon {
          margin-right: 20px;
          cursor: pointer;
        }
      }
    }

    .chat-body {
      @apply w-full overflow-auto;
      // height: 316px;
      height: 100%;
      border-bottom: solid 1px $chat-gray;
      overflow-x: hidden;

      .chat-body-info {
        @apply flex justify-center font-light;
        padding: 10px;
        color: #4d5f72;
        font-size: 15px;
      }

      .chat-item {
        @apply flex cursor-pointer;
        padding: 15px;
        border-bottom: solid 1px $chat-gray;

        &:hover {
          background: #eee;
        }

        .user-photo {
          width: 54px;
          height: 54px;
          border-radius: 54px;
          margin-right: 15px;
          object-fit: cover;

          &.special-chat {
            background: #6d7a89;
            padding: 10px;
          }
        }

        .user-chat-info {
          @apply flex flex-col;

          .name {
            @apply flex text-qureosBlack;
            font-size: 15px;
            margin-bottom: 3px;
          }

          .desc {
            @apply flex font-light;
            color: #4d5f72;
            font-size: 14px;
            height: 25px;
            overflow: hidden;
            padding-right: 20px;
          }
        }

        .chat-bubble {
          @apply flex bg-qureosRed text-white ml-auto justify-center items-center self-center;
          border-radius: 100%;
          padding: 5px;
          width: fit-content;
          min-width: 25px;
          height: 25px;
        }
      }

      .message-container {
        @apply flex flex-col;
        padding: 15px 20px 0px 20px;

        &:last-child {
          .message-time {
            display: flex !important;
          }
        }

        .message-main {
          @apply flex w-full;

          .user-photo {
            @apply flex self-end;
            height: 24px;
            width: 24px;
            border-radius: 24px;
            margin-right: 7px;
            object-fit: cover;

            &.fade {
              opacity: 0;
            }
          }

          .message-item {
            @apply flex;
            width: fit-content;
            padding: 8px 16px;
            background: $chat-dark;
            border-radius: 15px 15px 15px 0px;
            font-size: 13px;
            color: white;
            max-width: 230px;

            a {
              text-decoration: underline;

              &:hover {
                color: white;
              }
            }
          }
        }

        .message-time {
          @apply flex font-light;
          font-size: 10px;
          color: $chat-light-gray;
          margin-top: 3px;
          margin-bottom: 5px;
          display: none;
        }

        &.sender {
          .user-photo {
            order: 2;
            margin-right: 0px;
            margin-left: 7px;
          }

          .message-item {
            @apply ml-auto;
            background: $chat-blue;
            border-radius: 15px 15px 0px 15px;
          }

          .message-time {
            @apply ml-auto;
          }
        }
      }
    }

    .chat-footer {
      @apply flex w-full mt-auto justify-center items-start;
      height: 120px;
      padding: 15px;

      @media (max-width: $small-mobile) {
        height: 70px;
      }

      .comm {
        @apply flex flex-col-reverse w-full items-center;

        @media (max-width: $small-mobile) {
          flex-direction: row;
          position: static !important;
        }

        .textfield {
          @apply flex-1 relative w-full;
          padding: 0;
          border-radius: 0px;

          @media (max-width: $small-mobile) {
            padding: 0 13px;
            border-radius: 999px;
          }

          input {
            @apply w-full;
            background: #eef2f8;
            color: #8898aa;
            padding: 10px 12px;
            padding-right: 40px;
            border: none;
            border-radius: 0px;
            margin-bottom: 10px;

            @media (max-width: $small-mobile) {
              @apply rounded-full;
            }
          }

          img {
            @apply absolute cursor-pointer;
            right: 26px;
            top: 15px;
          }
        }

        @media (max-width: $mobile) {
          position: fixed;
          z-index: 10;
          bottom: 0;
          background: white;
        }

        .photo-uploader,
        .voice {
          @apply relative;
          align-self: flex-start;
          width: 20px;

          @media (max-width: $small-mobile) {
            align-self: center;
          }

          .popover {
            left: -230px;

            &:before {
              content: ' ';
              width: 0;
              height: 0;
              display: inline-block;
              border: 10px solid transparent;
              border-top-color: white;
              position: absolute;
              right: 7px;
              bottom: -20px;
            }
          }
        }

        .photo-uploader {
          @apply flex;

          .view-files {
            @apply cursor-pointer;
            margin-left: 30px;
          }

          @media (max-width: $mobile) {
            width: auto;

            .view-files {
              display: none;
            }
          }
        }
      }
    }
  }
}

.filter-menu-container {
  width: auto !important;
  height: auto !important;
}

/* New Student Profile */

.new-student-profile-container {
  @apply flex flex-col w-full;

  .common-section {
    border-bottom: solid 1px $qrs-gray;
    padding: 20px 15px;

    .common-title {
      @apply text-blackText font-semibold;
      font-size: 15px;
      margin-bottom: 15px;
    }

    .common-tags {
      @apply flex flex-wrap;

      .common-tag-item {
        @apply justify-center items-center text-blackText;
        font-size: 13px;
        width: fit-content;
        height: auto;
        cursor: pointer;
        padding: 5px 16px;
        border-radius: 25px;
        border: solid 1px #1c1f2a;
        margin: 0px 4px 4px 4px;
        flex: none;
      }
    }
  }

  .about-section {
    @apply flex flex-col;

    .about-header {
      @apply flex;
      margin-bottom: 20px;

      @media (max-width: $small-mobile) {
        flex-wrap: wrap;

        .info {
          width: 200px;
          flex: none;
        }

        .action-btn {
          margin-left: 0px !important;
          margin-top: 20px;
        }
      }

      .dp {
        width: 65px;
        height: 65px;
        border-radius: 100%;
        margin-right: 15px;
      }

      .info {
        @apply flex flex-col;

        .name {
          @apply flex;

          .title {
            @apply flex text-blackText font-semibold;
            font-size: 18px;
            margin-right: 7px;
          }

          .icon {
          }
        }

        .details {
          @apply flex items-center;

          .item {
            @apply flex;
            margin-left: 20px;

            &:first-child {
              margin-left: 0px;
            }

            .icon {
              margin-right: 5px;
            }

            .value {
              @apply text-blackText;
              font-size: 15px;
            }
          }
        }
      }

      .action-btn {
        @apply flex ml-auto justify-center items-center cursor-pointer;
        border: solid 0.5px $qrs-black;
        height: 30px;
        padding: 5px 14px;
        width: fit-content;
        border-radius: 30px;

        &:hover {
          background: $qrs-blue;
          border: solid 1px $qrs-blue;
          color: white;
        }
      }
    }

    .about-footer {
      @apply flex;

      .bio {
        @apply flex;
        font-size: 13px;
        color: #535f6e;
      }
    }
  }

  .completed-projects-box {
    @apply w-full flex flex-col;
    overflow: hidden;
    border-radius: 10px 10px 0px 0px;
    border: solid 1px #d0e9fd;
    margin-bottom: 15px;

    &:before {
      content: ' ';
      @apply w-full;
      height: 5px;
      background-color: $qrs-blue;
    }

    .content-box {
      @apply w-full flex flex-col;
      padding: 15px;

      .project-box-header {
        @apply flex flex-col;
        margin-bottom: 6px;

        .info {
          @apply flex;

          .title {
            @apply text-blackText;
            font-size: 15px;
            margin-right: 15px;
            align-self: center;
          }

          .rating {
            @apply flex;
            align-items: center;

            .icon {
              margin-right: 5px;
            }

            .value {
              font-size: 15px;
            }
          }

          .logo {
            @apply flex ml-auto;
            height: 50px;
            width: 100px;
            object-fit: scale-down;
          }
        }

        .provider {
          @apply flex;
          margin-bottom: 3px;

          .company-name {
            @apply font-light text-blackText;
            font-size: 15px;
          }
        }
      }
      .project-box-body {
        @apply flex;

        .content {
          @apply text-blackText;
          font-size: 13px;

          p {
            display: -webkit-box;
            -webkit-line-clamp: 3;
            -webkit-box-orient: vertical;
            overflow: hidden;
          }
        }
      }
    }
  }
}

.new-company-profile-container {
  @apply flex flex-col w-full;

  .about-section {
    @apply flex flex-col;

    .about-header {
      @apply flex;
      margin-bottom: 25px;

      .dp {
        width: 75px;
        height: 75px;
        border-radius: 100%;
        margin-right: 15px;
      }

      .info {
        @apply flex flex-col;

        .name {
          @apply flex text-blackText font-semibold;
          font-size: 18px;
        }

        .company-name {
          @apply flex font-light;
          font-size: 14px;
        }

        .details {
          @apply flex flex-col items-center;
          font-size: 14px;

          .item {
            @apply flex w-full;

            .icon {
              margin-right: 10px;
            }

            .value {
              @apply text-blackText font-light;
            }
          }
        }
      }

      .logo {
        @apply flex ml-auto;
        height: 25px;
        width: 100px;
        object-fit: scale-down;
      }
    }

    .about-body {
      padding-bottom: 25px;
      border-bottom: solid 1px #d7dee7;

      .brief {
        @apply flex flex-col;
        margin-bottom: 20px;

        .title {
          @apply flex font-semibold;
          font-size: 15px;
          margin-bottom: 16px;
        }

        .content {
          @apply flex;
          font-size: 13px;
        }
      }

      .media {
        @apply flex flex-wrap justify-start w-full;

        .media-item {
          @apply flex;
          flex: none;
          border-radius: 10px;
          background-color: #e5f3fa;
          height: 76px;
          width: 92px;
          margin-left: 8px;
          overflow: hidden;
          padding: 5px;

          img {
            object-fit: cover;
            width: 100%;
            height: 100%;
          }

          &:first-child {
            margin-left: 0px;
          }
        }
      }
    }

    .about-footer {
      @apply flex;
      padding: 20px 0px;

      .content {
        @apply flex w-full;

        .common-tags {
          @apply flex flex-wrap;

          .common-tag-item {
            @apply justify-center items-center text-blackText;
            font-size: 13px;
            width: fit-content;
            height: auto;
            cursor: pointer;
            padding: 5px 16px;
            border-radius: 25px;
            border: solid 1px #1c1f2a;
            margin: 0px 4px 4px 4px;
            flex: none;
          }
        }

        .social-links {
          @apply flex ml-auto;
          flex: none;

          a {
            img {
              width: 30px;
              height: 30px;
              margin: 0px 7px;
            }
          }
        }
      }
    }
  }
}

.job-page {
  background: white;
  min-height: 100vh;
  height: auto;

  .project-workspace-container {
    @media (max-width: $small-mobile) {
      padding: 15px;
    }
  }
}

.workspace-task-description {
  @apply rounded-lg flex flex-col;
  border: 1px solid #d7dee7;

  .parsed-content {
    padding: 15px;
  }

  .reply {
    @apply text-qureosBlue w-full text-center flex justify-center items-center;
    border-top: 1px solid #d7dee7;
    padding: 5px 0;

    img {
      margin-right: 5px;
    }
  }

  .update-editor {
    border-top: 1px solid #d7dee7;
  }
}

// COHORT

.new-workspace-container {
  .workspace-menu-btn {
    &.schedule-btn {
      background-color: $qrs-blue;
      border-color: $qrs-blue;
      color: white;
    }
  }

  .table-header-column {
    .project-title {
      .call-btn {
        @apply flex justify-center items-center font-semibold cursor-pointer;
        width: fit-content;
        height: 30px;
        border-radius: 24px;
        background-color: $qrs-red;
        color: white;
        font-size: 15px;
        padding: 15px;
      }
    }
  }

  .completed-cohort-message {
    @apply flex w-full items-center;

    @media (max-width: $small-mobile) {
      flex-wrap: wrap;
      justify-content: center;
    }

    .completed-message {
      @apply flex flex-col w-full;
      max-width: $qrs-col-4;
      margin: 20px 0px;
      padding: 13px 15px;
      border-radius: 10px;
      border: solid 1px $qrs-blue;
      margin-right: 12px;

      .title {
        @apply font-bold flex;
        font-size: 18px;
        color: $qrs-black-3;
        margin-bottom: 10px;
      }

      .desc {
        font-size: 15px;
        color: #6d7a89;
        margin-bottom: 14px;
      }

      .message-footer {
        @apply flex w-full;

        .links {
          @apply cursor-pointer;
          font-size: 15px;
          color: $qrs-blue;
        }
      }
    }
  }
}

.apprentice-selection-container {
  @apply flex w-full items-center h-auto;
  overflow: hidden;
  overflow-x: auto;
  padding-bottom: 10px;
  border-bottom: solid 1px #d7dee7;

  .apprentice-box {
    @apply flex cursor-pointer relative;
    flex: none;
    margin-right: 5px;
    border-radius: 40px;
    border: solid 2px #d7dee7;
    width: 43px;

    &.active {
      cursor: default;
      border: solid 2px $qrs-blue;
    }

    .notif {
      @apply flex absolute;
      height: 13px;
      width: 13px;
      background: #ff418c;
      right: 0px;
      bottom: 0px;
      border-radius: 13px;
    }

    .user-photo {
      @apply flex;
      width: 40px;
      height: 40px;
      border-radius: 40px;
    }
  }
}

.tasks-details-form {
  .ant-picker {
    border: none;
    margin-left: -10px;
    width: 250px;
    box-shadow: none;

    .ant-picker-suffix,
    .ant-picker-clear {
      display: none;
    }
  }
}
