.dropdown-root {
  .ant-dropdown-menu {
    border-radius: 0px 0px 8px 8px;
    position: absolute;
    top: -6px;
    box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0.1),
      0px 4px 6px -2px rgba(0, 0, 0, 0.05);
    width: 100%;
    border: 1px solid #d1d5db;
    max-height: 200px;
    min-height: 40px;
    overflow-y: auto;
    overflow-x: hidden;
    .ant-dropdown-menu-item {
      cursor: default;
    }
  }
  .ant-checkbox-wrapper {
    flex-grow: 1;
    .ant-checkbox-checked .ant-checkbox-inner {
      @apply border-qureosPrimaryLight;
      @apply bg-qureosPrimaryLight;
      @apply outline-qureosPrimaryLight;
    }
    span {
      margin-bottom: 0px !important;
      flex: none;
    }
  }
}
