@import './variables';

.qrs-dashboard {
  @apply -mt-4;

  @media (max-width: $mobile) {
    width: 100%;
  }

  .m-dbcontainer {
    @media (max-width: $mobile) {
      @apply bg-white p-4 rounded-lg shadow-md inline-block align-top;
      width: 100%;

      // & > div {
      //   display: block;
      //   margin-bottom: 10px;
      // }
    }
  }

  .dboard-container {
    @apply bg-white rounded-lg shadow-md inline-block align-top w-full;
    overflow: hidden;

    @media (max-width: $desktop-4), (max-width: $desktop-5) {
      height: 388px;
      padding: 15px;

      &.opportunities {
        padding-left: 0px;
      }

      &.skills {
        .dashboard-skill-slider {
          .card-box-container {
            margin-left: 10px;
          }
        }
      }
    }

    @media (max-width: $desktop-5) {
      height: 415px;
    }

    @media (max-width: $desktop-7) {
      .skill-card {
        height: 280px !important;
      }
    }

    .dashboard-skill-slider {
      .slick-slider {
        @media (max-width: $desktop-4),
          (max-width: $desktop-5),
          (max-width: $desktop-6),
          (max-width: $desktop-7) {
          width: 10000px;
          padding-left: 15px;
          position: relative;
          top: 10px;
        }

        .slick-list {
          .slick-track {
            .slick-slide {
              margin-right: 32px;
            }
          }
        }
      }
    }

    &:nth-child(2) {
      float: right;
    }

    .text-tip {
      font-size: 13px;
      text-align: center;
      color: #333;
    }

    .headline-pencil-trigger {
      width: 15px;
      cursor: pointer;
      margin: 10px;
      display: inline-block;
    }

    .heading-text {
      height: 70px;
      padding: 0px 20px 0px 20px;
      width: 100%;
      display: block;
      flex: none;
      overflow: hidden;

      &::before {
        content: '';
        width: 1px;
        height: 100%;
        display: inline-block;
        vertical-align: middle;
      }
      .text {
        width: calc(100% - 1px);
        display: inline-block;
        vertical-align: middle;
      }
    }

    .headline-modal {
      width: 600px;
      background: white;
      padding: 20px;
      border-radius: 12px;
      min-height: 350px;
      height: auto;
      padding-top: 30px;

      h1 {
        font-size: 22px;
      }

      .sub-tagline {
        font-size: 13px;
      }

      .textbox {
        text-align: right;
        margin-top: 30px;

        .char-counter {
          font-size: 12px;
        }
        textarea {
          background: #f8f8f8;
          padding: 10px;
          border-radius: 2px;
          height: 150px;
          text-align: left;
          resize: none;
          font-size: 16px;
        }

        button {
          display: inline-block;
          margin-top: 10px;
        }
      }
    }
  }
  .dashboard-item {
    width: calc(50% - 10px);
    height: 320px;

    @media (max-width: $mobile) {
      width: 100%;
    }
  }

  .badge-progress-box {
    position: relative;
    .badge {
      position: absolute;
      top: 7px;
      left: 50%;
      border-radius: 100%;
      width: 145px;
      height: 145px;
      object-fit: cover;
      background-color: white;
      transform: translateX(-50%);
    }

    .badge-label {
      background: #fff;
      padding: 5px;
      border-radius: 50px;
      padding: 5px 15px 5px 15px;
      color: $qrs-blue;
      position: absolute;
      border: solid 1px $qrs-blue;
      left: 50%;
      transform: translateX(-50%);
      top: -12px;
      text-align: center;

      &.complete {
        top: -8px;
      }
    }
  }

  .rep-info {
    margin-top: -30px;
    text-align: center;
    width: 100%;

    .rep-info-details {
      margin: 0 auto;
      margin-right: 15px;
      display: inline-block;
      font-weight: 600;

      img {
        display: inline-block;
        margin-right: 5px;
      }
    }
  }
}

.qrs-dashcard {
  // width: 280px !important;
  margin: 0 auto;

  @media (max-width: $desktop-4), (max-width: $desktop-5) {
    width: 250px !important;
    top: 11px;
  }

  @media (max-width: $desktop-7) {
    height: 300px;
  }

  @media (min-width: $desktop-8) {
    width: 350px !important;
    height: 350px !important;
  }

  &.push-content {
    top: 20px;
    position: relative;

    .skill-card {
      height: 280px;
    }
  }
}

.qrs-pap-box {
  width: 100%;
  max-width: 1366px;
  box-shadow: #aaa 0px 0px 13px;
  margin: 0 auto;
  height: calc(100vh - 100px);

  .logo {
    margin: 0 auto;
    max-width: 180px;
    padding: 10px;
  }

  .left-navig {
    width: 30%;
    background: #ddd;
    position: relative;

    .menu-tab {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      width: 100%;
      display: block;

      .item {
        width: 100%;
        border-bottom: solid 1px #eee;
        height: auto;
        padding: 10px;
        @apply text-qureosSecondary;
        font-weight: bold;
        font-size: 25px;
        cursor: pointer;
        background: white;
        display: block;

        &.active {
          @apply bg-qureosSecondary;
          color: #fff;
          cursor: default;
        }
      }
    }
  }
  .right-navig {
    width: 70%;

    .content {
      @apply bg-white;
      padding: 15px;
      height: 100%;
      overflow: auto;
      overflow-x: hidden;
    }
  }

  .left-navig,
  .right-navig {
    display: inline-block;
    vertical-align: top;
    height: 100%;
  }
}

.dashboard-section {
  @apply w-full flex;

  .section-wrapper {
    @apply w-full flex pt-6 pr-4;

    .section-item {
      @apply w-1/2;
    }
  }
}

.dashboard-job-slider {
  position: relative;

  .lock-logo {
    margin-left: 0px !important;
  }

  .slick-slider {
    .slick-list {
      text-align: center;
    }

    .slick-slide {
      width: 20.2vw !important;
      min-width: 280px;

      @media (max-width: $desktop-4) {
        min-width: 265px;
        margin-right: -10px;
      }
    }

    .slick-dots {
      position: absolute;
      bottom: 0px;
      text-align: center;

      @media (max-width: $desktop-4) {
        bottom: 10px;
      }

      @media (max-width: $desktop-7) {
        bottom: -5px;
      }

      li {
        border-width: 1px;
        height: 10px;
        width: 10px;
      }
    }
  }

  .dashboard-job-wrapper {
    @apply px-3 py-4;

    @media (max-width: $desktop-4) {
      width: 275px !important;
      height: 305px;
    }

    @media (max-width: $desktop-5) {
      width: 280px !important;
    }

    @media (max-width: $desktop-7) {
      top: -10px;
      height: 315px !important;
    }

    @media (min-width: $desktop-8) {
      width: 350px !important;
      height: 375px !important;
      top: -20px;
      margin-left: 20px;
    }
  }
}

.reputation-wrapper {
  @apply flex flex-row justify-between mt-5;

  .m-dbcontainer {
    @apply flex;

    .reputation-card {
      @apply bg-white p-4 w-3/4 rounded-lg shadow-md py-6 px-6;
    }

    .reputation-card-wrapper {
      @apply mt-5 flex flex-row justify-center w-full;

      .percentage-wrapper {
        @apply flex flex-col shadow-lg rounded-lg p-1 mt-1 w-2/6 mr-5;

        .progress-wrapper {
          @apply pt-5;
        }

        .percentage {
          @apply block text-4xl font-semibold;
        }

        .progress-info {
          @apply mt-2 text-center w-full mx-auto block text-sm font-semibold capitalize whitespace-nowrap;
        }

        .reputation-items {
          @apply flex w-full items-center px-4;
        }
      }

      .session-evaluation-wrapper {
        @apply w-5/6 flex flex-col;
      }
    }
  }
}

.info-card {
  @apply cursor-pointer flex items-center rounded-lg shadow-lg border border-gray-200 p-2 rounded-lg my-1;

  .info-card-separator {
    @apply h-24 border-r border-gray-400 mx-2;
  }

  .description {
    @apply text-sm text-gray-700 text-left w-2/3 flex items-center tracking-tighter leading-tight;
  }
}

.dashboard-rewards {
  @apply bg-white p-4 w-1/4 ml-4 rounded-lg shadow-md flex flex-col;
}

.mini-info {
  @apply w-full h-full flex shadow-lg bg-white rounded-lg items-center;

  .badge {
    @apply w-auto px-4 flex flex-col justify-center items-center;
  }

  .description {
    @apply w-auto px-2 flex flex-col;
  }
}

@media (max-width: $mobile) {
  .qrs-dashcard {
    width: 290px !important;
  }

  .dashboard-section {
    @apply flex-col;
    padding: 0 12px;

    .section-wrapper {
      @apply pr-0 justify-center;

      @media (max-width: $almostMobile) {
        @apply flex-col;
      }

      .section-item {
        @apply w-full mb-4 mx-auto;
        max-width: 320px;

        &:last-child {
          @apply mb-0;
        }
      }
    }
  }

  .qrs-dashboard {
    @apply pt-8;

    .dboard-container {
      @apply mb-4 pb-16;
      height: auto;

      .dashboard-skill-slider {
        padding-top: 27px;

        @media (max-width: $almostMobile) {
          padding-top: 80px;
        }

        .slick-slider {
          .slick-list {
            padding-left: 15px;

            .slick-track {
              @apply flex;

              .slick-slide {
                @apply flex justify-center;
                width: 256px !important;
                margin-right: 32px;

                &.slick-active {
                  width: 256px !important;
                }

                .card-box-container {
                  width: 256px;

                  img {
                    object-fit: scale-down;
                  }
                }
              }
            }
          }
        }

        .card-box-container {
          padding-left: 0;

          &.active-challenge {
            left: -6px;
            width: 268px;
          }
        }

        .pagination {
          @media (max-width: $almostMobile) {
            @apply relative flex justify-center;
          }
        }

        .slick-slider {
          margin-top: -20px;
        }
      }
    }
  }

  .dashboard-job-slider {
    margin-top: 40px;

    .slick-slider {
      .slick-slide {
        width: 300px !important;
      }
    }
  }

  .dboard-container {
    &.opportunities {
      .pagination {
        margin-left: 12px;
      }
    }
  }

  .reputation-wrapper {
    margin: 0 15px;

    .m-dbcontainer {
      @apply flex flex-col;

      .reputation-card {
        @apply w-full;
        padding: 0;
        box-shadow: none;
      }

      .reputation-card-wrapper {
        @apply flex-col;

        .percentage-wrapper {
          @apply mr-0 w-full;
          height: 300px;
          margin-bottom: 15px;

          .progress-wrapper {
            @apply pt-5 flex flex-col;
            height: 300px;

            .profile-strength {
              height: 197px;
              max-height: initial;
              min-height: initial;
            }
          }

          .progress-info {
            @apply mt-8;
          }
        }

        .session-evaluation-wrapper {
          @apply w-full flex flex-col;
        }
      }
    }
  }

  .dashboard-rewards {
    @apply w-full ml-0 mt-10 p-0;
    box-shadow: none;
  }

  .mini-info {
    @apply py-4;

    .badge {
      width: 80px;
    }

    .description {
      width: 200px;
    }
  }
}

@media (max-width: $almostMobile) {
  .info-card {
    @apply flex-col;

    .info-card-separator {
      @apply h-0 border-t w-24 mb-2 mt-6 border-r-0;
    }

    .description {
      @apply w-full pr-3;
    }
  }
}

.new-dashboard-container {
  @apply flex;

  .dashboard-holder {
    @apply w-full justify-center flex;

    @media (max-width: $tablet) {
      @apply flex-col;
      padding: 7px;
    }

    .dashboard-card {
      @apply flex w-full bg-white rounded-lg relative;
      height: fit-content;
      min-height: 100px;
      border: 1px solid #d7dee7;

      .title {
        padding: 15px 20px;
        text-transform: initial !important;
      }

      .description {
        padding: 0px 20px;
        font-size: 15px;
      }

      .action-btn {
        @apply flex justify-center cursor-pointer;
        height: 32px;
        width: fit-content;
        padding: 4px 16px;
        font-size: 18px;
        background: $qrs-blue;
        color: white;
        border-radius: 23px;
        font-size: 16px;
      }

      &.mentorship-card {
        margin-top: 15px;
        height: 400px;

        .qrs-generic-badge {
          &.session {
            border-right: none;
            background: #f2f9ff;
            width: 62px;

            .icon {
              border-radius: 100%;
              background: white;
            }

            .value {
              flex: 1;
              text-align: center;
            }
          }
        }

        .mentorship-banner {
          margin: 66px 15px 0px 15px;

          .extra-title {
            display: none;
          }

          &.small {
            height: 68px;
            margin: 15px 15px 15px 10px;

            .left-box {
              flex-direction: row;
              flex-flow: row-reverse;
              margin: 13px 8px;
              img {
                width: 46px;
                height: 42px;
              }

              .title {
                @apply flex flex-col font-normal;
                font-size: 13px;
                margin-left: 7px;
                padding-bottom: 5px;

                .extra-title {
                  @apply flex font-semibold;
                  font-size: 15px;
                }
              }
            }
          }
        }

        .small-session-box {
          .mentorship-session-card {
            height: 109px;
            margin-bottom: 10px;

            .details {
              height: 20px;
              overflow: hidden;
              margin-top: -10px;
            }
          }
        }
      }

      &.rewards-card {
        margin-top: 15px;
        height: 226px;

        .reward-container {
          @apply flex overflow-auto;
          margin-top: 10px;
          padding-left: 10px;
          padding-top: 10px;
          height: 160px;
          flex: none;
          overflow-y: hidden;

          .reward-box {
            @apply cursor-pointer shadow-lg rounded-lg;
            width: 220px;
            height: 132px;
            margin-right: 17px;
            padding: 14px 10px;
            flex: none;

            .xp {
              flex-direction: row-reverse;
              padding: 2px 6px 2px 0px;

              .icon {
                margin-left: 0;
                margin-right: 7px;
              }
            }

            .xp-label {
              background: #f2f9ff;
              border: 1px solid #cce9ff;
              border-radius: 18px 0px 0px 18px;
              font-size: 13px;
              font-weight: normal;
              color: #3aa4f3;
              padding-right: 10px;
              margin-right: -10px;
              border-right: none;
            }

            .action-box {
              background: #f2f9ff;
              border-radius: 10px;
              overflow: hidden;
              margin-top: 14px;
              height: 63px;
              @apply flex;

              & > .left-box {
                font-size: 15px;
                @apply flex-1 flex text-qureosBlue items-center;
                padding-left: 9px;
              }

              & > .right-box {
                font-size: 15px;
                width: 25px;
                height: 100%;
                background: #c5e4fd;
                @apply items-center flex text-center justify-center;
                cursor: pointer;
              }
            }
          }
        }

        .skill-box {
          margin-top: 25px;
          height: 132px;
          width: 100%;
          border-radius: 10px;
          display: flex;
          overflow: hidden;

          & > .left-box {
            background: #f2f9ff;
            height: 100%;
            flex: 1;

            img {
              cursor: pointer;
            }

            .text {
              margin-top: 13px;
              @apply text-qureosBlue self-center cursor-pointer;
            }
          }
          & > .right-box {
            flex-basis: 47px;
            background: #c5e4fd;
            @apply flex justify-center;
          }
        }
      }

      & > .title,
      .dc-title {
        @apply text-blackText font-semibold uppercase;
        font-size: 18px;

        .icon {
          width: 15px;
          height: 15px;
          margin-left: 10px;
          align-self: center;
        }
      }

      .checklist {
        margin: 10px 0px 0px 0px;
        padding-left: 20px;

        .item {
          cursor: pointer;
          font-size: 15px;
          color: #8898aa;
          @apply flex;
          margin: 0px 0px 13px 0px;

          &.active {
            .text {
              @apply font-semibold text-qureosBlue;
            }

            .check-item {
              border: none;
            }
          }

          .check-item {
            width: 20px;
            height: 20px;
            border: solid 1px #8898aa;
            border-radius: 20px;
            margin-right: 15px;
          }
        }
      }

      .project-slider {
        margin: 0 8px 0px 0px;
        width: 100%;
        min-height: 100px;
        @apply flex;

        .slider-tools {
          position: absolute;
          right: 20px;
          top: 15px;
        }

        .slider-content {
          width: 100% !important;
          padding: 0px 10px 10px 10px;

          .dashboard-job-card {
            height: 237px;
            margin-top: 14px;
          }

          .v2-skill-card {
            &:first-child {
              // margin-left: 10px;
            }

            &:last-child {
              padding-right: 10px;
            }
          }
        }

        .project-row {
          margin-right: 15px;
          margin-right: 15px;
          margin-top: 15;
          padding: 0px;
          min-height: 210px;
        }
      }
    }

    & > .left-box {
      height: auto;
      width: 285px;
      @apply flex flex-col;
      margin-right: 15px;

      @media (max-width: $tablet) {
        width: 100%;
        height: auto;
        margin-bottom: 10px;
      }
    }

    & > .right-box {
      @apply flex flex-col;
      height: auto;
      width: 765px;

      @media (max-width: $tablet) {
        width: 100%;
      }
    }
  }
}
.project-box {
  // @media (max-width: $desktop-4),
  //   (max-width: $desktop-5),
  //   (max-width: $desktop-6),
  //   (max-width: $desktop-7),
  //   (max-width: $desktop-8) {
  //   width: calc(100vw - 140px) !important;
  // }
  // @media (max-width: $tablet) {
  //   width: 100% !important;
  // }
  // @media (max-width: $mobile) {
  //   width: 100% !important;
  // }
}
.scrollBar__width {
  max-width: 100%;
  @media (min-width: $long-screen) {
    max-width: calc(100vw - 300px) !important;
  }
}

.add-skills-modal {
  max-width: 800px;

  .ant-modal-body {
    .modal-sub-section {
      padding: 10px 0;

      .form-tags-container {
        @apply flex flex-wrap;
        padding: 10px 0;

        .form-tags {
          @apply rounded-full text-black;
          background: #e3e8f0;
          padding: 5px 10px;
          margin-right: 5px;
          margin-bottom: 5px;

          .form-tag-name {
            margin-right: 10px;
          }
        }
      }

      .form-input {
        .skill-autocomplete {
          left: 23px !important;
          width: calc(100% - 46px);
        }

        .form-search-icon {
          @apply absolute;
          background: transparent;
          right: 30px;
        }
      }
    }
  }
}

.dashboard-banner-container {
  @apply flex w-full relative mx-auto;
  min-height: 160px;
  height: auto;
  padding-bottom: 10px;
  border-radius: 10px;

  &::before {
    @apply absolute;
    box-shadow: 0px 0px 20px #3e44571a;
    border-radius: 10px;
    content: ' ';
    top: 0px;
    left: 0px;
    width: 100%;
    height: 130px;
    background: #0061f9;
    z-index: 1;

    @media (max-width: $mobile) {
      height: 100%;
    }
  }

  @media (max-width: $mobile) {
    max-width: calc(100% - 20px);
  }

  .banner-content {
    @apply flex  flex-col relative w-full h-full;
    z-index: 10;
    padding: 15px 15px 0px 30px;

    .banner-body {
      @apply flex w-full h-full;

      .banner-image {
        @apply mt-auto text-white;
        margin-right: 20px;
        width: 205px;
        margin-top: -10px;

        @media (max-width: $small-mobile) {
          display: none;
        }
      }

      .banner-msg {
        @apply flex flex-col text-white;

        .intro {
          @apply flex;
          margin-bottom: 5px;
          font-size: 18px;
        }

        .caption {
          @apply flex font-semibold;
          font-size: 22px;
          margin-bottom: 3px;
        }

        .sub-caption {
          @apply flex text-lg;
          margin-bottom: 3px;
        }
      }

      .banner-decoration {
        @apply flex ml-auto;
        margin-right: 33px;
        top: 20px;
        height: 90px;
        position: absolute;
        right: 0;
        bottom: 0;
      }
    }
  }
}

#main {
  &.wrap-dashboard {
    background: white;
    min-height: 100vh;
    height: auto;
  }
}

.webinar-item {
  @apply relative shadow-lg rounded-lg mx-2;
  width: 242px;
  padding: 10px;
  overflow: hidden;

  &:before {
    @apply bg-qureosBlue absolute w-full;
    height: 10px;
    content: ' ';
    top: 0;
    left: 0;
  }

  .title {
    @apply w-full text-center;
    font-size: 16px;
    font-weight: bold;
  }

  .description {
    @apply mb-2;
  }

  .mentor {
    font-size: 16px;
    @apply text-qureosBlue w-full text-center py-2;
  }

  .learn-more {
    @apply bg-qureosBlue rounded-full px-3 py-1 text-white;
  }

  .date {
    font-size: 16px;
    @apply w-full flex items-center justify-center mb-3;

    img {
      @apply mr-1;
    }
  }
}

.ant-dropdown-menu-submenu-title {
  display: flex;
  align-items: center;
}
.ant-dropdown-menu-submenu-popup ul {
  border-radius: 0.5rem;
}
