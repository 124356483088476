@import './variables';

.cohort-card-container {
  @apply flex flex-wrap w-full;
}

.cohort-card {
  @apply flex w-full cursor-pointer;
  border-radius: 10px;
  border: solid 1px $qrs-gray;
  height: auto;
  min-height: 167px;
  max-width: 350px;
  overflow: hidden;
  flex: none;
  margin: 0px 15px 15px 0px;

  .handle {
    @apply flex;
    background: $qrs-blue;
    width: 10px;
    height: initial;
  }

  .cohort-card-content {
    @apply w-full flex flex-col;

    .ccc-body {
      @apply flex flex-col;
      padding: 15px;
      min-height: 123px;
      height: auto;

      .title {
        @apply font-semibold;
        color: $qrs-black;
        font-size: 15px;
        margin-bottom: 4px;
      }

      .sub-title {
        @apply flex;
        font-size: 15px;
        color: $qrs-black;
      }

      .footer-box {
        @apply flex flex-wrap mt-auto;

        .contents {
          @apply flex w-full;

          .progress-container {
            @apply flex items-center;
            flex: none;

            .item-title {
              font-size: 15px;
              color: $qrs-black;
              margin-right: 10px;
            }

            .progress-box {
              width: 100px;
              height: 12px;
              border-radius: 8px;
              overflow: hidden;
              border: solid 1px $qrs-black-2;

              .progress-content {
                @apply flex;
                height: 100%;
                width: 15%;
                background: $qrs-black-2;
              }
            }
          }

          .item-content {
            @apply flex;

            .text {
              @apply font-light;
              color: #6a7a8b;
              font-size: 15px;
              margin-right: 10px;
            }

            .value {
              @apply font-semibold;
              font-size: 15px;
              color: #100e0e;
            }
          }
        }
      }
    }

    .ccc-footer {
      @apply flex w-full items-center;
      height: 45px;
      border-top: solid 0.5px #d6dee8;
      padding: 15px;

      .footer-tags {
        @apply flex;
        border-radius: 18px;
        height: 24px;
        width: fit-content;
        min-width: 75px;

        &.has-border {
          border: solid 1px $qrs-blue;
        }

        &.success {
          background: #50d4981a;
          border-color: #50d498;

          .tag-content {
            color: #50d498;
          }
        }
        &.primary {
          background: #f2f9ff;
          border-color: #cce9ff;

          .tag-content {
            color: #3aa4f3;
          }
        }

        .icon {
          margin-left: -1px;
        }

        .tag-content {
          @apply flex items-center justify-center w-full;
          padding: 6px;
          font-size: 14px;

          .red-dot {
            @apply flex;
            width: 12px;
            height: 12px;
            border-radius: 12px;
            background: #fb6767;
            margin-right: 7px;
          }

          .status {
            @apply font-semibold;
            font-size: 15px;
          }
        }
      }
    }
  }
}
