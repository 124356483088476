$qrs-blue: var(--theme-primary);
$qrs-light-blue: var(--theme-primary-light);
$qrs-black: #1a1e2c;
$qrs-black-2: #1c1f2a;
$qrs-black-3: #1b1f2b;
$qrs-gray: #d7dee7;
$qrs-dirty-white: #f8f8f8;
$qrs-white-blue: #f7fbff;
$qrs-purple: var(--theme-primary);
$qrs-label-light: #6f7a88;
$qrs-purple-light: var(--theme-primary-light);
$qrs-border-light: #d7dee7;

$almostMobile: 512px;

$tablet: 1024px;
$mobile: 812px;
$small-mobile: 420px;
$xs-mobile: 360px;
$landscape-min: 320px;
$landscape-max: 411px;
$long-screen: 768px;

$screen-h1: 768px;
$screen-h2: 824px;
$screen-h3: 974px;

$screen-w3: 1430px;

$desktop-1: 1024px;
$desktop-2: 1280px;
$desktop-3: 1360px;
$desktop-4: 1366px;
$desktop-5: 1440px;
$desktop-6: 1600px;
$desktop-7: 1680px;
$desktop-8: 1920px;

$delay: 0.3s;

$qrs-col-1: 122px;
$qrs-col-2: 256px;
$qrs-col-3: 390px;
$qrs-col-4: 524px;
$qrs-col-5: 658px;
$qrs-col-6: 792px;
$qrs-col-7: 926px;
$qrs-col-8: 1060px;
$qrs-col-9: 1194px;
$qrs-col-10: 1328px;
$qrs-col-11: 1462px;
$qrs-col-12: 1600px;
