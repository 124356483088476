.ant-steps-item-tail::after {
  background-color: #d7dee7 !important;
}

.select-calender {
  .fc-day-today {
    background-color: inherit !important;
  }
  .fc-highlight {
    background: #6d28d9 !important;
    border-radius: 1.5rem;
    color: white;
  }
  .fc-daygrid-day-events {
    min-height: 0 !important;
  }
  .fc-daygrid-day-top {
    justify-content: center;
    padding-top: 5px;
    a:hover {
      color: #6d28d9 !important;
    }
  }
  .fc-toolbar {
    justify-content: space-evenly !important;
    & h2 {
      font-size: 1.125rem;
    }
  }
  .fc-button {
    background-color: white !important;
    color: #000 !important;
    padding: 2px !important;
    border-width: 2px !important;

    border-radius: 100% !important;
  }
  .fc-theme-standard {
    .fc-scrollgrid {
      border-width: 0px !important;
    }
    & td,
    th {
      border: 0;
      border-bottom: 0;
    }
    & thead tr {
      border-bottom: 0;
    }
    & tr {
      border-top: 0;
      overflow: hidden;
    }
    td {
      overflow: hidden;
    }
  }
}

.availability-calender {
  .fc-highlight {
    background: #8b31ff !important;
    border-radius: 4px !important;
    color: black;
  }
  .fc-daygrid-day-events {
    min-height: 0 !important;
  }
  .fc-daygrid-day-top {
    align-items: center;
    justify-content: center;
    padding-top: 6px;
    a:hover {
      color: #000 !important;
    }
  }
  .fc-day-disabled {
    background: #fff !important;
  }
  .fc-toolbar {
    justify-content: space-between !important;
    & h2 {
      font-size: 1.125rem;
    }
  }
  .fc-event {
    margin-bottom: 1px;
  }
  .fc-button {
    background-color: #fff !important;
    color: #000 !important;
    border-color: #fff !important;
    &:disabled {
      color: #fff !important;
    }
  }
  .fc-daygrid-day-number {
    font-size: 16px;
    font-weight: 600;
  }
  .fc-theme-standard {
    .fc-scrollgrid {
      border-width: 0px !important;
    }
    & td,
    th {
      border: 0;
      border-bottom: 0;
    }
    & thead tr {
      border-bottom: 0;
    }
    & tr {
      border-top: 0;
      overflow: hidden;
    }
    td {
      overflow: hidden;
    }
  }
}
.event-calendar {
  .fc-scrollgrid-sync-inner {
    a:hover {
      color: var(--theme-primary);
    }
  }
  .fc-day-today {
    background-color: inherit !important;
  }
  .fc-highlight {
    background: #f9f5ff !important;
    color: white;
  }
  & .fc-header-toolbar {
    margin-bottom: 0px !important;
  }
  table thead tr {
    border-bottom-width: 0px !important;
  }
  .fc-theme-standard {
    & td,
    th {
      border-bottom-width: 0px !important;
    }
  }
}
