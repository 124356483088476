.char-counter {
  &.subtext {
    &.task {
      position: relative;
      top: -10px;
      font-size: 11px;
      left: calc(100% - 130px);
    }
  }
}
