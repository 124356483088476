@import './sass/variables.scss';
@import './sass/thread.scss';
@import './sass/new-workspace.scss';
@import './sass/antd-modal.scss';
@import './sass/antd-input.scss';
@import './sass/antd-switch.scss';
@import './sass/antd-select.scss';
@import './sass/antd-slider.scss';
@import './sass/antd-radio.scss';
@import './sass/drawer.scss';
@import './sass/char-counter.scss';
@import './sass/wizard.scss';
@import './sass/slider.scss';
@import './sass/badge.scss';
@import './sass/button.scss';
@import './sass/cohort.scss';
@import './sass/chat.scss';
@import './sass/antd-image.scss';
@import './sass/input.scss';
@import './sass/antd-alert.scss';
@import './sass/antd-card.scss';
@import './sass/ck-editor.scss';
@import './sass/antd-picker.scss';
@import './sass/antd-steps.scss';
@import './sass/antd-checkbox.scss';
@import './sass/tabs.scss';
@import './sass/antd-tabs.scss';
@import './sass/job.scss';
@import './sass/antd-time-range-picker.scss';
@import './sass/antd-pagination.scss';
@import './sass/antd-menu.scss';

// CORPORATE
@import './sass/corporate/projects.scss';
@import './sass/corporate/intl-tel.scss';

/** GENERIC DRAWER TEMPLATE */

.drawer-template {
  .sidebar {
    z-index: 999;
  }

  @include workspace-drawer;

  .content-wrapper {
    .content {
      background: #fff;
      position: relative;

      @include workspace-drawer;
    }
  }

  &.open-drawer {
    @include workspace-drawer-open;

    .content-wrapper {
      .content {
        @include workspace-drawer-open;

        .drawer-template-container {
          width: calc(100% - 524px);
          min-width: $qrs-col-4;

          @media (max-width: $mobile) {
            width: 100%;
          }
        }
      }
    }
  }
}

input,
textarea {
  @apply w-full rounded-lg px-3 py-2;
  border: 1px solid #c5e4fd;

  &:focus {
    outline-width: 0;
  }
}

.action-btn {
  @apply bg-qureosPrimary flex cursor-pointer;
  border-radius: 23px;
  color: white;
  font-size: 15px;
  padding: 5px 15px;
  justify-content: center;
  width: fit-content;
}

/**** END DRAWER TEMPLATE */

.generic-selection-box {
  @apply flex w-full flex-wrap;

  .selection-box {
    @apply flex flex-col w-full relative cursor-pointer;
    border-radius: 10px;
    max-width: $qrs-col-2;
    overflow: hidden;
    height: 65px;
    border: solid 1px #4daabc;
    margin-right: 12px;
    margin-bottom: 12px;
    flex: none;

    &:hover,
    &.active {
      .box-bg {
        max-width: 100%;
        width: 999px;
        transition: 0.3s all ease-in;
      }

      .box-content {
        .detail-box {
          .text {
            color: white;
          }
        }
      }
    }

    .box-bg {
      position: absolute;
      top: 0px;
      left: 0px;
      height: 100%;
      width: 36px;
      z-index: 1;
      background: #4daabc;
    }

    .box-content {
      @apply flex w-full h-full relative;
      z-index: 2;

      .icon-box {
        @apply flex justify-center items-center;
        width: 42px;
        height: 100%;
      }

      .detail-box {
        @apply flex flex-col items-start justify-center w-full h-full;
        padding-left: 16px;

        &.soon {
          align-self: center;
        }

        &.thick {
          @apply font-semibold;
        }

        .text {
          @apply text-blackText;
          font-size: 18px;
        }

        .sub-text {
          font-size: 15px;
          color: #000;
        }
      }
    }
  }
}

.module-steps {
  @apply flex justify-center items-center relative;
  border-radius: 10px;
  border: solid 1px #cce9ff;
  width: fit-content;
  min-width: 120px;
  height: 45px;
  margin-right: 22px;
  padding: 0px 10px;
  flex: none;

  @media (max-width: $small-mobile) {
    margin-bottom: 12px;
  }

  .step-inner {
    @apply flex;

    .icon {
      margin-right: 10px;
    }

    .title {
      @apply font-semibold;
      font-size: 14px;
      color: $qrs-black-2;
      margin-right: 5px;
    }

    .step-arrow {
      @apply flex justify-center items-center ml-auto absolute;
      border-radius: 22px;
      width: 22px;
      height: 22px;
      background: $qrs-black-2;
      right: -12px;
    }
  }
}

.module-box {
  @apply flex w-full;
  border-radius: 10px;
  border: solid 1px $qrs-blue;
  overflow: hidden;
  min-height: 235px;
  height: auto;
  margin-bottom: 15px;

  .module-title {
    @apply flex justify-center items-center;
    width: 36px;
    height: auto;
    background: $qrs-blue;
    flex: none;

    .title-item {
      @apply flex font-semibold;
      color: white;
      font-size: 15px;
      text-transform: uppercase;
      writing-mode: tb-rl;
      transform: rotate(-180deg);
    }
  }

  .module-details {
    @apply flex flex-col w-full;

    .details-header {
      @apply flex w-full;
      background: #f2f4f7;
      height: fit-content;

      @media (max-width: $desktop-2) {
        @apply flex-wrap;

        .details-item {
          width: 100% !important;
          max-width: 100% !important;
          flex: none;
          border-bottom: solid 1px $qrs-gray;

          &.with-line {
            border-right: none !important;

            .arrow-flow {
              right: auto !important;
              left: 50% !important;
              transform: translateX(-50%) rotate(90deg) !important;
              bottom: -10px !important;
              top: auto !important;
            }
          }
        }
      }

      .details-item {
        @apply flex flex-col relative;
        width: fit-content;
        padding: 15px 20px;
        flex: 1 1 auto;
        height: fit-content;
        min-height: 145px;

        &.with-line {
          border-right: solid 0.5px #808d9d;
          max-width: 550px;
          flex: none;
          width: 100%;

          .arrow-flow {
            @apply absolute flex justify-center items-center;
            width: 22px;
            height: 22px;
            border-radius: 22px;
            background: $qrs-black-2;
            right: -12px;
            top: 50%;
            transform: translateY(-50%);
          }
        }

        .details-title {
          @apply flex font-semibold text-blackText;
          margin-bottom: 5px;
          font-size: 15px;
        }

        .details-desc {
          @apply flex;
          font-size: 15px;
          color: #505f70;
        }

        .details-bottom {
          @apply flex mt-auto;

          .sub-details {
            color: #505f70;
            font-size: 15px;
          }
          .right-details {
            @apply flex ml-auto;
            color: #505f70;
            font-size: 15px;
          }
        }
      }
    }

    .details-footer {
      @apply flex flex-col;
      padding: 15px;

      .footer-title {
        @apply flex font-semibold text-blackText;
        margin-bottom: 13px;
        font-size: 15px;
      }

      .footer-tag-container {
        @apply flex flex-wrap;

        .footer-tags {
          @apply flex justify-center items-center;
          padding: 5px 12px;
          width: fit-content;
          height: 30px;
          border-radius: 25px;
          border: solid 1px $qrs-black-2;
          color: $qrs-black-2;
          overflow: hidden;
          margin-right: 5px;
          margin-bottom: 10px;
        }
      }
    }
  }
}

.cohort-bar {
  @apply flex w-full relative;
  height: 70px;
  background: #4daabc;
  border-radius: 10px;
  overflow: hidden;

  @media (max-width: $desktop-1) {
    height: auto;
  }
  @media (max-width: $small-mobile) {
    height: auto;
  }

  .pocket {
    @apply flex  justify-center items-center;
    width: 35px;
    height: initial;
    border-right: solid 1px #fff;
    flex: none;

    .pocket-icon {
      @apply cursor-pointer;
    }
  }

  .details-box {
    @apply flex justify-between w-full  items-center cursor-pointer flex-wrap;

    @media (max-width: $desktop-1) {
      min-height: 80px;
      height: auto;
    }

    @media (max-width: $small-mobile) {
      padding: 15px;
      flex-direction: column;
      align-items: flex-start;
    }

    &.incomplete {
      background: $qrs-gray;

      & > .title {
        color: $qrs-black-2;
      }

      .text-box {
        .text {
          color: $qrs-black-2;
        }
      }
    }

    & > .title {
      @apply flex font-semibold items-center;
      padding-left: 18px;
      color: white;
      font-size: 18px;
      min-width: 250px;

      @media (max-width: $small-mobile) {
        height: 30px;
        padding-left: 0px;
        margin-bottom: 10px;
      }
    }

    .right-bar {
      @apply flex ml-auto;

      @media (max-width: $desktop-1) {
        margin-left: 15px;
        margin-bottom: 5px;
        width: 100%;
        flex: none;
        justify-content: space-between;
      }

      @media (max-width: $small-mobile) {
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        margin-left: 0px;
      }
    }

    .text-box {
      @apply flex items-center;

      @media (max-width: $small-mobile) {
        @apply w-full;

        .text {
          &.text-details {
            @apply ml-auto;
          }
        }
      }

      .text {
        @apply flex justify-center items-center;
        margin-right: 30px;
        color: white;
        font-size: 18px;
      }

      .rating-box {
        @apply flex justify-center items-center;
        height: 32px;
        width: fit-content;
        padding: 7px 15px;
        margin-right: 25px;
        border-radius: 16px;
        background: white;

        .icon {
          margin-right: 9px;
        }

        .value {
          font-size: 18px;
          color: $qrs-black-2;

          &.danger {
            color: #e2445c;
          }
        }
      }
    }

    .status-box {
      @apply flex items-center;

      @media (max-width: $small-mobile) {
        flex: none;
        width: fit-content;
        padding: 0px;
        margin-bottom: 10px;
      }

      .text {
        @apply flex font-semibold text-white;
        font-size: 18px;
        margin-right: 8px;
      }
    }

    .progress-box {
      @apply flex items-center;
      flex: none;
      width: fit-content;
      margin-right: 40px;

      @media (max-width: $small-mobile) {
        margin-bottom: 5px;

        .title {
          display: none;
        }
      }

      .title {
        color: white;
        font-size: 18px;
        margin-right: 13px;
      }

      .progress-bar {
        @apply flex;
        width: 172px;
        height: 16px;
        border-radius: 8px;
        border: solid 1px #fff;
        overflow: hidden;

        .progress-amount {
          transition: 0.3s all ease-in;
          background: white;
          width: 15%;
          height: 100%;
        }
      }
    }

    .time-box {
      @apply flex items-center;
      margin-right: 30px;

      .title {
        font-size: 18px;
        color: white;
        margin-right: 10px;

        @media (max-width: $small-mobile) {
          display: none;
        }
      }

      .value {
        @apply font-semibold text-white;
        font-size: 18px;

        @media (max-width: $small-mobile) {
          font-weight: normal;
          font-size: 15px;
        }
      }
    }

    .call-box {
      @apply flex;
      margin-right: 20px;

      @media (max-width: $small-mobile) {
        margin-bottom: 15px;
      }

      .call-btn {
        @apply flex justify-center items-center cursor-pointer;
        border-radius: 17px;
        border: solid 1px #fff;
        color: white;
        font-size: 15px;
        height: 30px;
        width: fit-content;
        padding: 4px 15px;

        &:hover {
          background: $qrs-blue;
        }
      }
    }

    .arrow-box {
      @apply flex justify-center items-center;
      margin: 0px 30px;

      @media (max-width: $small-mobile) {
        position: absolute;
        right: -5px;
        top: 50%;
        transform: translateY(-50%);

        img {
          width: 15px !important;
        }
      }

      img {
        width: 10px;
      }
    }
  }
}

.cohort-menu-dropdown {
  border-radius: 2px;
  overflow: hidden;
  box-shadow: 0px 0px 20px rgba(106, 112, 128, 0.2);
}

.report-box {
  textarea {
    resize: none;
  }

  .report-button {
    &.warning {
      background: #ffb100;
    }

    &.danger {
      background: #ffb100;
    }
  }
}

.meet-container {
  iframe {
    position: fixed;
    width: 100% !important;
    height: 100% !important;
    z-index: 9999;
    left: 0;
    top: 0;
  }

  .meet-brands {
    position: fixed;
    z-index: 99999;

    .logo {
      position: fixed;
      z-index: 99999;
      top: 35px;
      left: 10px;
    }
  }
}

.fileviewer-container {
  @apply flex flex-col justify-center rounded-lg p-4 w-full h-full overflow-auto relative;
  padding: 70px;
  padding-right: 80px;
  max-width: 900px;

  .actions {
    @apply absolute;
    z-index: 1;
    right: 20px;
    height: 100%;
    padding: 75px 0;
  }
}

.qrs-file-viewer {
  @apply rounded-lg overflow-hidden;
  overflow: auto;

  .pg-viewer {
    display: flex;
    justify-content: center;
  }

  .photo-viewer-container {
    // height: 300px !important;
    height: auto !important;
    width: calc(100vw - 30%) !important;

    img {
      height: 100% !important;
      width: 100% !important;
      object-fit: scale-down;
    }
  }
}

.modularization-antd-table {
  .ant-table-container {
    table {
      th {
        background: transparent;
        color: #6b7280;
        padding: 14px 10px;
        font-weight: 600;

        &::before {
          content: none !important;
        }
      }
      td {
        color: #6b7280;
      }
    }
  }
}

.qureos-select {
  height: 35px;
  .ant-select-selector {
    height: 35px !important;
  }
}
