@import './variables';

body {
  &.snapjs-left {
    background: #234362;

    #main {
      @apply overflow-hidden h-screen;
    }

    .snap-drawers {
      z-index: 30;

      .snap-overlay {
        z-index: 1;
        opacity: 1;
      }
    }

    .snap-drawer-left {
      .qrs-sidebar {
        left: 0;
        z-index: 2;
      }
    }
  }
}

.snap-drawers {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: auto;
  height: auto;
  z-index: -1;

  -webkit-transition: all $delay ease-out;
  transition: all $delay ease-out;

  .snap-overlay {
    width: 100%;
    left: 0;
    height: 100%;
    top: 0;
    position: fixed;
    background: rgba(0, 0, 0, 0.5);
    opacity: 0;
    z-index: -1;
  }
  // display: none;

  @media (max-width: $mobile) {
    display: block;
  }

  .snap-drawer-left {
    left: 0;
    z-index: 1;

    .qrs-sidebar {
      -webkit-transition: all $delay ease-out;
      transition: all $delay ease-out;

      width: 120px;
      left: -300px;

      .qrs-navig {
        @apply flex justify-center;
      }

      .mobile-sidebar-control,
      .sidebar-control {
        display: none;
      }
    }
  }
}

.sidebar-toggle {
  @apply relative z-10;
  display: none;

  @media (max-width: $mobile) {
    display: flex;
  }
}

@media (max-width: $mobile) {
  #main {
    .qrs-sidebar {
      display: none !important;
    }

    .content {
      .header-navigation {
        &.pl-32 {
          padding-left: 15px;
        }
      }
    }
  }
}

.qrs-sidebar {
  background: #3461ff !important;
  -webkit-transition: width $delay ease-out;
  transition: width $delay ease-out;

  .user-points-box {
    .quick-info {
      min-height: 25px;
      flex-direction: column;
      display: flex;
      padding: 0px;
    }
  }

  .qrs-navig {
    // @media (max-width: $mobile) {
    //   display: none;
    // }

    @apply justify-center w-full;
    padding: 20px 0px;

    .qrs-logo {
      width: 30px;
    }
  }

  .qrs-quick-info-box {
    &.session-box {
      margin-left: 0;
    }

    .qrs-generic-badge {
      flex: 1;
      flex-direction: row-reverse;
      padding: 2px 6px 2px 0px;
      margin: 2px;

      &.xp {
        background: #343a30;
        border-color: #feb200;

        .icon {
          margin-left: -1px;
          margin-right: auto;
        }

        .value {
          margin-left: 5px;
          font-size: 11px;
        }
      }

      &.session {
        .icon {
          margin-left: -1px;
          margin-right: auto;
        }

        .value {
          margin-left: 5px;
          font-size: 11px;
        }
      }
    }

    .ib-item {
      margin-bottom: 10px;

      &:nth-child(1) {
        margin-left: 0px;
      }
    }
  }

  .profile-photo {
    object-fit: cover;
  }

  .qrs-menu {
    // padding: 10px;

    .item {
      text-align: left;
      padding-left: 0px;

      img {
        @extend .qrs-transition;
        width: 25px;
      }
    }
  }

  &.open {
    width: 6rem;

    .qrs-quick-info-box {
      .xp,
      .session {
        .value {
          font-size: 14px;
        }
      }

      &.session-box {
        margin-left: auto;
      }
    }

    .qrs-generic-badge {
      margin: 0px;
      flex: 0 auto;
    }

    .quick-info {
      padding: 0px 0px;
      display: flex;
      flex-direction: row;
    }

    .qrs-navig {
      .qrs-logo {
        height: 50px;
        margin-bottom: -10px;
        margin-top: -7px;
        width: 130px;
        object-fit: scale-down;
      }
    }

    .qrs-quick-info-box {
      .ib-item {
        width: 100%;
        margin-bottom: 0px;

        &:nth-child(1) {
          margin-left: -12px;
        }
      }
    }

    .qrs-menu {
      .item {
        @apply flex justify-center;

        img {
          width: 35px;
          vertical-align: middle;
          display: inline-block;
        }
      }
    }
  }
}

.qrs-header-tabs {
  width: 100%;
  display: flex;

  @media (max-width: $mobile) {
    position: absolute;
    top: 0px;
    left: 65px;
    height: 57px;
    width: 375px;
    z-index: 1;
    padding: 5px 5px 0px 0px;
    display: block;

    .selection-tab-box {
      .tab-item {
        border-bottom: none;

        &.active {
          background: transparent;
        }

        a {
          @apply flex;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }
    }

    @media (max-width: $small-mobile) {
      width: 170px;
    }

    .tab-item {
      width: 100%;
      padding: 10px;
      background: #fff;
      border-bottom: solid 1px #eee;
      height: 52px;

      .header-tab-underline {
        display: none;
      }
    }
  }

  .divider {
    display: none;
  }

  .general-menu {
    display: none;
  }
}

.qrs-menu-list {
  display: flex;
  flex-direction: column;

  @media (max-width: $mobile) {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0px;
    left: 0px;
    z-index: 999;
    background: white;
    padding-top: 100px;

    a {
      display: block;
      width: 100%;
    }
  }
}

.dropdown-menu {
  &.antd {
    @apply rounded-lg shadow-lg bg-white relative w-auto;
    min-width: 150px;
    max-width: 250px;
    top: 10px;

    .inner {
      @apply p-3 flex flex-col;

      a {
        @apply w-full py-1;
      }
    }
  }
}

.siderbar-icon-admin {
  zoom: 2.5;
}

.sidebar-tooltip {
  &.ant-tooltip {
    z-index: 999 !important;
    padding-left: 25px;
  }

  .ant-tooltip-content {
    background: #fff !important;
    border: solid 1px #234362;
    border-radius: 10px;
    overflow: hidden;

    .ant-tooltip-arrow {
      width: 15px;
      height: 15px;
      left: 12px;

      .ant-tooltip-arrow-content {
        width: 15px;
        height: 15px;
        left: 2px;
        border: solid 1px #234362;
      }
    }

    .ant-tooltip-inner {
      height: 50px;
      display: flex;
      justify-content: center;
      align-items: center;
      color: #234362;
      font-size: 15px;
      padding: 0px 15px;
    }
  }

  &.active {
    .ant-tooltip-content {
      border: solid 1px #3aa4f3;

      .ant-tooltip-arrow {
        .ant-tooltip-arrow-content {
          border: solid 1px #3aa4f3;
        }
      }

      .ant-tooltip-inner {
        color: #3aa4f3;
      }
    }
  }
}
