#main {
  .header-navigation {
    @apply bg-white fixed flex flex-row border-b border-gray-300 w-full py-3 px-4 shadow-lg;
    padding-left: 90px;
    height: 62px;
    z-index: 2;

    &.nav-open {
      padding-left: 150px;
    }

    @media (max-width: $mobile) {
      padding-left: 20px;
    }
  }
}
