@import './variables';

.template-title {
  @apply cursor-pointer text-xl;
}

#main {
  background: #edf2f7;
}

@media (max-width: $mobile) {
  #main {
    z-index: 1;
    box-shadow: 4px 1px 20px 7px #0a2235;
    min-height: 100vh;
  }

  .template-title {
    @apply text-base;
  }
}
