.learn-from-qureos {
  .learn-section {
    .section-image {
      img {
        width: 68px;
      }
    }
  }
}

.who-should-enrol {
  .enrol-section {
    .section-image {
      height: 100%;
      align-items: center;
      display: flex;

      img {
        width: 65px;
      }
    }
  }
}

.certificate-content {
  .certificate-section {
    .section-image {
      display: flex;
      align-items: center;
      justify-content: center;

      img {
        width: 65px;
      }
    }
  }
}

.start-journey {
  padding: 48px 60px;

  .apply-scholarship {
    border-bottom: 2px solid #fff;
  }

  h2 {
    color: #fff;
  }
}
