.ant-input-affix-wrapper {
  &.text-sm .ant-input {
    font-size: 0.875rem !important;
    line-height: 1.25rem !important;
  }
}

.ant-input-affix-wrapper-focused {
  box-shadow: 0 0 0 1px #1dbe65 !important;
}

.disabled-font {
  .ant-input:disabled {
    color: #444 !important;
  }
}
