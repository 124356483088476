.antd-checkbox {
  .ant-checkbox {
    height: 16px;
  }
}

.case-study-section {
  .ant-checkbox-inner {
    margin: 0;
  }
  .ant-checkbox-checked {
    .ant-checkbox-inner {
      background-color: var(--theme-primary);
    }
    &.ant-checkbox-disabled {
      .ant-checkbox-inner {
        background-color: unset;
      }
    }
  }
  .case-study-label {
    color: rgb(107 114 128) !important;
  }

  .ant-checkbox-wrapper-disabled {
    .case-study-label {
      color: rgba(0, 0, 0, 0.25) !important;
    }
  }
}

.corporate-checkbox {
  .ant-checkbox-checked {
    .ant-checkbox-inner {
      background-color: var(--theme-primary);
      border-color: var(--theme-primary);
    }
    &.ant-checkbox-disabled {
      .ant-checkbox-inner {
        background-color: unset;
      }
    }
  }
  .corporate-checkbox-label {
    span {
      margin-bottom: 0 !important;
    }
    .ant-checkbox {
      top: 0;
      border-color: var(--theme-primary);
      &:hover {
        border-color: var(--theme-primary);
      }
    }
    color: rgb(107 114 128) !important;
  }

  .ant-checkbox-wrapper-disabled {
    .corporate-checkbox-label {
      color: rgba(0, 0, 0, 0.25) !important;
    }
  }
}
.ai-interview-checkbox {
  .ant-checkbox {
    height: 16px !important;
  }
  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: var(--theme-primary);
    border-color: var(--theme-primary);
  }
  .ant-checkbox-disabled .ant-checkbox-inner {
    background-color: #d9d9d9;
    border-color: #d9d9d9;
  }
}
