@import './variables';

.sidebar-v2 {
  @apply h-screen relative overflow-auto;
  width: 234px;
  background: #234362;

  .sidebar-inner {
    @apply absolute h-full w-full;
  }
}

.content-wrapper-v2 {
  @apply flex flex-col w-full;

  .header-v2 {
    @apply w-full relative;
    height: 65px;

    .inner {
      @apply w-full bg-white shadow-lg fixed;
      height: 65px;
    }
  }
}
