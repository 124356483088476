@import './variables';

.proficiency-badge-container {
  @apply flex flex-col relative;
  width: 120px;
  height: 112px;
  flex: none;

  .badge-bg {
    @apply w-full absolute;
    z-index: 1;
    top: 0px;
    left: 0px;
    padding: 0px 8px;

    img {
      width: 100%;
      height: 100%;
      object-fit: scale-down;
    }
  }

  .badge-content {
    @apply flex flex-col relative items-center h-full;
    z-index: 2;
    padding: 7px 0px;

    & > .title {
      @apply flex flex-wrap justify-center;
      color: white;
      font-size: 11px;
      width: 50px;
      height: 30px;
      margin-bottom: 5px;
      line-height: 15px;
      text-align: center;
    }

    .badge-rating-box {
      @apply flex flex-col absolute w-full bg-white;
      height: 35px;
      border-radius: 8px;
      border: solid 3px $qrs-blue;
      border-left: none;
      border-right: none;
      padding: 3px 0px 5px 0px;
      top: 40px;

      .text {
        @apply flex w-full text-blackText justify-center;
        font-size: 11px;
      }

      .rating {
        @apply flex justify-center items-center relative;
        background: $qrs-black;
        width: 120px;
        width: fit-content;
        padding: 0px 18px;
        margin: 0 auto;
        border: solid 3px $qrs-blue;
        border-bottom: none;
        height: 12px;
        border-radius: 10px 10px 0px 0px;

        .rating-content {
          @apply flex items-center justify-center relative;
          top: 4px;
          width: 10px;
        }

        .icon {
          margin-right: 3.5px;
          width: 8px;
          height: 8px;
        }

        .value {
          @apply flex font-semibold;
          font-size: 10px;
          color: white;
        }
      }
    }

    .failed-rating-box {
      @apply flex flex-col items-center;
      width: calc(100% - 40px);

      .title {
        @apply flex;
        color: white;
        font-size: 12px;
      }

      .rating {
        @apply flex justify-center items-center relative w-full;
        padding: 3px 0px;
        margin: 0 auto;
        height: auto;
        border-bottom: solid 1px #fff;

        .icon {
          margin-right: 3.5px;
          width: 14px;
          height: 14px;
        }

        .value {
          @apply flex;
          font-size: 15px;
          color: white;
        }
      }

      .rating-footer {
        @apply flex flex-col;
        margin-top: 2px;

        .text {
          @apply flex text-white;
          font-size: 12px;
        }

        .rating {
          border-bottom: none;
          padding: 0px;

          .value {
            color: $qrs-red;
          }
        }
      }
    }

    .badge-footer {
      @apply flex mt-auto justify-center;
      width: 100%;
      margin-bottom: 7px;
    }
  }
}
