.transactions {
  @apply w-full px-5 py-2 mx-auto;

  table {
    @apply w-full;
    border-collapse: separate;
    border-spacing: 0 0.8em;

    tr {
      @apply mb-3 shadow-lg;

      td,
      th {
        @apply bg-white p-6 font-thin;

        &:first-child {
          @apply rounded-tl-lg rounded-bl-lg;
        }

        &:last-child {
          @apply rounded-br-lg rounded-tr-lg;
        }
      }

      th {
        @apply text-lg text-black font-semibold;
      }
    }
  }
}

.transaction-status {
  @apply flex items-center justify-center rounded-full;
  width: 107px;
  height: 40px;

  &.completed {
    background-color: #d9edfd;
    @apply text-qureosBlue;
  }

  &.failed {
    background-color: #f7e0e1;
    color: #fa6767;
  }
}

.reference-copied {
  @apply absolute text-white rounded-full;
  background: rgba(0, 0, 0, 0.46);
  top: 10px;
  right: 14px;
  padding: 5px 10px;
  font-size: 12px;
}
