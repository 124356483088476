@import './variables';

.new-profile-details {
  @apply flex flex-col w-full;
  margin: 0 auto;
  max-width: 800px;
  background: white;
  height: auto;
  min-height: 300px;
  border-radius: 10px;
  border: solid 1px $qrs-gray;

  @media (max-width: $small-mobile) {
    border-radius: 0px;
  }

  .profile-details-section {
    @apply flex flex-col;
    padding: 25px 30px;

    .detail-item {
      @apply flex w-full;
    }

    .submit-btn {
      @apply flex justify-center items-center cursor-pointer;
      min-width: 90px;
      width: fit-content;
      height: 30px;
      border-radius: 23px;
      background: $qrs-blue;
      font-size: 15px;
      color: white;
      padding: 5px 0px;
    }

    &.menu {
      padding: 20px 20px 0px 20px;
      border: none;

      .edit-btn {
        cursor: pointer;
      }

      .close-btn {
        height: 15px;
        cursor: pointer;
      }
    }

    &.divider {
      border-bottom: solid 1.5px $qrs-gray;
    }

    &.basic-info {
      padding: 20px 30px;
      border-bottom: solid 1.5px $qrs-gray;

      @media (max-width: $small-mobile) {
        border-bottom: none;
      }

      .basic-details {
        @apply flex w-full items-center;

        @media (max-width: $small-mobile) {
          flex-direction: column;
        }

        .user-dp {
          position: relative;
          align-self: self-start;

          @media (max-width: $small-mobile) {
            margin-bottom: 20px;
            align-self: center;
          }

          .photo {
            height: 80px;
            width: 80px;
            border-radius: 80px;
            object-fit: cover;
            margin-right: 20px;
            border: solid 1px #b2bbc7;
          }

          .edit-dp {
            @apply absolute flex justify-center items-center;
            right: 15px;
            bottom: 5px;
            cursor: pointer;
            width: 30px;
            height: 30px;
            border-radius: 30px;
            border: solid 1px #b2bbc7;
            background: white;
          }
        }

        .user-info {
          @apply flex flex-col;

          .name {
            @apply flex items-center;
            margin-bottom: 8px;

            @media (max-width: $small-mobile) {
              justify-content: center;
            }

            .text {
              @apply font-bold;
              font-size: 22px;
              color: $qrs-black;
              margin-right: 10px;
            }

            .icon {
              width: 18px;
              height: 18px;
            }
          }

          .footer-info {
            @apply flex flex-col;

            @media (max-width: $small-mobile) {
              align-items: center;
            }

            .main-details {
              @apply flex;
              margin-bottom: 6px;

              @media (max-width: $small-mobile) {
                justify-content: center;
                align-items: center;
              }

              .items {
                @apply flex;
                margin-right: 20px;

                img {
                  margin-right: 7px;
                }

                .value {
                  font-size: 15px;
                  color: $qrs-black;
                }
              }
            }

            .sub-details {
              @apply flex;

              .items {
                .text {
                  font-size: 15px;
                  color: $qrs-black;
                }
              }
            }
          }
        }

        .user-info-form {
          @apply flex flex-col;

          .title {
            @apply font-semibold;
            color: $qrs-black;
            margin-bottom: 20px;
          }
        }

        .share-btn {
          @apply flex justify-center items-center;
          height: 32px;
          width: 95px;
          border-radius: 23px;
          background: #d0e9fd;
          border: solid 1px #d0e9fd;
          padding: 6px 0px;
          cursor: pointer;
          margin-left: auto;

          @media (max-width: $small-mobile) {
            margin: 0px;
            margin-top: 20px;
          }

          .icon {
            margin-right: 10px;
          }

          .text {
            font-size: 15px;
            color: $qrs-blue;
          }
        }
      }
    }

    .form-container {
      @apply flex w-full flex-wrap;
      max-width: 380px;

      &:hover {
        .remove-btn {
          display: flex;
        }
      }

      .remove-btn {
        height: 25px;
        cursor: pointer;
        display: none;
        border-radius: 100%;
        border: solid 1px #d7dee7;
        padding: 5px;
        align-self: center;
      }

      &.full {
        max-width: 100%;
      }

      &.group {
        @media (max-width: $small-mobile) {
          flex-wrap: nowrap;

          .form-item {
            width: auto;
          }
        }
      }

      &.language {
        .form-item {
          margin-bottom: 20px;
          margin-right: 20px;
        }
      }

      &.certificate {
        padding: 15px 0px;
        max-width: 100%;

        &:not(:nth-child(1)) {
          border-top: solid 1px #eee;
        }
      }

      .form-item {
        @apply flex flex-col;
        flex: none;
        margin-right: 10px;
        margin-bottom: 10px;

        @media (max-width: $small-mobile) {
          width: 100%;
        }

        .ant-select {
          min-width: 130px;
        }

        .caption {
          font-size: 15px;
          color: #6d7a89;
          margin-bottom: 10px;
        }

        .text-field,
        input[type='text'] {
          &:not(.google-places-autocomplete__input) {
            width: 180px;
          }
          &.google-places-autocomplete__input {
            width: 100%;
          }
          @apply shadow-none bg-white;
          border-radius: 5px;
          border: solid 1px $qrs-gray;
          height: 40px;
          padding: 10px 12px;
          font-size: 14px;
          color: $qrs-black;

          @media (max-width: $small-mobile) {
            width: 100%;
          }
        }
      }

      .ant-picker {
        border-radius: 5px;
        border: solid 1px #d7dee7;
        width: 180px;
        height: 40px;
      }
    }

    .cert-container {
      &:hover {
        .remove-btn {
          display: flex;
        }
      }

      .remove-btn {
        height: 25px;
        cursor: pointer;
        display: none;
        border-radius: 100%;
        border: solid 1px #d7dee7;
        padding: 5px;
        align-self: center;
      }
    }

    .box-container {
      @apply flex flex-wrap;

      .item-box {
        @apply flex flex-col;
        margin-right: 15px;
        margin-bottom: 10px;
        max-width: 350px;
        width: 100%;
        flex: none;

        &.full {
          max-width: 100%;
        }

        & > .title {
          @apply flex font-bold;
          font-size: 15px;
          margin-bottom: 10px;
        }

        .body {
          @apply flex;

          &.frow {
            flex-direction: column;
          }

          .bio {
            @apply flex flex-col w-full;

            .char-counter {
              @apply ml-auto;
              font-size: 15px;
              color: #535f6e;
            }
          }

          .parag {
            font-size: 13px;
            color: #535f6e;
          }

          .tags {
            @apply flex justify-center items-center cursor-pointer;
            border-radius: 25px;
            border: solid 1px $qrs-black-2;
            padding: 5px 16px;
            margin-right: 8px;
            margin-bottom: 8px;
            font-size: 13px;
            flex: none;
          }

          textarea {
            resize: none;
            border: solid 1px #d7dee7;
            border-radius: 5px;
            font-size: 14px;
            color: #535f6e;
            padding: 15px;
          }
        }
      }

      .add-btn {
        @apply flex cursor-pointer;

        img {
          margin-right: 8px;
        }

        .text {
          font-size: 14px;
          color: #000;
          font-weight: normal;
        }
      }
    }

    .certificate-item {
      @apply flex;
      margin-right: 50px;
      margin-bottom: 20px;
      width: 195px;

      @media (max-width: $small-mobile) {
        width: 100%;
      }

      .logo {
        width: 16px;
        height: 16px;
        margin-right: 10px;
        margin-top: 5px;
      }

      .details {
        @apply flex flex-col;

        .title {
          color: $qrs-black-3;
          font-size: 15px;
          margin-bottom: 2px;
        }

        .desc {
          @apply flex items-center;

          .item {
            @apply flex font-light items-center;
            color: $qrs-black-3;
            font-size: 15px;

            &.company-name {
              max-width: 100px;
              display: block;
              overflow: hidden;
              white-space: nowrap;
              text-overflow: ellipsis;
            }
          }

          .dot {
            width: 4px;
            height: 4px;
            background: #6d7a89;
            border-radius: 100%;
            margin: 0px 7px;
          }
        }
      }
    }

    .badge-container {
      @apply flex flex-wrap;
      gap: 20px;

      .proficiency-badge-container {
        flex: none;
        margin-right: 15px;
        margin-bottom: 15px;
      }
    }

    .ant-select-selection-item-remove {
      position: relative;
      top: -2px;
    }
  }

  .completed-projects-box {
    @apply w-full flex flex-col;
    overflow: hidden;
    border-radius: 10px 10px 0px 0px;
    border: solid 1px #d0e9fd;
    margin-bottom: 15px;

    &:before {
      content: ' ';
      @apply w-full;
      height: 5px;
      background-color: $qrs-blue;
    }

    .content-box {
      @apply w-full flex flex-col;
      padding: 15px;

      .project-box-header {
        @apply flex flex-col;
        margin-bottom: 6px;

        .info {
          @apply flex;

          .title {
            @apply text-blackText;
            font-size: 15px;
            margin-right: 15px;
            align-self: center;
          }

          .rating {
            @apply flex;
            align-items: center;

            .icon {
              margin-right: 5px;
            }

            .value {
              font-size: 15px;
            }
          }

          .logo {
            @apply flex ml-auto;
            height: 50px;
            width: 100px;
            object-fit: scale-down;
          }
        }

        .provider {
          @apply flex;
          margin-bottom: 3px;

          .company-name {
            @apply font-light text-blackText;
            font-size: 15px;
          }
        }
      }
      .project-box-body {
        @apply flex;

        .content {
          @apply text-blackText;
          font-size: 13px;

          p {
            display: -webkit-box;
            -webkit-line-clamp: 3;
            -webkit-box-orient: vertical;
            overflow: hidden;
          }
        }
      }

      .project-box-footer {
        @apply flex;

        .action-text {
          @apply flex ml-auto font-semibold cursor-pointer;
          font-size: 15px;
          color: $qrs-blue;
          text-transform: uppercase;
        }
      }
    }
  }
}
