@import './variables';

$forum-light-blue: #d0e9fd;
$forum-white-blue: #f7fbff;
$forum-gray: #8898aa;
$forum-dark-gray: #6a7a8b;
$forum-blue: #34a5f3;
$forum-red: #e2445c;
$forum-green: #34c8a0;
$forum-dirty-white: #f3f5f7;
$forum-black: #17294d;

.community-container {
  @apply flex flex-wrap relative;

  @media (max-width: $small-mobile) {
    @apply justify-center;

    .category-box {
      margin-right: 0px !important;
    }
  }

  &.public {
    @apply justify-center;
    max-width: 885px;
    margin: 0 auto;
    margin-top: 30px;
  }

  .category-box {
    @apply flex relative flex-col w-full bg-white overflow-hidden cursor-pointer;
    border-radius: 10px;
    height: fit-content;
    min-height: 150px;
    max-height: 274px;
    margin-bottom: 20px;
    margin-right: 20px;
    max-width: 274px;
    transition: 0.5s all ease-in;

    // &::before {
    //   content: ' ';
    //   height: 10px;
    //   width: 100%;
    //   background: $forum-blue;
    // }

    &:hover {
      // overflow: visible;

      .text-content {
        max-height: 1000px !important;
        bottom: -45px !important;
        padding: 10px !important;
      }

      .body-content {
        max-height: 1000px;

        .title {
          img {
            display: none;
          }
        }

        .join-btn {
          display: flex;
        }
      }
    }

    .more-option {
      @apply flex absolute cursor-pointer;
      right: 10px;
      top: 10px;
      z-index: 10;

      .mark {
        @apply flex font-semibold;
        font-size: 13px;
        color: #1a1f2c73;
      }
    }

    .icon-box {
      @apply flex relative bg-gray-100 w-full justify-center items-center;
      height: 97px;
      flex: none;
      padding: 9px;
      border-radius: 10px 10px 0px 0px;
      z-index: 2;
    }

    .body-content {
      @apply flex flex-col relative overflow-hidden;
      height: auto;
      max-height: 45px;
      transition: 0.1s all ease-in;

      .title {
        @apply flex flex-col justify-center items-center font-semibold text-qureosBlack justify-center relative bg-white;
        font-size: 15px;
        padding: 12px 0px;
        z-index: 10;

        img {
          margin-top: 5px;
        }
      }

      .join-btn {
        @apply justify-center items-center cursor-pointer self-center font-semibold text-qureosBlue;
        font-size: 15px;
        width: 70px;
        height: 32px;
        padding: 6px;
        background: #f2f9ff;
        border-radius: 23px;
        margin-bottom: 20px;
        display: none;

        &.inverse {
          background: #34c8a034;
          color: $forum-green;
        }
      }

      .text-content {
        @apply flex absolute h-auto overflow-hidden;
        color: $forum-gray;
        font-size: 13px;
        max-height: 0px;
        background: white;
        width: 100%;
        left: 0px;
        padding: 0px 10px;
        border-radius: 10px;
        transition: 0.1s all ease-in;
        bottom: 0px;
        z-index: 1;
      }
    }
  }
}

.forum-container {
  @apply flex;

  @media (max-width: $mobile) {
    padding: 0 10px;
  }

  &.single-post {
    width: 100%;
    max-width: 700px;

    .forum-post-container {
      width: 100%;
    }
  }

  .talent-hub-btn {
    @apply flex items-center justify-center;
    color: white;
    font-size: 20px;
    background: $forum-green;
    padding: 9px;
    border-radius: 10px;
    margin-bottom: 15px;
  }

  .left-navig {
    @apply flex flex-col w-full h-auto;
    min-height: 300px;
    max-width: 800px;

    .talent-hub-btn {
      display: none;

      @media (max-width: $mobile) {
        display: flex;
      }
    }

    .forum-filter-container {
      @apply flex flex-col bg-white w-full items-start;
      min-height: 120px;
      border-radius: 10px;
      padding: 15px;
      margin-bottom: 15px;

      .head-title {
        @apply flex text-qureosBlack;
        font-size: 18px;
        height: 24px;
        margin: 0px;
      }

      .action-box {
        @apply flex mt-auto w-full;

        @media (max-width: $mobile) {
          @apply flex-col;
          margin-top: 15px;
        }

        .input-box {
          @apply flex relative;
          width: 100%;
          max-width: 300px;
          height: 40px;
          border: 1px solid $forum-light-blue;
          border-radius: 5px;

          @media (max-width: $mobile) {
            width: 100%;
            max-width: 100%;
            margin-bottom: 15px;
          }

          input {
            @apply flex w-full h-full;
            border: none;
            background: transparent;
            padding: 11px 15px;
            font-size: 13px;
            color: $forum-gray;
          }

          .btn {
            padding: 8px;
            width: 30px;
            cursor: pointer;
          }
        }

        .btn-box {
          @apply flex ml-auto overflow-hidden;
          height: 40px;
          width: fit-content;
          border: 1px solid $forum-light-blue;
          border-radius: 5px;

          @media (max-width: $mobile) {
            @apply flex-col;
            width: 100%;
            height: auto;

            .btn-item {
              @apply justify-center;
              flex: 1;
            }
          }

          .btn-item {
            @apply flex text-qureosBlack items-center cursor-pointer;
            font-size: 13px;
            border-left: solid 1px $forum-light-blue;
            padding: 12px 10px;

            @media (max-width: $mobile) {
              border-left: none;
              border-top: solid 1px $forum-light-blue;

              &:first-child {
                border-top: none;
              }
            }

            &:nth-child(1) {
              border-left: none;
            }

            img {
              margin-right: 6px;
              filter: grayscale(1);
              height: 20px;
            }

            &.active {
              color: $forum-blue;

              img {
                filter: none;
              }
            }
          }
        }
      }
    }
  }

  .right-navig {
    @apply flex flex-col w-full;
    max-width: 328px;
    margin-left: 15px;

    @media (max-width: $mobile) {
      display: none;
    }

    .talent-hub-btn {
      @apply flex items-center justify-center;
      color: white;
      font-size: 20px;
      background: $forum-green;
      padding: 9px;
      border-radius: 10px;
      margin-bottom: 15px;
    }

    .card-box {
      @apply flex flex-col w-full h-auto bg-white overflow-hidden;
      border-radius: 10px;
      min-height: 100px;
      margin-bottom: 15px;

      &::before {
        content: ' ';
        height: 10px;
        width: 100%;
        background: $forum-blue;
      }

      .body-content {
        @apply flex flex-col;
        padding: 15px 25px 25px 25px;

        .title {
          @apply flex font-semibold;
          font-size: 15px;
          color: $forum-black;
          margin-bottom: 15px;
        }

        .item-counter-box {
          @apply flex;
          padding-bottom: 15px;
          margin-bottom: 15px;
          border-bottom: solid 1px $forum-light-blue;

          .item-counter {
            @apply flex justify-center items-center;
            background: #eff8fe;
            padding: 3px 12px;
            color: $forum-blue;
            font-size: 25px;
            min-width: 50px;
          }

          .item-label {
            @apply flex font-semibold text-qureosBlack;
            font-size: 20px;
            padding: 12px 15px;
          }
        }

        .text-content {
          @apply flex;
          color: $forum-gray;
          font-size: 15px;
        }
      }
    }

    .insight-box {
      @apply flex flex-col bg-white;
      border-radius: 10px;

      .title-box {
        @apply flex items-center font-semibold;
        font-size: 15px;
        color: $forum-black;
        padding: 15px 20px;
        border-bottom: solid 1px $forum-light-blue;
      }

      .insight-content-box {
        @apply flex flex-col;
        padding: 20px;
        border-bottom: solid 1px $forum-light-blue;

        &.no-line {
          border-bottom: none;
        }

        .title {
          font-size: 15px;
          color: $forum-black;
          margin-bottom: 4px;
        }

        .sub-title {
          font-size: 13px;
          color: $forum-gray;
        }

        .data-box {
          @apply flex;
          margin-top: 15px;
          border-radius: 5px;
          border: solid 1px $forum-light-blue;
          padding: 10px 15px;

          .data-item {
            @apply flex flex-col;

            .value {
              font-size: 25px;
              color: $forum-black;
            }
            .sub-value {
              font-size: 13px;
              color: $forum-gray;
            }
          }

          .data-icon {
            @apply flex ml-auto items-center;
            zoom: 3;
          }
        }
      }
    }
  }

  .forum-action-container {
    @apply w-full h-auto bg-white;
    min-height: 90px;
    border-radius: 10px;
    padding: 20px;
    margin-bottom: 15px;

    .forum-action-content {
      @apply flex items-start;
    }

    .attachment-area {
      @apply pt-4;
      padding-left: 60px;

      ul {
        @apply w-full flex;

        li {
          a {
            @apply flex text-qureosBlue text-sm pr-2;

            img {
              @apply mr-2;
            }
          }
        }
      }

      .uploaded-imgs {
        padding-top: 15px;

        li {
          @apply relative;
          margin-right: 20px;

          img {
            width: 50px;
          }

          .remove-img {
            background: black;
            position: absolute;
            right: -9px;
            width: 20px;
            padding: 5px;
            border-radius: 100%;
            top: -9px;
          }
        }
      }

      .video-input {
        @apply mb-2;
      }
    }

    @media (max-width: $mobile) {
      .user-photo {
        display: none !important;
      }

      .attachment-area {
        padding-left: 0;
      }
    }

    .user-photo {
      @apply flex;
      height: 45px;
      width: 45px;
      border-radius: 100%;
      margin-right: 15px;
      object-fit: cover;
    }

    .input-box {
      @apply flex overflow-hidden w-full;
      border: 1px solid $forum-light-blue;
      border-radius: 5px;
      height: auto;
      min-height: 45px;

      &.v2 {
        @apply flex-col;

        input {
          font-weight: bold;
        }

        .footer-action {
          @apply flex;
          background: $forum-white-blue;

          .ant-select-focused .ant-select-selector,
          .ant-select-selector:focus,
          .ant-select-selector:active,
          .ant-select-open .ant-select-selector {
            border: none;
            box-shadow: none !important;
          }

          .ant-select {
            width: 100%;
            margin-left: 5px;
            outline: none !important;

            .ant-select-selection-search-input {
              width: fit-content !important;
              padding: 0px !important;
            }

            .ant-select-open {
              border: none !important;
            }

            .ant-select-selection-item-remove {
              @apply flex items-center;
            }

            .ant-select-selector {
              background: $forum-white-blue;
              border: none;
            }
          }

          .alternate-post-btn {
            @apply flex w-full justify-end;
            background: $forum-white-blue;
            padding: 8px;

            button {
              @apply flex items-center cursor-pointer justify-center text-qureosBlue self-end;
              font-size: 14px;
              padding: 7px 11px;
              width: fit-content;
              border-radius: 5px;
              border: solid 1px $forum-blue;
              background: transparent;

              span {
                margin-right: 12px;
              }
            }
          }
        }
      }

      .input,
      input {
        @apply flex h-full w-full;
        border: none;
        background: $forum-white-blue;
        padding: 13px 15px;
        height: 45px;
        min-height: 44px;
        resize: none;
        color: $forum-gray;
      }

      .post-btn {
        @apply flex items-center cursor-pointer justify-center;
        max-width: 190px;
        height: auto;
        background: $forum-blue;
        color: white;
        font-size: 15px;
        padding: 9px 25px;
        border-radius: 0px;

        img {
          margin-right: 10px;
        }

        @media (max-width: $mobile) {
          img {
            display: none;
          }
        }
      }
    }
  }

  .forum-post-container {
    .forum-post-body {
      @apply flex flex-col bg-white w-full  h-auto relative;
      border-radius: 10px;
      padding: 20px 22px 0px 22px;
      margin-bottom: 15px;
      transition: 0.5s all ease-in;

      &.highlighted {
        background: #39a4f31c;
      }

      .m-dot {
        margin: 0px 5px;
        width: 3px;
        height: 3px;
        border-radius: 100%;
      }

      .post-header {
        @apply flex;
        margin-bottom: 25px;

        .post-author-box {
          @apply flex;

          .user-photo {
            width: 35px;
            height: 35px;
            border-radius: 100%;
            margin-right: 13px;
            object-fit: cover;
          }

          .info-box {
            @apply flex flex-col;
            .name {
              @apply flex font-semibold items-center text-qureosBlack;
              font-size: 13px;

              span {
                margin-right: 5px;
                text-transform: capitalize;
              }

              img {
                // margin-left: auto;
                width: 12px;
                height: 12px;
              }
            }
            .time-box {
              @apply flex items-center;
              font-size: 11px;
              color: $forum-gray;

              .m-dot {
                background: $forum-gray;
              }
            }
            .mentor-badge {
              @apply rounded px-2 text-white bg-qureosBlue;
            }
          }
        }

        .post-action-box {
          @apply flex ml-auto items-center self-start;
          width: fit-content;

          .action-item {
            @apply flex font-semibold cursor-pointer;
            font-size: 13px;
            margin-left: 15px;
            color: $forum-gray;

            &:hover,
            &.active {
              color: $forum-blue;
            }

            &:nth-child(1) {
              margin-left: 0px;
            }
          }
        }
      }

      .post-body {
        margin-bottom: 10px;
        @apply flex flex-col;

        .post-title {
          @apply flex font-bold text-qureosBlack;
          font-size: 15px;
          margin-bottom: 13px;
        }

        .post-content {
          @apply text-qureosBlack;
          font-size: 14px;
          white-space: pre-wrap;

          a {
            color: $forum-blue;
          }

          .tagged-user {
            color: $forum-blue;
            font-weight: bold;
          }
        }

        .yt-frame {
          width: 100%;
        }

        .ant-carousel {
          .slick-slider {
            .slick-dots {
              bottom: -25px;
              width: initial;

              li {
                border-radius: initial;
                height: initial;

                button {
                  border-radius: initial;
                  height: 3px;
                }
              }
            }

            .photo-wrapper {
              @apply w-full flex justify-center;
              max-height: 400px;

              img {
                object-fit: scale-down;
              }
            }
          }
        }
      }

      .post-footer-section {
        @apply flex;
        position: relative;
        left: -22px;
        width: calc(100% + 44px);
        border-top: solid 1px $forum-light-blue;
        padding: 15px 15px 15px 25px;

        &.no-line {
          border-top: none;
        }

        .emoji-box {
          @apply flex items-center;
          font-size: 13px;
          color: $forum-red;
          margin-right: 20px;

          .m-dot {
            background: $forum-red;
          }
        }

        .helpful-box {
          @apply flex items-center;
          font-size: 13px;
          color: $forum-green;
          margin-right: 20px;

          img {
            margin-right: 6px;
          }

          .m-dot {
            background: $forum-green;
          }
        }

        .comment-box {
          @apply flex items-center;
          font-size: 13px;
          color: $forum-blue;
          cursor: pointer;

          img {
            margin-right: 6px;
          }

          .m-dot {
            background: $forum-blue;
          }
        }

        .tags-box {
          @apply flex uppercase font-semibold items-center;
          color: $forum-dark-gray;
          font-size: 13px;

          .title {
            margin-right: 17px;
          }

          .tag-item {
            @apply flex items-center capitalize justify-center;
            padding: 4px 14px;
            border-radius: 18px;
            background: $forum-dirty-white;
            margin-right: 7px;
          }
        }

        .footer-action-box {
          @apply flex items-center cursor-pointer;
          margin-right: 25px;

          color: #6d7a89 !important;

          .icon-box {
            background: #eff4fa !important;
          }

          &.red {
            &.active,
            &:hover {
              color: #e2445c !important;
              .icon-box {
                background: #fbf0f1 !important;
              }
            }
          }

          &.green {
            @media (max-width: $mobile) {
              span {
                &:not(.mobile) {
                  display: none;
                }

                &.mobile {
                  display: block;
                }
              }
            }

            &.active,
            &:hover {
              color: $forum-green !important;

              .icon-box {
                background: #eafaf5 !important;
              }
            }
          }

          &.blue {
            &.active,
            &:hover {
              color: $forum-blue !important;

              .icon-box {
                background: #eff8fe !important;
              }
            }
          }

          .icon-box {
            @apply flex justify-center items-center;
            padding: 10px;
            margin-right: 8px;
            border-radius: 18px;
            width: 34px;
            height: 27px;
          }
        }
      }

      .post-comment-box {
        @apply flex flex-col w-full;
        border: solid 1px $forum-light-blue;
        border-radius: 5px;
        background: $forum-white-blue;
        height: auto;
        padding: 13px 10px;
        margin-bottom: 20px;

        .input {
          border: none;
          background: transparent;
          min-height: 40px;
          resize: none;
          padding: 0px;
          color: $forum-gray;
          font-size: 13px;
        }

        .reply-btn {
          @apply flex self-end text-qureosBlue justify-center items-center;
          border: solid 1px $forum-blue;
          padding: 7px 15px;
          background: transparent;
        }
      }
    }

    .forum-post-comment {
      @apply flex flex-col;

      .m-dot {
        margin: 0px 5px;
        width: 3px;
        height: 3px;
        border-radius: 100%;
      }

      .forum-comment-item {
        @apply flex;
        margin-bottom: 15px;

        .user-photo {
          @apply flex rounded-full;
          width: 35px;
          height: 35px;
          margin-right: 13px;
          object-fit: cover;
        }

        .comment-body {
          @apply flex flex-col w-full;

          .comment-container {
            @apply flex flex-col w-full bg-white;
            border-radius: 5px;
            padding: 10px;
            margin-bottom: 5px;

            &:hover {
              .comment-header {
                .action-item {
                  opacity: 1;
                }
              }
            }

            .comment-header {
              @apply flex items-center relative;
              color: $forum-gray;
              margin-bottom: 9px;

              .title {
                @apply flex font-semibold text-qureosBlack;
                font-size: 13px;
              }

              .m-dot {
                background: $forum-gray;
              }

              .delete-comment {
                @apply absolute;
                right: 10px;
                top: 10px;

                img {
                  width: 8px;
                }
              }

              .action-item {
                @apply flex ml-auto;
                cursor: pointer;
                opacity: 1;
              }
            }

            .comment-desc {
              @apply flex flex-col text-qureosBlack;
              font-size: 14px;
              white-space: pre-wrap;

              a {
                color: $forum-blue;
              }

              span {
                @apply flex flex-col flex-wrap;
              }

              .comment-tag {
                color: $forum-blue;
                margin-right: 10px;
              }

              .post-reply-box {
                @apply flex flex-col w-full;
                border: solid 1px $forum-light-blue;
                border-radius: 5px;
                background: $forum-white-blue;
                height: auto;
                padding: 13px 10px;
                margin: 17px 0px 20px 0px;

                .input {
                  border: none;
                  background: transparent;
                  min-height: 40px;
                  resize: none;
                  padding: 0px;
                  color: $forum-gray;
                  font-size: 13px;
                }

                .reply-btn {
                  @apply flex self-end text-qureosBlue justify-center items-center;
                  border: solid 1px $forum-blue;
                  padding: 7px 15px;
                  background: transparent;
                }
              }
            }
          }

          .comment-footer {
            @apply flex cursor-pointer;

            .footer-item {
              @apply flex items-center;
              margin-right: 16px;

              &.blue {
                &.active,
                &:hover {
                  color: $forum-blue !important;
                }

                color: $forum-gray;
              }
              &.green {
                &.active,
                &:hover {
                  color: $forum-green !important;
                }

                color: $forum-gray;

                .m-dot {
                  background: $forum-green;
                }
              }

              .icon {
                margin-right: 6px;
              }
            }
          }
        }
      }
    }
  }

  .forum-pagination {
    @apply flex w-full justify-end;

    .pagination-box {
      @apply flex justify-end;

      .pagination-btn {
        @apply flex justify-center cursor-pointer;
        font-size: 14px;
        width: 22px;
        height: 22px;
        color: $forum-dark-gray;
        border: solid 1px transparent;
        margin-right: 5px;

        &.disabled {
          opacity: 0.3;
          cursor: not-allowed;
        }

        &.active {
          @apply font-semibold;
          border-radius: 2px;
          border-color: $forum-blue;
          color: $forum-blue;
        }
      }
    }
  }
}

.emoji-menu {
  .ant-dropdown-menu {
    border-radius: 30px;
    padding: 3px 10px;
    width: fit-content;

    li {
      background: transparent !important;
      padding: 10px;
    }

    .emoji-menu-box {
      @apply flex justify-center items-center;
      padding-left: 10px;

      .emoji-item {
        margin-right: 5px;
        zoom: 2.5;
        cursor: pointer;
      }
    }
  }
}

.action-menu {
  width: 100px;

  .menu-item {
    @apply flex;

    img {
      margin-right: 10px;
    }
  }
}

.user-tooltip {
  padding-bottom: 3px;
  font-weight: 600;
}

.update-form-post-ant-modal {
  .ant-modal-content {
    width: 100vw;
    max-width: 750px;
  }
}

.report-box {
  @apply flex flex-col;
  margin-top: 10px;

  .title {
    @apply flex font-semibold;
    font-size: 16px;
    color: $forum-black;
  }
  .desc {
    @apply flex;
    font-size: 15px;
    color: $forum-gray;
  }

  .report-tags-box {
    @apply flex;
    margin-top: 12px;

    .report-tag {
      @apply flex font-semibold justify-center items-center cursor-pointer;
      background: $forum-dirty-white;
      color: $forum-dark-gray;
      border-radius: 10px;
      padding: 5px 10px;
      margin-right: 5px;
      font-size: 14px;

      &:hover,
      &.active {
        color: white;
        background: $forum-blue;
      }
    }
  }

  .report-button {
    @apply flex ml-auto font-semibold;
    border-radius: 5px;
    padding: 5px 10px;
    font-size: 15px;
    color: white;
    background: $forum-blue;

    &:disabled {
      background: $forum-dirty-white;
      color: $forum-dark-gray;
    }
  }
}

.resources-container {
  @apply flex flex-wrap relative;

  @media (max-width: $mobile) {
    @apply justify-center;
  }

  .resources-item {
    @apply flex flex-col bg-white cursor-pointer;
    border-radius: 10px;
    box-shadow: #3e44571a 0 0 20;
    width: 240px;
    height: 300px;
    margin-right: 15px;
    margin-bottom: 15px;

    &:hover {
      background: $forum-blue;

      .header-box {
        .info {
          .title,
          .sub-title {
            color: #fff;
          }
        }
      }

      .content-box {
        .body {
          background: $forum-blue;
          max-height: 1000px;
          min-height: 230px;
          height: auto;
          border-radius: 0px 0px 10px 10px;
          position: absolute;
          padding: 13px;

          .title,
          .desc {
            height: auto;
            overflow: visible;
            white-space: pre-wrap;
            color: #fff;
          }

          .more-caret {
            filter: invert(1);
          }
        }
      }

      .footer-box {
        .tags {
          border-color: #fff;
          color: #fff;
        }
      }
    }

    .header-box {
      @apply flex;
      padding: 13px;
      border-bottom: solid 1px #d7dee7;

      .user-photo {
        @apply flex;
        width: 40px;
        height: 40px;
        border: solid 1px #e2e9f2;
        border-radius: 40px;
        margin-right: 10px;
      }

      .info {
        @apply flex flex-col;

        .title {
          @apply block overflow-hidden whitespace-nowrap font-semibold text-qureosBlack;
          font-size: 14px;
          width: 130px;
          text-overflow: ellipsis;
        }
        .sub-title {
          font-size: 11px;
          color: $forum-dark-gray;
        }
      }
    }

    .content-box {
      @apply flex h-full justify-center relative;

      .body {
        @apply flex flex-col w-full relative  overflow-hidden;
        padding: 13px 13px 0px 13px;
        height: auto;
        transition: 0.1s max-height ease-in;
        max-height: 150px;

        .title {
          @apply flex font-semibold text-qureosBlack;
          font-size: 18px;
          width: 185px;
        }

        .desc {
          @apply flex;
          font-size: 13px;
          color: $forum-dark-gray;
        }

        .more-caret {
          margin-top: 6px;
        }
      }
    }

    .footer-box {
      @apply flex flex-wrap mt-auto;
      height: fit-content;
      padding: 13px 10px 0px 10px;

      .tags {
        @apply flex  justify-center items-center font-semibold rounded-full;
        padding: 5px 15px;
        font-size: 12px;
        color: $forum-dark-gray;
        border: solid 1px #b0bbc8;
        margin-right: 8px;
        margin-bottom: 8px;
      }
    }
  }
}

.forum-modal {
  @apply mx-auto;
  max-width: 800px;
}

.quote-card {
  @apply mb-3 rounded-lg bg-qureosBlue text-lg text-white;
  border: none;

  .ant-card-body {
    @apply relative;

    .close-btn {
      @apply absolute;
      right: 10px;
      top: 10px;

      img {
        width: 14px;
      }
    }
  }
}

.vote-details {
  @apply w-full flex;
  color: #7a8593;

  .votes {
    @apply relative;
    padding-right: 20px;

    &:not(.finished) {
      &:after {
        content: ' ';
        width: 5px;
        height: 5px;
        background: #727e8d;
        position: absolute;
        border-radius: 100px;
        top: 9px;
        left: calc(100% - 11px);
      }
    }
  }
}

.forum-action-content {
  .ant-mentions {
    border: none;

    &.ant-mentions-focused {
      border: none;
      box-shadow: none;
    }

    &:focus {
      outline: none;
    }

    textarea {
      background: #f7fbff;
      &:focus {
        outline: none;
      }
    }
  }
}

.dashboard-post {
  @apply shadow-lg mx-1 border border-gray-300 rounded-lg relative;
  width: 16rem;
  flex: none;
  overflow: hidden;

  &:after {
    content: ' ';
    @apply bg-qureosBlue w-full absolute;
    top: 0;
    left: 0;
    height: 10px;
  }

  &:last-child {
    @apply mr-4;
  }
}

.dashboard-post-spacer {
  width: 10px;
  height: 50px;
  flex: none;
}

.forum-container .right-navig .card-box {
  position: sticky;
  top: 70px;
}

.forum-container .right-navig .insight-box {
  position: sticky;
  top: 80px;
}
