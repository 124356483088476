@import './variables';

.challenges-heading {
  @apply w-full flex py-3;

  &.custom-spacing {
    padding-bottom: 20px;
  }

  .challenges-title {
    @apply uppercase text-xl text-black;

    &.lowercase {
      text-transform: initial;
    }
  }
}

.select-company-popover {
  @apply fixed h-auto w-2/4 m-5 rounded;
  top: 15%;
  left: calc(50% + 50px);
  transform: translateX(-50%);

  .company {
    @apply flex flex-col  text-center mx-auto;
  }

  .modal-close {
    position: absolute;
    right: 30px;
    top: 10px;
  }

  &.v2 {
    max-width: 900px;

    .ant-modal-body {
      & > .shadow-md {
        box-shadow: none;
      }
    }
  }
}

.select-company-popover-v2 {
  max-width: 900px;

  .shadow-md {
    box-shadow: none;
  }

  .company {
    @apply text-center;
  }
}

.action-bar-wrapper {
  @apply flex justify-center fixed z-10;
  top: 55px;
  width: calc(100% - 250px);
  z-index: 11;

  & > div {
    box-shadow: 1px 3px 17px 0px rgba(0, 0, 0, 0.35);
  }
}

.challenge-title-wrapper {
  @apply w-full px-5;
}

.challenge-box-wrapper {
  @apply flex flex-row justify-between;
}

.challenge-info-wrapper {
  @apply flex flex-col  w-1/2 mx-2;
}

.brief-wrapper {
  @apply mt-5 flex;
}

.challenge-details-wrapper {
  @apply flex w-1/2 mx-2;
}

.challenge-evaluation-wrapper {
  @apply w-3/4 mx-auto flex flex-col pt-20;
}

.evaluation-wrapper {
  @apply flex flex-row flex-wrap justify-between text-center rounded-lg w-full;

  .guideline {
    @apply pt-4 rounded-lg w-1/2;

    &:nth-child(odd) {
      @apply pr-4;
    }
  }
}

.resource-modal {
  @apply bg-white text-blackText p-4 rounded-lg;
  width: 600px;
}

.mentor-modal {
  @apply relative p-6;
  width: 916px;

  .balance-wrapper {
    @apply w-1/5 -mt-12 ml-2 flex items-start justify-center;
  }

  .mentorship-details {
    @apply w-3/5 my-3 flex flex-col items-center;
  }

  .mentor-type {
    @apply w-40 bg-blue-500 mx-2 rounded-lg h-56 flex flex-col items-center;
  }

  .close-button-wrapper {
    @apply w-full absolute px-12 flex justify-end;
  }
}

.challenge-details {
  @apply flex flex-col w-1/2 mx-2;
}

.submit-form {
  @apply w-1/2 mx-2;
}

.submit-challenge-modal {
  @apply rounded-lg bg-white p-8 flex flex-col items-center justify-center;
  width: 600px;
}

.challenge-session {
  @apply relative flex mt-16 mx-auto;
  max-width: 969px;
  height: calc(100vh - 200px);

  .fields-container {
    margin-bottom: 20px;
  }
}

// CHALLENGES -PROGRESS STYLING RESPONSIVENESS

.skill-progress-details {
  @apply flex flex-1 items-center;
}

@media (max-width: $mobile) {
  .skill-progress-details {
    flex: unset;
    flex-direction: column;
    width: 100%;
    align-items: unset;
    .progress-title {
      width: 100%;
    }
    .progress-bar-container {
      .progress-bar {
        margin-left: 0;
      }
    }
  }
}

.sub-skill-details {
  display: flex;
  width: 100%;

  .sub-skill-progress {
    width: 100%;
  }
}

@media (max-width: $mobile) {
  .sub-skill-details {
    flex-direction: column;
    max-width: 70%;
  }
}

// END OF CHALLENGES -PROGRESS STYLING RESPONSIVENESS

.mentor-profile-box {
  @apply ml-1 flex flex-col;
  width: 335px;

  // @media (max-width: $mobile) {
  //   &.as-modal {
  //     display: block;
  //   }

  //   position: absolute;
  //   width: calc(100vh - 293px);
  //   height: calc(100vh + 13px);
  //   z-index: 11;
  //   top: -16px;
  // }

  /*@media (max-height: $screen-h1), (max-height: $screen-h2) {
    zoom: 0.7;

    .profile-header {
      zoom: 0.8;
    }

    .skill-box {
      zoom: 0.5;
    }

    .pr-sections {
      zoom: 0.8;

      &.achievements {
        zoom: 0.5;

        .achievement-item {
          img {
            margin: 0 auto;
          }
        }
      }
    }

    .thin-scrollbar {
      &::-webkit-scrollbar {
        width: 3px;
        height: 10px;
        border-radius: 100px;
      }
    }
  }*/

  @media (max-height: $screen-h3) {
    // zoom: 0.8;
  }

  .mentor-profile {
    .m-points {
      font-size: 14px;
    }
  }
}

.active-challenges-wrapper {
  @apply w-full flex pt-8 overflow-auto;

  .skill-card-container {
    width: 224px;
    height: 250px;

    .card-box-container {
      width: 224px;
    }

    span {
      &.font-semibold {
        font-size: 15px;
      }
    }
  }
}

.challenges-cards {
  @apply flex flex-row mb-3 overflow-auto w-full;

  .skill-card {
    width: 224px;
  }
}

.challenge-overview-wrapper {
  @apply text-blackText px-4 pb-4 w-3/5 mx-auto;

  .challenge-skill {
    @apply mt-2 px-3 py-1 rounded-full border border-blue-500;
    font-size: 13px;
  }
}

.challenge-title-wrapper {
  @apply flex flex-col px-6 mx-auto w-3/5 pt-8;
}

.challenge-summary {
  @apply w-full mx-auto pt-8;
}

.mentor-modal-wrapper {
  top: calc(50% - 260px);
}

// APR. 16, 2020
.challenges-heading {
  @apply w-full flex justify-between pl-3 py-8;

  &.for-active {
    @apply pt-0 -mt-1 pb-2;
  }

  .challenges-title-wrapper {
    @apply flex-1 items-center flex;

    .challenges-title {
      @apply text-xl font-semibold;
    }
  }
}

.challenges {
  @apply w-full flex overflow-auto mb-4;
  ::-webkit-scrollbar {
    width: 0px;
    background: transparent;
  }

  &.active {
    @apply pt-3;

    .v2-skill-card {
      .skill-name {
        @apply flex flex-col;
      }
    }
  }
}

.v2-skill-card {
  @apply px-3;
  margin-bottom: 30px;

  &.active-challenge-card {
    height: 238px;
  }

  &.challenge-card {
    height: 228px;
    padding: 0px 0px 0px 15px;

    .red-dot {
      @apply absolute;
      top: 10px;
      left: 10px;
    }

    &.weekly {
      .skill-info-box {
        display: none;
      }

      .header {
        @apply justify-center flex font-thin text-center text-white absolute w-full rounded-tl-lg rounded-tr-lg uppercase;
        padding: 4px 0;
        background: #4097a8;
        z-index: 1;

        img {
          margin-left: 10px;
        }
      }

      .skill-name {
        padding-top: 27px !important;
      }
    }

    &.exclusive {
      .card-box-container {
        &:after {
          @apply absolute bg-qureosBlue rounded-bl-lg rounded-br-lg w-full;
          content: ' ';
          height: 10px;
          bottom: 0;
          left: 0;
        }
      }
    }

    &.disabled {
      .card-box-container {
        &:before {
          @apply w-full h-full absolute rounded-lg;
          content: ' ';
          background: rgba(0, 0, 0, 0.4);
          z-index: 11;
        }
      }
    }

    .company-logo {
      width: 50px;
      height: 50px;
      margin-bottom: 15px;

      img {
        object-fit: scale-down;
      }
    }
  }

  .card-box-container {
    @apply cursor-pointer;
    width: 226px;
    border: 1px solid #d7dee7;

    &.has-header {
      .skill-name {
        padding-top: 77px;
      }
    }
  }

  .skill-name {
    @apply w-full px-4 flex flex-col items-center justify-center font-semibold text-base text-center;
    height: 200px;
  }
}

.challenge-separator {
  @apply w-full border-white;
  margin-top: 33px;

  &:before {
    content: ' ';
    @apply absolute w-full border-b border-white;

    left: 0;
  }
}

.student-challenges {
  padding-top: 80px !important;
  padding-right: 0 !important;
  padding-left: 80px !important;

  &.pushed {
    padding-left: 200px !important;
  }

  .challenges-heading {
    &:first-child {
      @apply pt-1;
      margin-top: 0px;
    }

    &.as-absolute {
      @apply absolute w-full;
      top: 70px;
      padding: 0 20px;
    }
  }

  .slider-header {
    padding: 0 25px;
  }

  .slider-content {
    margin-left: 0 !important;

    .skill-challenge-box {
      &:last-child {
        margin-right: 15px;
      }

      &:first-child {
        margin-left: 25px;
      }
    }

    .v2-skill-card {
      &:last-child {
        padding-right: 15px;
      }

      &:first-child {
        // padding-left: 25px;
      }
    }
  }
}

.review-wrapper {
  @apply flex flex-col w-full mx-auto;
  max-width: 900px;
}

.review {
  .action-bar-wrapper {
    @apply fixed flex justify-center;
    z-index: 11;
  }

  .review-section-wrapper {
    @apply flex flex-row;

    .review-section {
      @apply w-1/2 px-5;

      &:last-child {
        @apply -mt-5;
      }
    }
  }

  .box-scrollbar {
    .header {
      min-height: 48px;
      height: auto;
    }
  }

  .overview-section-wrapper {
    @apply w-full flex;
  }

  .overview-section {
    @apply w-full flex-1 pr-3;

    &:last-child {
      @apply mx-2 w-full flex-1;
    }
  }
}
// END APR. 16, 2020

// ==================================================================
// mobile starts here
// ==================================================================

@media (max-width: $mobile) {
  // APR. 16, 2020
  .review {
    padding-top: 40px;

    .review-section-wrapper {
      @apply flex-col;

      .review-section {
        @apply w-full;

        &:last-child {
          @apply mt-0;
        }

        .box-scrollbar {
          @apply mx-0;
        }
      }
    }

    .overview-section-wrapper {
      @apply flex-col;
      padding: 0 10px;

      .overview-section {
        padding-right: 16px;

        .box-scrollbar {
          @apply mb-4 rounded-lg;

          .content {
            @apply rounded-tl-lg rounded-tr-lg;
            height: auto !important;

            p {
              max-height: initial !important;
            }
          }

          .header {
            @apply rounded-bl-lg rounded-br-lg;
          }
        }
      }
    }
  }

  .challenges-heading {
    padding-left: 20px;

    &.for-active {
      @apply py-4;
    }
  }

  .challenges {
    padding-left: 8px;
  }
  // END APR. 16, 2020

  .mentor-modal-wrapper {
    top: 140px !important;
  }

  .challenge-summary {
    @apply pt-16;
  }

  .student-challenges {
    padding: 0;

    & > .generic-slide-container {
      padding-left: 0px;
    }

    .banner {
      margin: 0 15px;
    }
  }

  .challenge-title-wrapper {
    @apply w-full px-3;

    & > div {
      @apply px-5;
      text-align: left;
    }
  }

  .challenge-overview-wrapper {
    @apply w-full px-3;
  }

  .progress-wrapper {
    @apply w-full px-3;
  }

  .challenges-cards {
    padding: 20px;
  }

  .challenge-wrapper {
    padding-top: 60px !important;
  }

  .student-challenges {
    padding-top: 80px !important;
  }

  .action-bar-wrapper {
    width: 100%;
    top: 48px;

    & > div {
      transform: scale(0.8);
    }
  }

  .challenges-container {
    & > .flex {
      @apply justify-center;
    }
  }

  .select-company-popover {
    @media (max-width: $almostMobile) {
      width: calc(100vw + 70px);
      left: calc(50% - 24px);
      margin-right: 0;

      & > div {
        @apply h-screen;
      }
    }

    width: calc(100% - 240px);
    display: flex;
    justify-content: center;
    left: 50%;
    top: -20px;
    height: 100vh;

    & > div {
      @apply relative overflow-auto;
    }

    .modal-close {
      position: absolute;
      right: 38px;
      top: 10px;
      z-index: 1;
    }

    .text-2xl {
      font-size: 1rem;
      padding-left: 5px;
    }

    .company {
      @apply p-3;
    }
  }

  .challenge-title-wrapper {
    @apply px-0 text-center;
  }

  .challenge-box-wrapper {
    @apply flex flex-col w-full;

    .box-scrollbar {
      width: 100%;
      @apply mb-4;
    }
  }

  .challenge-info-wrapper {
    @apply w-full pr-3;
  }

  .brief-wrapper {
    @apply mt-0 mb-4;

    .box-scrollbar {
      @apply mx-0;

      .content {
        height: auto !important;
      }
    }
  }

  .challenge-details-wrapper {
    @apply w-full pr-3;

    .box-scrollbar {
      .content {
        height: auto !important;
      }
    }
  }

  .challenge-evaluation-wrapper {
    @apply w-full px-3 pt-5;
  }

  .evaluation-wrapper {
    @apply flex-col;

    .guideline {
      @apply w-full px-0;

      &:nth-child(odd) {
        @apply px-0;
      }
    }
  }

  .resource-modal {
    width: 100%;

    p {
      @apply text-center;
    }
  }

  .mentor-modal {
    @apply p-3;
    width: 100%;

    .form-steps {
      margin-top: 40px;
    }

    .balance-wrapper {
      display: none;
    }

    .mentorship-details {
      @apply w-full flex flex-col;
    }

    .close-button-wrapper {
      @apply px-10;
    }
  }

  .challenge-details {
    @apply w-full pr-3 mb-4;

    .box-scrollbar {
      .content {
        height: auto !important;
      }
    }
  }

  .submit-form {
    @apply w-full mx-auto pr-3;
  }

  .submit-challenge-modal {
    width: 100%;
  }

  .challenge-session {
    @apply mx-auto px-3 flex flex-col flex-col-reverse;
    box-sizing: border-box;

    .mentor-profile-box {
      @apply mb-4 w-full;
      margin-left: 1px;
      display: none;

      &.no-mentor {
        display: block;
      }

      & > div {
        @apply py-4;
      }
    }
  }

  .active-challenges-wrapper {
    @apply px-6;
    padding-top: 50px;

    .skill-card-container {
      margin-left: 0px;
    }

    .placeholder-div {
      width: 200px;
      height: 100%;
      padding: 10px;
    }
  }
}

// APR 27. 2020
@media (max-width: $mobile) {
  .challenge-template {
    &.with-mentor-session {
      padding-bottom: 0;
      @apply overflow-hidden h-screen;

      .action-bar-wrapper {
        display: none;
      }

      .challenge-session {
        margin: 0;
        padding: 0;
        width: 100%;
        height: 100%;

        .mentor-profile-box {
          display: none;
        }
      }
    }
  }
}

#main {
  &.wrap-challenge-template {
    &.with-mentor-session {
      @media (max-width: $mobile) {
        .header-navigation {
          display: none;
        }
      }
    }
  }
}

.qrs-main-content {
  &.with-mentor-session {
    @media (max-width: $mobile) {
      padding-top: 0px;
    }
  }

  .challenge-session {
    .chat-wrapper {
      .chat-v2 {
        .controls {
          height: 50px;
          padding: 0 20px;

          @apply flex items-center justify-between;
        }
      }
    }
  }
}

.empty-skill-box {
  @apply flex items-center justify-center cursor-pointer;
  width: 226px;
  height: 109px;
  border-radius: 10px;
  border: dashed 1px #aebbc9;
  font-size: 18px;
  color: #8898aa;
  margin-left: 10px;
}

.skill-challenge-box {
  flex: none;
  width: 226px;
  margin-bottom: 15px;
  height: 109px;
  border-radius: 10px;
  box-shadow: 0px 0px 20px #3e44571a;
  margin-left: 15px;
  @apply flex flex-col overflow-hidden bg-white;

  &::before {
    content: ' ';
    height: 10px;
    width: 100%;
    background: #84e1b6;
  }

  & > .title {
    padding: 0 10px;
    margin-top: 10px;
    height: 40px;
    font-size: 15px;
    @apply w-full flex text-blackText justify-start items-center flex-wrap text-left;
  }

  & > .footer {
    @apply mt-auto flex;
    padding: 10px;

    .text {
      font-size: 15px;
      @apply text-qureosBrightGreen font-semibold;
    }

    .endorsement-value {
      @apply flex items-center justify-center font-semibold;
      width: 44px;
      height: 24px;
      border: solid 1px #d3f4df;
      border-radius: 12px;
      background: #edfbf5;
      font-size: 15px;
      color: #00cc6f;
    }
  }
}

.skill-challenge-coming-soon {
  width: 226px;
  height: 109px;
  border: 1px dashed #aebbc9;
  border-radius: 10px;
  font-size: 18px;
  color: #8898aa;
  @apply flex text-center justify-center items-center;
}

.new-challenge-card-footer {
  height: 40px !important;
  border-top: 0.5px solid #d6dee8;

  & > .footer {
    @apply mt-auto flex w-full;
    padding: 5px;

    .text {
      font-size: 15px;
      @apply text-qureosBrightGreen font-semibold;
    }
  }
}

.challenge-workspace {
  margin: 0 auto !important;

  .tab-handle {
    @apply justify-center items-center;
    padding: 0px 0px 0px 10px;

    .challenge-handle {
      @apply flex font-semibold uppercase cursor-pointer;
      font-size: 18px;
    }
  }

  .review-item {
    @media (max-width: $mobile) {
      .scoring-wrapper {
        margin-left: auto !important;
      }

      .evaluation-options {
        &::after {
          width: calc(100% - 80px);
        }
      }
    }

    .review-title {
      text-align: left;
    }

    .scoring-wrapper {
      margin-left: -45px;
    }

    .review-content {
      textarea {
        resize: none;
      }
    }
  }

  .resources-box {
    @apply flex flex-wrap justify-center w-full relative;
    margin-top: 25px;

    .report-btn {
      width: 22px;
      height: 22px;
      cursor: pointer;
      position: absolute;
      z-index: 9;
      top: -10px;
    }

    .resources-item {
      width: 69px;
      height: 100px;
      margin-right: 60px;

      @media (max-width: $mobile) {
        margin-right: 12px;
      }

      .file-name {
        font-size: 13px;
        margin-top: 4px;
      }
    }
  }

  .challenge-submit-textarea {
    overflow: hidden;
  }

  .challenge-submission-editor {
    position: static;

    .ck-content {
      height: 345px !important;
    }
  }
}

.session-workspace {
  min-height: 0px !important;
  margin-bottom: -25px !important;
}

.submitted-weekly-challenge {
  @apply flex flex-col w-full justify-center;
  max-width: 737px;
  height: auto;
  min-height: 457px;
  background: #fff;
  border-radius: 10px;
  box-shadow: #6a70801a 0 0 20px;
  margin: 0 auto;
  padding: 17px 40px 30px 40px;

  .banner-item {
    margin-bottom: 8px;
  }

  .caption {
    @apply w-full;
    color: #3aa4f3;
    font-size: 18px;
    text-align: center;
  }
  .sub-caption {
    @apply w-full;
    color: #576271;
    font-size: 14px;
    text-align: center;
  }

  .banner-box {
    @apply flex w-full overflow-hidden;
    height: 60px;
    border-radius: 10px;
    box-shadow: #3e44571a 0 0 20px;
    max-width: 650px;
    margin: 0 auto;
    margin-top: 25px;

    .left-box {
      @apply flex flex-1 justify-center items-center;
      background: white;
    }
    .right-box {
      @apply flex w-full justify-center items-center font-semibold;
      width: 100%;
      max-width: 532px;
      background: #3aa4f3;
      color: #fff;
      font-size: 15px;
    }
  }
}

.workspace-team-container {
  @apply flex flex-col;
  padding-top: 20px;
  height: 100%;

  .add-member-btn {
    @apply bg-qureosBlue cursor-pointer rounded-full text-sm px-5 py-1;
    width: fit-content;
    color: white;
  }

  table {
    .profile-pic {
      height: 30px;
      width: 30px;
      margin-right: 10px;
    }
  }
}

.confirm-challenge-modal {
  max-width: 600px;
}
