.antd-card-p-none {
  .ant-card-body {
    padding: 0px;
  }
}

.antd-card-h-full {
  .ant-card-body {
    height: 100%;
  }
}
