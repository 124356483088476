.intl-tel-wrapper .intl-tel-input {
  width: 100%;
  .selected-flag {
    width: 120px !important;
    gap: 10px;
    border-right: solid #d1d5db;
    display: flex !important;
    align-items: center;
    padding: 10px;
    .selected-dial-code {
      padding-left: 0 !important;
      margin-left: auto;
      pointer-events: none;
    }
    .arrow {
      border-radius: 1px;
      border-color: #9ca3af !important;
      pointer-events: none;
      position: absolute;
      left: 40px;
      top: 13px;
      &:after {
        content: '';
      }
    }
  }
}
