.antd-selector {
  .ant-select-selector {
    box-shadow: none !important;
    height: 38px !important;
    & > span {
      display: flex;
      align-items: center;
      height: 100%;
    }
  }
}

.antd-selector__corporate.ant-select-focused {
  .ant-select-selector {
    box-shadow: 0 0 0 1px var(--theme-primary) !important;
  }
}

.no-focus {
  input[type='search']:focus {
    --tw-ring-color: transparent !important;
  }
}

.ant-select-selection-item-remove {
  svg {
    margin-top: -4px;
  }
}

.select-height-42 {
  .ant-select-selector {
    height: 42px !important;
    .ant-select-selection-overflow {
      overflow: scroll;
      height: 100%;
    }
  }
}

.ant-select {
  &.height-11 .ant-select-selector {
    height: 45px !important;
    .ant-select-selection-item {
      display: flex;
      align-items: center;
    }
  }
}

.no-focus {
  .ant-select-focused .ant-select-selector,
  .ant-select-selector:focus,
  .ant-select-selector:active,
  .ant-select-open .ant-select-selector {
    border-color: #d9d9d9 !important;
    box-shadow: none !important;
  }
}

.antd-rounded {
  .ant-select-selector {
    border-radius: 8px !important;
  }
}

.placeholder-center {
  .ant-select-selection-placeholder {
    display: flex !important;
    align-items: center !important;
  }
}

.custom-suffix-icon {
  .ant-select-arrow {
    display: flex !important;
    align-items: center;
    justify-content: center;
  }
}
