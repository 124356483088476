// only way to center image in chat preview for now
// need to improve later

.ant-image-preview-img {
  margin: 0 auto !important;
  object-fit: contain;
}

.ant-image-preview-img-wrapper {
  display: flex;
  padding: 10rem 0 !important;
}

.ant-image-preview-body {
  .ant-image-preview-operations {
    justify-content: center;
  }
}
