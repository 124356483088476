.public-page {
  padding-top: 100px;

  .navigation {
    @apply fixed w-full bg-white shadow-lg px-4 py-3 flex justify-between items-center;
    top: 0;
    left: 0;
    z-index: 10;

    .logo {
      height: 60px;
    }

    .links {
      a {
        font-size: 18px;
      }
    }
  }

  .profile-cards-wrapper {
    @apply w-full flex flex-wrap mx-auto justify-center my-4;
    max-width: 1113px;
  }

  .profile-card-loader {
    @apply mt-6 p-5 relative overflow-hidden;
    width: 336px;
    height: 465px;

    .ph-item {
      @apply rounded-lg shadow-lg;
      height: 100%;
    }
  }
}
