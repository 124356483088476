@import './variables';

.ant-btn {
  &.ant-btn-primary {
    background: #3461ff;
    color: white;
  }
}

.confirm-modal {
  @apply w-full rounded-lg p-3 flex justify-center flex-col relative;
  min-width: 300px;
  max-width: 800px;

  .header {
    @apply relative text-qureosBlue text-center flex items-center justify-center text-xl;
    width: calc(100% + 24px);
    height: 30px;

    .close-button {
      @apply absolute rounded-full bg-white;
      right: 10px;
      padding: 6px;

      img {
        height: 10px;
      }
    }
  }

  .content-text {
    @apply w-full px-24 text-center pt-3 pb-4;
  }

  .confirm-button-wrapper {
    @apply mx-auto flex py-3 justify-center w-full pt-4;

    .btn {
      @apply px-6 rounded-lg py-2 text-center text-white;

      &.true {
        @apply bg-qureosBlue;
      }

      &.false {
        @apply bg-red-600;
      }
    }
  }
}

.challenge-mentor-modal {
  width: 100% !important;
  max-width: 750px;

  .project-mentorship {
    box-shadow: none !important;
  }
}

.setting-modal {
  .ant-modal-content {
    width: 650px;
    max-width: 100vw;
    height: auto;
    // padding-bottom: 10px;
    margin: 0 auto;

    .ant-modal-body {
      padding: 0px;

      .setting-header {
        @apply flex text-qureosBlack font-semibold;
        padding: 23px 0px 15px 33px;
        border-bottom: 0.5px solid #c5e4fd;
        font-size: 15px;
      }
      .setting-section {
        @apply flex relative items-center;
        padding: 0px 0px 0px 40px;

        @media (max-width: $mobile) {
          padding: 0px 0px 0px 25px;
        }

        &.profile-section {
          min-height: 160px;
          height: auto;

          .dp-profile {
            width: 76px;
            height: 76px;
            border-radius: 100%;
            object-fit: cover;
            margin-right: 20px;
          }

          .profile-info {
            @apply flex flex-col;

            .main-info {
              @apply text-qureosBlack font-semibold;
              font-size: 18px;
              margin-bottom: 5px;
            }
            .sub-info {
              @apply text-qureosBlack font-light;
              font-size: 15px;
              margin-bottom: 5px;
            }
            .action-info {
              color: #3aa4f3;
              font-size: 13px;
              cursor: pointer;
            }
          }

          .edit-text {
            @apply font-light self-start ml-auto cursor-pointer;
            font-size: 14px;
            color: #8898aa;
            padding: 6px 20px 0px 0px;

            &:hover {
              color: #3aa4f3;
            }
          }

          .form-content {
            @apply flex flex-col justify-center;
            padding: 20px;

            @media (max-width: $small-mobile) {
              padding: 10px;
            }

            .form-item {
              @apply flex flex-col;
              margin-bottom: 15px;

              label {
                @apply font-semibold;
                color: #8898aa;
                font-size: 12px;
                margin-bottom: 8px;
              }

              input {
                @apply font-light;
                color: #8898aa;
                height: 40px;
                border: 1px solid #c5e4fd;
                border-radius: 10px;
                padding: 10px;
              }

              .ant-select-selector {
                border-radius: 10px 0px 0px 10px;
                height: 40px;
                width: 80px;
                margin-right: -6px;
                border: 1px solid #c5e4fd;
                border-right: none;
                background: #f1f3f1;

                .ant-select-selection-item {
                  padding-top: 4px;
                  color: #aaa;
                }
              }

              .action-btn {
                width: 74px;
                height: 32px;
                background: var(--theme-primary);
                border-radius: 5px;
                cursor: pointer;
                padding: 6px;
                text-align: center;
                color: #ffffff;
                font-size: 15px;
              }
            }
          }
        }
      }

      .notification-section {
        // min-height: 160px;
        height: auto;
        padding: 0px 0px 0px 33px;
        .notification-settings {
          width: 50%;
          padding: 22px 0px;

          .notification-item {
            display: flex;
            justify-content: space-between;

            .item-title {
              @apply font-light;
              font-size: 15px;
            }

            .ant-switch-checked .ant-switch-handle {
              left: calc(100% - 18px - 5px);
            }

            .ant-switch {
              width: 47px;
              height: 24px;
              background-color: #8898aa;
            }

            .ant-switch-checked {
              background-color: #2699fb;
            }

            .ant-switch-handle {
              top: 1px;
              width: 22px;
              height: 22px;
            }
            .ant-switch-handle::before {
              border-radius: 11px;
            }
          }
        }
      }

      .community-notif-container {
        @apply flex;
        padding: 10px 30px;

        .table-box {
          @apply flex flex-col w-full;

          .tr-box {
            @apply flex w-full justify-between;
            margin-bottom: 20px;

            .td-box {
              @apply flex font-light;
              font-size: 15px;
              min-width: 70px;

              &.title {
                @apply font-semibold text-qureosBlue;
                font-size: 14px;
              }

              &:first-child {
                min-width: 200px;
              }

              &:not(:first-child) {
                @apply font-semibold justify-center items-center;
              }

              .ant-checkbox {
                &::after {
                  display: none;
                }
              }
            }
          }
        }
      }
    }
  }
}

.change-password-modal {
  top: 50%;
  transform: translateY(-50%);
  font-family: 'Inter', sans-serif;
  .ant-modal-content {
    max-width: 100vw;
    min-width: 30vw;
    height: auto;
    margin: 0 auto;
    border-radius: 8px !important;

    .ant-modal-close {
      position: absolute;
      color: white;
      top: -45px;
      right: -10px;
    }

    .ant-modal-body {
      padding: 0px;
      width: 481px;

      .modal-header {
        @apply flex font-bold text-gray-900;
        padding: 34px 36px;
        font-size: 30px;
      }

      .modal-body {
        @apply px-9;
        padding-bottom: 26px;
        background-color: white;

        .form-content {
          @apply flex flex-col justify-center;

          .form-item {
            @apply flex flex-col;
            margin-bottom: 18px;

            label {
              color: rgb(55 65 81 / var(--tw-text-opacity)) !important;
              @apply text-sm;
            }
            input {
              padding: 6px 12px !important;
            }
          }
        }

        .btn {
          font-size: 16px;
          font-weight: 500;
          width: 127px;
          height: 50px;
          justify-content: center;
          align-items: center;
        }

        .btn-success {
          background-color: #1dbe65;
          cursor: pointer;

          &:hover {
            background-color: #198f4e;
          }

          &:disabled {
            background-color: gray;
            cursor: default;
          }
        }

        .btn-default {
          background-color: white;
          border: 1px solid lightgray;
          color: black;

          &:hover {
            background-color: lightgray;
          }
        }
      }
    }
  }
}

// WARNING: This class affects the use of antd modals across the whole platform
.ant-modal-content {
  @media (max-width: $mobile) {
    position: fixed !important;
    top: 0px !important;
    left: 0px !important;
    height: 100vh !important;
    width: 100vw !important;
    overflow-y: auto !important;
  }
}

@media (max-width: $mobile) {
  .modal-wrapper .ant-modal-content {
    padding-bottom: 0 !important;
  }

  .apprentice-profile-modal-wrapper .ant-modal-body {
    max-height: 70dvh;
    overflow-y: auto;
  }

  .modal-wrapper .ant-modal-body {
    max-height: 60dvh;
    overflow-y: auto;
  }
}
