// ANT MODAL
.ant-modal {
  &:not(.default-width) {
    width: fit-content !important;
  }

  .ant-modal-close {
    &:hover {
      background: transparent !important;
    }
  }

  .ant-modal-content {
    border-radius: 10px !important;
  }
  .ant-modal-header {
    border-radius: 10px 10px 0 0;
  }
  &.add-link-modal {
    max-width: 600px !important;
  }
  &.divider-none {
    .ant-modal-header, .ant-modal-footer {
      border: 0;
    }
  }
  &.even-spacing {
    .ant-modal-content {
      display: flex;
      flex-direction: column;
      gap: 24px;
      padding: 24px;
      & > div {
        padding: 0;
      }
    }
  }
}

.ant-modal-close-x-none {
  .ant-modal-close-x {
    display: none;
  }
}
