@import './../variables';

.application-status {
  &.shortlisted {
    @apply text-qureosPink;
  }

  &.offered {
    @apply text-qureosBlueGreen;
  }
}

.certificate-viewer-modal {
  width: 100% !important;
  max-width: 920px;
  padding-top: 87px !important;
}

.project-company-select {
  @apply w-full bg-white rounded-xl p-3 py-6 flex flex-wrap overflow-auto;
  max-width: 1025px;
  max-height: 600px;

  .v2-skill-card {
    @apply mb-6;
  }
}

.project-container {
  @media (max-width: $mobile) {
    padding: 0px !important;
  }

  .project-builder {
    @apply relative overflow-auto pb-16;
    height: calc(100vh - 100px);
    scroll-behavior: smooth;

    .selected-company {
      @apply flex items-center justify-center shadow-lg rounded-lg bg-white px-6 py-4;

      .logo {
        @apply w-16 mr-6;

        img {
          object-fit: scale-down;
        }
      }

      .name {
        @apply text-qureosBlue;
      }
    }

    &.scrolled {
      .project-form {
        &.plain {
          min-width: 808px;
          @apply fixed;
          top: 80px;
          z-index: 11;
          box-shadow: 0px 0px 30px #6a708026;

          @media (max-width: $mobile) {
            min-width: 0px;
            width: calc(100% - 20px);
          }

          .steps-wrapper {
            .step {
              @apply cursor-pointer;
              padding-top: 16px;

              .image {
                overflow: hidden;
                height: 0px;
              }
            }
          }
        }
      }
    }

    .btn {
      &.draft {
        @apply border border-qureosBlue rounded-full px-10 py-3 text-qureosBlue text-2xl;
        background: transparent;
        transition: all 0.3s ease;

        &:hover {
          @apply bg-qureosBlue text-white;
        }
      }

      &.preview {
        @apply rounded-full px-10 py-3 text-white text-2xl ml-4;
        background: #707070;
        transition: all 0.3s ease;
      }
    }
  }
}

.field-label {
  padding-bottom: 10px;
  text-transform: uppercase;
  color: #8898aa;
  font-size: 12px;
}

.project-form {
  @apply w-full rounded-xxl shadow-lg bg-white relative;
  border-left: 13px solid;
  margin-top: 35px;

  &.plain {
    margin-top: 0px;
    border-radius: 0.5rem 0.5rem 0px 0px;
    border: none;
  }

  &.info {
    border-color: #34a5f3;
    margin-top: 0px;
    border-radius: 0px 0px 0.5rem 0.5rem;
  }

  &.benefits {
    @apply border-qureosGreen;

    .picker {
      &.commitment {
        .ant-picker {
          border: 1px solid #ebeef1;
          border-radius: 10px;
          height: 51px;
          top: 1px;
          width: 190px;

          input {
            padding: 10px 8px 8px 10px;
            margin-right: 40px;
            border: none !important;
          }

          .ant-picker-suffix {
            top: -5px;
            position: absolute;
            background: #ebeef1;
            width: 48px;
            height: calc(100% + 10px);
            display: flex;
            align-items: center;
            justify-content: center;
            right: -11px;
            border-top-right-radius: 10px;
            border-bottom-right-radius: 10px;

            .anticon {
              font-size: 15px;
            }
          }
        }
      }
    }
  }

  .steps-wrapper {
    @apply w-full mx-auto flex relative;
    max-width: 920px;

    .step {
      @apply flex-1 flex flex-col justify-between h-full;
      padding-top: 34px;

      .image {
        @apply flex justify-center;
        height: 90px;
        transition: 0.3s all ease-in;

        @media (max-width: $mobile) {
          height: 70px;
        }
      }

      .label {
        @apply flex justify-center text-center text-base font-semibold;
        padding-top: 22px;
        padding-bottom: 24px;

        .index {
          @apply text-2xl font-semibold relative pr-2;
          top: -10px;
        }
      }

      &.active {
        &:first-child {
          .label {
            @apply text-qureosBlue;
          }
        }

        &:nth-child(2) {
          .label {
            @apply text-qureosPurple;
          }
        }

        &:last-child {
          .label {
            @apply text-qureosGreen;
          }
        }
      }

      &:not(.active) {
        .label {
          div {
            &:last-child {
              img {
                height: 26px;
              }
            }
          }
        }
      }
    }
  }

  .separator {
    @apply w-full relative;

    &:after {
      content: ' ';
      position: absolute;
      width: 100%;
      border-bottom: 1px solid #ebeef1;
      bottom: 0;
      left: 0;
    }
  }

  .form-body {
    @apply py-6 px-16 flex flex-col;

    @media (max-width: $mobile) {
      padding: 20px;
    }

    .form-container {
      max-width: 700px;
    }

    .form-group {
      @apply flex flex-col;

      &.contained {
        @apply w-full;
        max-width: 900px;

        .fields-wrapper {
          .field {
            &:not(.w-auto) {
              @apply flex-1;
            }
          }

          &.project-category-dropdown {
            width: 343px;

            .field {
              position: relative;
              z-index: 9;
            }
          }
        }
      }

      &:not(:first-child) {
        padding-top: 25px;
      }

      &:first-child {
        padding-bottom: 35px;
      }

      .label {
        @apply text-qureosSoftBlack font-semibold;
        padding-bottom: 30px;
        font-size: 18px;

        .subtitle {
          @apply font-thin;
          text-transform: initial;
          font-size: 15px;
        }
      }

      .fields-wrapper {
        @apply w-full flex;
        margin-bottom: 25px;

        @media (max-width: $mobile) {
          @apply flex-col;

          .pr-12 {
            padding-right: 0px;
          }

          .field {
            margin-bottom: 10px;
          }
        }

        .field {
          @apply flex flex-col;
          // @apply w-2/6;
          padding-right: 60px;

          input[type='text'],
          input[type='number'] {
            padding: 13px 20px;
          }

          .w1 {
            width: 135px;
          }
          .w2 {
            width: 175px;
          }

          &.cols4 {
            width: 25%;
          }

          &.cols5 {
            width: 20%;
          }

          &.cols6 {
            width: 16%;
          }

          &.mr-sm {
            margin-right: 10px;
          }

          &.no-pads {
            padding: 0px 0px 0px 0px;
          }

          &.editor {
            width: 75%;

            .ckeditor-box {
              position: relative;

              @media (max-width: $mobile) {
                width: calc(100vw - 75px);
              }

              .editor-text-counter {
                display: none;
                position: absolute;
                top: -25px;
                font-size: 12px;
                right: 0px;
                color: #8898aa;
              }

              .ck-content {
                min-height: 200px;
              }
            }
          }

          .inputPicker {
            position: relative;

            &.long {
              input {
                height: 50px;
              }
            }

            .picker-icon {
              position: absolute;
              right: 10px;
              top: 15px;
            }
          }

          input {
            border-radius: 10px;
            background: #fff;
            border: 1px solid #ebeef1;
          }

          .css-yk16xz-control {
            border: 1px solid #ebeef1;
            border-radius: 10px;
          }

          .css-1pahdxg-control,
          .css-1fhf3k1-control {
            height: 50px;
          }

          .ant-switch {
            height: 40px;
            width: 103px;

            .ant-switch-inner {
              @apply text-base uppercase font-semibold;
            }

            .ant-switch-handle {
              width: 35px;
              height: 35px;

              &:before {
                @apply rounded-full;
              }
            }

            &:not(.ant-switch-checked) {
              &:after {
                left: 2px;
              }
            }

            &.ant-switch-checked {
              .ant-switch-handle {
                left: calc(100% - 38px);
              }
            }
          }

          .css-yk16xz-control {
            padding: 6px 0;
          }
        }
      }

      .template-selection {
        border-radius: 10px;
        background: #fff;
        border: 1px solid #ebeef1;
        overflow: hidden;
        padding: 5px;
        width: 100%;
        max-width: 350px;

        .ant-select-selector {
          border: none;
          box-shadow: none !important;

          .ant-select-selection-search-input {
            min-width: 100px;
          }

          .ant-select-selection-item-remove {
            svg {
              margin-top: -4px;
            }
          }
        }
      }
    }

    .project-type-options {
      @apply w-full flex;

      @media (max-width: $mobile) {
        @apply flex-col items-center;
      }

      .option {
        @apply text-base flex items-center justify-center rounded-lg shadow-lg text-mutedBlack font-thin mr-8 cursor-pointer;
        width: 190px;
        height: 90px;

        @media (max-width: $mobile) {
          flex: none;
          margin-bottom: 10px;
        }

        // &.active {
        @apply bg-qureosBlue text-white;
        // }

        // todo: remove
        &:not(.option-flexible_deliverables) {
          color: #838383;
          background-color: #eaeaea;
          cursor: not-allowed;
        }
      }
    }
  }

  .custom-checkbox {
    .item-check {
      margin-right: 10px;

      @media (max-width: $mobile) {
        min-width: 0px;
      }
    }
  }
}

.project-row {
  .red-dot {
    @apply absolute;
    left: 9px;
    top: 15px;
  }

  .project-footer {
    @apply flex bg-white;
    height: 36px;
    border-top: solid 1px #d6dee8;
    padding: 6px 10px;

    .footer-status {
      @apply text-qureosGold font-semibold;
      text-transform: uppercase;
      font-size: 13px;

      &.hired {
        @apply text-qureosGreen;
      }
    }

    .footer-case {
      @apply items-center;

      width: calc(100% / 3);

      .fc-label {
        color: #feb200;
        font-size: 13px;
        font-weight: normal;
        margin-top: -2px;

        &.blue {
          color: #c5e4fd;
        }
      }

      .fc-icon {
        width: 23px;
        height: 23px;
      }
    }

    &.as-rank {
      @apply justify-between;

      .rating {
        @apply font-normal text-sm;
        color: #576271;
        padding-left: 6px;
      }

      .ended-at {
        @apply font-normal text-sm;
        color: #576271;
      }
    }
  }
}

.qrs-task-manager {
  width: 100%;
  // max-width: 600px;
  height: auto;
  // max-height: 620px;
  // box-shadow: 0px 0px 20px #3e44571a;
  // border-radius: 10px;
  padding: 15px;

  &.no-shadow {
    box-shadow: none;
  }

  &.wide {
    .list-table {
      width: 500px !important;
    }
  }

  label {
    margin: 0px;
  }

  .content-highlight {
    .title {
      font-size: 18px;
      @apply text-blackText;
      margin-bottom: 22px;
    }

    .action-btn {
      @apply flex items-center;
      height: 50px;
      background: #d9edfd 0% 0% no-repeat padding-box;
      border-radius: 23px;
      padding: 7px 40px 7px 40px;
      color: #3aa4f3;
      font-size: 22px;

      img {
        margin-right: 10px;
      }
    }
  }

  .task-form {
    position: relative;

    .top-navigation {
      margin-left: auto;

      @media (max-width: $small-mobile) {
        // flex-direction: column;
        width: fit-content;
        padding-right: 20px;
      }

      .navig-btns {
        @media (max-width: $small-mobile) {
          font-size: 13px;
          width: fit-content;
          margin-left: 2px !important;
        }

        @apply flex items-center;
        background: #d9edfd;
        border-radius: 22px;
        padding: 8px 20px 8px 20px;
        color: #3aa4f3;
        font-size: 15px;
        margin-left: 12px;
        cursor: pointer;
        position: relative;
        height: 30px;
        flex: none;

        img {
          margin-right: 8px;
          width: 14px;
        }

        .filter-trigger {
          position: absolute;
          top: 0px;
          left: 0px;
          width: 100%;
          height: 100%;
          opacity: 0;
        }

        &.icon {
          border-radius: 15px;
          padding: 9px 14px 9px 14px;

          img {
            margin: 0px;
          }
        }
      }
    }

    .title {
      @apply text-blackText;
      font-size: 18px;
      margin-bottom: 20px;

      @media (max-width: $small-mobile) {
        font-size: 13px;
        width: 180px;
      }
    }

    .list-container {
      width: 100%;
      position: relative;
      overflow-x: auto;

      .list-table {
        margin-top: 20px;
        width: 542px;
        padding: 0px;

        @media (max-width: $mobile) {
          width: 310px;
        }

        th {
          @apply text-blackText;
          font-weight: normal;
          font-size: 12px;

          img {
            margin-right: 7px;
            display: inline-block;
          }
        }

        .floater {
          width: 310px;
          position: sticky;
          left: 0;
          z-index: 2;
          background: rgba(255, 255, 255, 0.8);

          @media (max-width: $mobile) {
            width: 200;
            text-align: left;
          }

          // @media (max-width: $mobile) {
          .task-box {
            &.warning {
              background-color: #fae2e6;
            }

            &.info {
              background-color: #fff3d8;
            }

            &.success {
              background-color: #ebfbf4;
            }
          }
          // }
        }

        th,
        td {
          padding: 0px;
        }

        thead {
          border: none;
        }

        .head {
          th {
            padding-bottom: 10px;
          }
        }

        .body {
          td {
            padding-bottom: 5px;
            padding-right: 5px;
          }
        }

        .task-box {
          width: 100%;
          height: 33px;
          border: 1px solid #c5e4fd;
          border-radius: 5px;
          overflow: hidden;

          &::before {
            content: '';
            width: 5px;
            background: #3aa4f3;
            display: inline-block;
            height: 100%;
            vertical-align: middle;
          }

          .text-info {
            margin-left: 5px;
            @apply text-blackText;
            font-size: 11px;
          }

          .chat-bubble {
            float: right;
            width: 30px;
            height: 30px;
            padding-left: 6px;
            padding-top: 6px;
            position: relative;

            .counter {
              text-align: center;
              color: #3aa4f3;
              font-size: 10px;
              position: absolute;
              top: 7px;
              width: 100%;
              padding: 0px;
              left: 0px;
            }
          }
        }

        .files-box {
          @apply cursor-pointer text-center flex items-center justify-center;
          width: 45px;
          height: 33px;
          border: 1px solid #c5e4fd;
          border-radius: 5px;
          padding: 0 5px;

          img,
          .counter {
            display: inline-block;
            @apply text-blackText;
            font-size: 10px;
            margin: 3px;
          }
        }

        .status-box {
          width: 82px;
          height: 33px;
          border-radius: 5px;
          text-align: center;
          font-size: 11px;
          color: #ffffff;
          padding: 9px;
          line-height: 0px;

          @media (max-width: $mobile) {
            font-size: 0px;
            width: 40px;

            img {
              display: inline-block !important;
              margin-top: -3px;
            }
          }

          img {
            &.rate-stars {
              display: flex;
              margin: 0px 2px 0px 2px;
              width: 10px;
              height: 10px;

              @media (max-width: $mobile) {
                display: none !important;
              }
            }

            display: none;
            vertical-align: middle;
            margin-right: 5px;
            margin-top: -3px;
          }

          &.not-started {
            background: #79affc;
          }

          &.revise {
            background: #b553c7;
          }
          &.stuck {
            background: #e2445c;
          }
          &.done {
            background: #336ad3;
          }
          &.in-progress {
            background: #ffb100;
          }
          &.completed {
            background: #33d390;

            img {
              @media (max-width: $mobile) {
                margin-left: 5px !important;
              }
            }
          }
        }

        .date-box {
          width: 60px;
          border: 1px solid #c5e4fd;
          border-radius: 5px;
          text-align: center;
          @apply text-blackText;
          font-size: 11px;
          padding: 8px 0;

          &.ontime {
            border-color: #33d390;
            color: #33d390;
          }

          &.overdue {
            border-color: red;
            color: red;
          }

          .completion-label-box {
            @apply font-semibold flex justify-center;

            @media (max-width: $small-mobile) {
              font-size: 9px;
            }

            img {
              width: 12px;
              height: 12px;
              margin-right: 5px;
              align-self: center;
              display: none;
            }
          }
        }

        tbody {
          tr {
            border: none;
          }
        }
      }

      .date-box {
        width: 94px;
        border: 1px solid #c5e4fd;
        border-radius: 5px;
        text-align: center;
        @apply text-blackText;
        font-size: 11px;
        padding: 8px 0;

        &.warning {
          background: white;
          border-color: #ffb100;
          color: #ffb100;
        }

        &.danger {
          border-color: #fa6767;
          color: #fa6767;
          background: white;
        }
      }

      .more-option-box {
        @apply flex items-center justify-center;
        width: 9px;
        height: 35px;
        margin: 2px 0 0 2px;
        .more-options {
          margin-left: 1px;
        }
      }
    }

    .form-cols {
      margin-bottom: 15px;
      clear: both;

      .form-title {
        @apply text-blackText;
        font-size: 13px;
        margin-bottom: 5px;
      }
      .form-input {
        border-radius: 10px;
        border: 1px solid #c5e4fd;
        padding: 10px;
        color: #576271;
        font-size: 13px;
        background: white;
      }

      .input-group {
        position: relative;
        overflow: hidden;
        border-radius: 10px;

        input {
          padding-right: 35px;
          height: 38px;
        }

        .icon {
          position: absolute;
          top: 0px;
          right: 0px;
          cursor: pointer;
          text-align: center;
          width: 30px;
          height: 100%;
          padding: 10px 5px 5px 5px;
          background: #d0e9fd;
        }
      }

      .ck-editor {
        border: 1px solid #c5e4fd;
        border-radius: 10px;

        .ck-toolbar {
          border-color: #c5e4fd;
          background: transparent;
          border-top: none;
          border-right: none;
          border-left: none;
        }
        .ck-content {
          min-height: 150px;
          border: none;
          box-shadow: none;
          border-radius: 0px 0px 10px 10px;
          border-top: solid 1px #c5e4fd;

          ol {
            list-style: decimal;
          }
        }
      }

      .link-item {
        color: #3aa4f3;
        font-size: 12px;
        cursor: pointer;
        display: inline-block;
        border: none;
        box-shadow: none;

        img {
          margin-right: 7px;
          display: inline-block;
        }

        .dropdown {
          @apply bg-white overflow-hidden rounded-lg relative;
          top: 10px;
          box-shadow: 0px 0px 20px #6a708033;
          height: 80px;
          width: 175px;

          .dropdown-item {
            padding: 15px 15px 0px 15px;
            display: block;
            margin: 0px;
            cursor: pointer;
            color: #576271;
          }
        }
      }

      .deadline-picker {
        width: 140px;
        background: #ffffff;
        border: 1px solid #c5e4fd;
        border-radius: 10px;
        position: relative;

        .qrs-datepicker {
          margin-left: -140px;
        }

        input {
          color: #8898aa;
          padding: 9px 17px 9px 10px;
          font-size: 13px;
          background: white;
          border-radius: 10px;
        }

        .icon {
          background: #d0e9fd;
          width: 48px;
          height: 100%;
          position: absolute;
          top: 0px;
          right: 0px;
          padding: 9px 15px 9px 15px;
          border-radius: 0px 10px 10px 0px;
        }

        .picker {
          opacity: 0;
          top: 0px;
          position: absolute;
          left: 0px;

          .ant-picker-clear {
            display: none;
          }
        }
      }

      .files-box {
        padding: 10px;

        .file-item {
          @apply py-2 px-4 rounded-full flex justify-between;
          background: #d9edfd;
          color: #576271;
          font-size: 11px;
          display: inline-block;
          margin: 2px;

          .file-name {
            max-width: 300px;
            padding-right: 4px;
            text-overflow: ellipsis;
            overflow: hidden;
            display: inline-block;
            white-space: nowrap;
            vertical-align: top;
          }

          img {
            display: inline-block;
            cursor: pointer;
          }
        }
      }

      .form-btn {
        background: #3aa4f3;
        border-radius: 23px;
        padding: 5px 18px 5px 18px;
        font-size: 15px;
        cursor: pointer;
        margin-left: auto;

        &.btn-back {
          background: #c05621;
        }
      }
    }
  }
}

.file-viewer {
  @apply bg-white rounded-lg relative;
  width: 215px;
  height: auto;
  box-shadow: 0px 0px 20px #6a708033;

  .files {
    padding: 15px 11px;
    display: inline-block;

    .project-file {
      width: fit-content;
      color: #576271;
      display: flex;
      border-radius: 12px;
      padding: 2px 2px 2px 10px;
      margin-bottom: 7px;

      .remove-icon {
        @apply cursor-pointer;
        width: 11px;
        margin-right: 6px;
      }

      &.company {
        background-color: #ebfbf4;

        .name {
          @apply text-qureosBrightGreen;
        }
      }

      &.user {
        background-color: #d9edfd;

        .name {
          @apply text-qureosBlue;
        }
      }

      .name {
        @apply w-auto;
        padding-left: 5px;
        padding-right: 6px;
        text-overflow: ellipsis;
        max-width: 150px;
        white-space: nowrap;
        overflow: hidden;
      }
    }
  }

  .uploader {
    @apply relative;
    padding: 8px 11px;
    padding-bottom: 8px;

    &.has-file {
      padding: 17px 10px 10px 10px;

      &:before {
        content: ' ';
        border-top: 1px solid #d6dee8;
        width: 100%;
        height: 1px;
        position: absolute;
        left: 0;
        top: 8px;
      }
    }

    .upload-action {
      @apply flex cursor-pointer;
      font-size: 11px;

      img {
        margin-right: 15px;
      }
    }
  }
}

// Global Project UI

.status-box {
  width: 82px;
  height: 33px;
  border-radius: 5px;
  text-align: center;
  font-size: 11px;
  color: #ffffff;
  padding: 9px;
  text-transform: capitalize;

  img {
    display: inline-block;
    vertical-align: middle;
    margin-right: 5px;
    margin-top: -3px;
  }

  &.pending {
    background: #79affc;
  }

  &.revise,
  &.active {
    background: #b553c7;
  }
  &.stuck {
    background: #e2445c;
  }
  &.done,
  &.review {
    background: #336ad3;
  }
  &.inprogress {
    background: #ffb100;
  }

  &.submitted {
    background: #336ad3;
  }
  &.completed {
    background: #33d390;
  }
}

.date-box {
  width: 94px;
  border: 1px solid #c5e4fd;
  border-radius: 5px;
  text-align: center;
  @apply text-blackText;
  font-size: 11px;
  padding: 9px 0px 9px 0px;

  &.warning {
    background-color: #fae2e6;
  }

  &.info {
    background-color: #fff3d8;
  }

  &.success {
    background-color: #ebfbf4;
  }
}

.ad-menu {
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0px 0px 20px #6a708033;
}

.filter-menu-container {
  width: 215px;
  height: 242px;
  box-shadow: 0px 0px 20px #6a708033;
  border-radius: 10px;
  overflow: hidden;
  padding: 8px 10px 8px 10px;
  background: #fff;

  ul {
    box-shadow: none;
    height: 100%;

    li {
      padding: 0px;

      &:hover {
        background: transparent;
      }

      table {
        tr {
          td {
            padding: 8px;
            padding-bottom: 0px;

            .status-box,
            .date-box {
              cursor: pointer;
              width: 77px;
              height: 24px;
              padding: 1px 8px;
              text-align: left;

              &.selected {
                opacity: 0.2;
                background: #000;
                color: #eee;
                border-radius: 0px;
                height: 25px;
              }
            }
          }
        }
      }
    }
  }
}

.thread-menu {
  box-shadow: 0px 0px 20px #6a708033;
  width: 175px;
  border-radius: 10px;
  overflow: hidden;

  img {
    margin-right: 16px;
  }

  li {
    display: flex;
  }
}

.tab-menu {
  li {
    padding: 12px;
    color: #8898aa;
    font-size: 18px;
    cursor: pointer;
  }
}

.status-menu-container {
  border-radius: 10px;
  width: 150px;
  padding: 8px 20px 8px 20px;

  .title {
    @apply text-blackText;
    font-size: 12px;
    margin: 0px 0px 5px 0px;
  }

  .status-box {
    height: 24px;
    margin: 0px 0px 5px 0px;
    border-radius: 5px;
    line-height: 5px;
  }
}

.project-workspace-container {
  .workspace-task-list {
    padding: 0px;
  }
}

.css-26l3qy-menu {
  background: red;
}

.filter-btn {
  background: #d9edfd;
  border-radius: 23px;
  font-size: 15px;
  padding: 5px 14px;
  color: #3aa4f3;
  margin-left: 10px;
  cursor: pointer;

  img {
    margin-top: 6px;
  }
}

.project-applicants-container {
  padding-left: 20px;
  padding-top: 15px;

  .header {
    margin: 0px 0px 25px 0px;
    display: flex;

    .title {
      font-size: 18px;
      @apply text-blackText;
      font-weight: normal;
      margin: 0px;
    }

    .filter {
      margin-left: auto;
    }
  }

  .content {
    display: flex;
    flex-wrap: wrap;

    &.pipeline-scroller {
      max-width: calc(100vw - 200px);
      overflow-x: auto;
      margin-left: -20px;

      @media (max-width: $mobile) {
        max-width: calc(100vw - 100px);
      }

      .pipeline-container {
        width: 1440px;
        display: flex;
      }
    }

    .pipelines {
      width: 278px;
      height: 520px;
      background: #fbfbfb;
      border-top: 1px solid #d6dee8;
      border-radius: 5px;
      overflow: hidden;
      margin: 0px 10px 10px 0px;

      &:before {
        content: ' ';
        display: block;
        width: 100%;
        height: 5px;
        background: #000;
        margin: 0px 0px 20px 0px;
      }

      &.talent-group {
        &:before {
          background: #11cdef;
        }
      }

      &.shortlisted {
        &:before {
          background: transparent
            linear-gradient(91deg, #c781ff 0%, #e57373 100%) 0% 0% no-repeat
            padding-box;
        }
      }

      &.contacted {
        &:before {
          background: transparent
            linear-gradient(91deg, #ff9784 0%, #ffb74d 100%) 0% 0% no-repeat
            padding-box;
        }
      }

      &.offered {
        &:before {
          background: transparent
            linear-gradient(91deg, #9ea7fc 0%, #65b6f7 100%) 0% 0% no-repeat
            padding-box;
        }
      }

      &.hired {
        &:before {
          background: transparent
            linear-gradient(271deg, #81d5ee 0%, #7ed492 100%) 0% 0% no-repeat
            padding-box;
        }
      }

      .title {
        text-align: center;
        font-size: 15px;
        font-weight: 600;
        @apply text-blackText;
        text-transform: uppercase;
        width: 100%;
        display: block;
      }

      .card-holder {
        margin: 20px 0px 0px 0px;
        padding: 0px 0px 0px 7px;
        height: 445px;
        overflow: auto;
        overflow-x: hidden;
      }
    }

    .applicant-item {
      background: #ffffff;
      box-shadow: 0px 0px 20px #3e44571a;
      border-radius: 5px;
      width: 266px;
      height: 138px;
      overflow: hidden;
      display: flex;
      margin: 0px 10px 10px 0px;

      &::before {
        content: ' ';
        height: 100%;
        background: #c5e4fd;
        display: flex;
        flex-basis: 5px;
      }

      .body {
        padding: 10px 0px 10px 10px;
        width: 100%;

        .dp {
          width: 40px;
          height: 40px;
          margin: 0 15px 0px 0px;
          border-radius: 100%;
        }

        .name {
          font-size: 14px;
          font-weight: 600;
          @apply text-blackText;
          flex-basis: 162px;
          padding: 0px;
          display: flex;
          align-items: center;
          height: 45px;
          text-transform: capitalize;
        }

        .shortlist-btn {
          cursor: pointer;
          margin: 0px 10px 0px auto;
          align-self: flex-start;
        }

        .percent-box {
          color: #50d498;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          margin: 0 15px 0px 0px;

          .amount {
            font-size: 18px;
            font-weight: bold;
          }
          .text {
            text-transform: uppercase;
            font-size: 11px;
            font-weight: 600;
          }
        }

        .education-box {
          border-top: 0.5px solid #c5e4fd;
          padding: 5px 0px 0px 0px;
          width: 100%;

          .major {
            font-size: 10px;
            color: #6a7a8b;
            text-transform: uppercase;
            padding: 0px 0px 1px 0px;
          }

          .course {
            font-size: 11px;
            font-weight: 600;
            @apply text-blackText;
            margin: 0px 10px 10px 0px;
          }

          .school {
            font-size: 10px;
            color: #6a7a8b;
          }
        }
      }
    }
  }
}

.project-offer {
  .congrats-icon {
    font-size: 84px;
  }

  .nda {
    margin-top: 28px;
    padding: 0 32px;

    @apply flex flex-col items-start justify-start;

    .title {
      @apply text-black font-semibold text-base;
      margin-bottom: 9px;
    }

    .nda-content {
      @apply rounded-lg w-full overflow-auto text-left;
      border: 1px solid #d0e9fd;
      padding: 13px;
      height: 377px;
    }
  }
}

.offer-action-wrapper {
  @apply w-full flex justify-between;
  padding-left: 34px;
  padding-top: 19px;

  .ant-checkbox-wrapper {
    margin-left: 0;

    span {
      &.ant-checkbox {
        height: 17px;
        margin-top: 4px;

        &.ant-checkbox-checked {
          &:after {
            display: none !important;
          }
        }
      }

      &:not(.ant-checkbox) {
        margin-top: -3px;

        a {
          @apply text-qureosBlue;
        }
      }
    }
  }

  .buttons {
    @apply flex flex-1 justify-end;

    .decline {
      background: transparent;
      @apply border-2 border-qureosBlue text-qureosBlue mr-4;
    }

    button {
      height: fit-content;
    }
  }
}

.coc-modal {
  @apply p-6;

  & > div {
    @apply rounded-lg overflow-auto text-left p-2;
    width: 100vw;
    height: 100vh;
    max-height: 400px;
    border: 1px solid #d0e9fd;
    max-width: 600px;
  }
}

.coc-title {
  @apply text-black font-semibold text-base p-6 pb-1;
}

.project-applicant-notice {
  @apply mx-auto w-full;
  max-width: 737px;

  .content {
    @apply rounded-lg shadow-lg bg-white p-6 text-base flex justify-center;

    .banner {
      @apply flex flex-col items-center justify-center;
    }

    h1 {
      @apply text-base text-center;
    }
  }
}

.project-mentorship {
  &.ask {
    width: 100vw;
    max-width: 700px;

    @apply rounded-lg shadow-lg bg-white p-6;

    .ask-content {
      margin-top: 40px;
      margin-bottom: 40px;
      width: 330px;
      @apply mx-auto;

      .css-yk16xz-control,
      .css-1pahdxg-control {
        border-radius: 20px;
      }

      .title {
        margin-bottom: 35px;
        @apply text-black font-semibold text-center;
      }

      textarea {
        @apply rounded-xl;
        background: #f7f7f7;
        color: #999ea5;
        padding: 9px 14px;
        resize: none;
      }

      .uploader {
        padding-top: 8px;

        .add-files {
          @apply flex;

          img {
            margin-right: 7px;
          }

          span {
            @apply text-qureosBlue;
          }
        }

        .files {
          @apply flex flex-col;

          .file {
            margin-top: 13px;
            @apply flex;

            a {
              @apply px-4;
            }

            .remove-file {
              @apply cursor-pointer;
              width: 11px;
            }
          }
        }
      }
    }

    .button {
      @apply bg-qureosBlue rounded-lg text-white flex justify-center;
      padding: 9px 25px;
    }
  }

  .tab {
    @apply flex justify-center;

    a {
      @apply relative;
      color: #d6dee8;

      &:before {
        content: ' ';
        height: 6px;
        @apply w-full rounded-full bg-qureosGray absolute;
        bottom: -16px;
      }

      &.active {
        @apply text-qureosBlue;

        &:before {
          @apply bg-qureosBlue;
        }
      }
    }
  }
}

.workspace-chat {
  .challenge-session {
    margin-top: 0;
  }
}

.mentorship-action {
  @apply w-full flex justify-between mx-auto;
  max-width: 540px;
}

.mentorship-button {
  @apply w-full mt-4 rounded-lg shadow-lg bg-white flex overflow-hidden;
  max-width: 574px;

  .details {
    @apply flex-1 p-4 flex;
    height: 100px;

    img {
      @apply rounded-full;
      width: 40px;
      margin-right: 16px;
    }
  }

  .title {
    @apply text-xl text-qureosBlue font-semibold flex items-center justify-center;
  }

  .as-button {
    @apply bg-qureosBlue text-white p-1 items-center justify-center flex text-base font-semibold;
    width: 116px;
  }

  &.with-mentor {
    .details {
      @apply flex-col;
      padding-top: 10px;

      .title {
        @apply text-left justify-start uppercase;
        margin-bottom: 10px;
      }

      img {
        height: 40px;
      }

      .mentor-info {
        .name {
          @apply text-base font-semibold;
        }

        .location {
          @apply flex text-sm items-center;

          img {
            width: 14px;
            height: 14px;
            margin-right: 6px;
          }
        }
      }
    }
  }
}

.v2-skill-card {
  .as-button {
    @apply absolute h-full flex items-center justify-center;
    right: 0;
    width: 28px;
    top: 0;
  }
}

.project-slider {
  .qureos-slider {
    .slider-content {
      height: 263px;

      .project-row {
        margin-top: 15px;
      }
    }
  }
}

.completed-project-label {
  @apply w-full flex items-center justify-center uppercase;
  color: #50d498;

  &:not(.hired) {
    color: #fa6767;
  }
}

@media (max-width: $mobile) {
  .workspace-chat {
    // padding-top: 190px !important;

    .challenge-session {
      padding-bottom: 100px;
      height: calc(100% - 300px);
    }
  }

  .mentor-profile-box {
    display: none !important;
  }

  .messages-v2 {
    height: calc(100vh - 220px) !important;
  }

  .chat-wrapper {
    margin-top: 20px;
  }
}

.project-footer {
  @apply w-full text-center;
  color: #feb200;

  &.draft {
    @apply text-qureosBlue;
  }

  &.approved {
    color: #fb6767;
  }
}

.action-btn-wrapper {
  width: 150px;

  .action-btn {
    @apply w-full flex items-center justify-center;

    &.outline {
      @apply font-normal border border-qureosBlue;
      background-color: transparent;
    }
  }

  &.custom {
    background: #d9edfd;
  }
}

.btn-v2 {
  @apply w-full flex;

  .btn {
    @apply text-qureosBlue py-2 text-center rounded-full mr-3;
    min-width: 142px;
    width: auto;
    background-color: #d9edfd;

    &.active {
      @apply bg-qureosBlue;
      color: white;
    }
  }
}

.project-badge-wrapper {
  @apply w-full flex px-3;
  margin-top: -2px;
}

.rate-task {
  padding-top: 15px;

  .star-container {
    margin-bottom: 7px !important;
  }

  .rate-title {
    @apply text-qureosBlack;
    width: 100px;
  }
}

.dashboard-template {
  &:not(.sidebar) {
    .project-builder {
      &.scrolled {
        .project-form {
          &.plain {
            max-width: calc(100% - 152px);
          }
        }
      }
    }
  }

  .project-builder {
    &.scrolled {
      .project-form {
        &.plain {
          max-width: calc(100% - 272px);

          @media (max-width: $mobile) {
            max-width: 100% !important;
          }
        }
      }
    }
  }
}

.revise-actions {
  @apply w-full flex justify-between items-center;
  padding-top: 10px;

  .ant-picker {
    border-radius: 10px;
    height: 50px;
    border-color: #d0e9fd;

    .ant-picker-input {
      margin-top: -7px;

      input {
        padding-top: 6px;
      }
    }
  }

  .deadline-picker {
    width: 140px;
    background: #ffffff;
    border: 1px solid #c5e4fd;
    border-radius: 10px;
    position: relative;

    .qrs-datepicker {
      margin-left: -140px;
    }

    input {
      color: #8898aa;
      padding: 9px 17px 9px 10px;
      font-size: 13px;
      background: white;
      border-radius: 10px;
    }

    .icon {
      background: #d0e9fd;
      width: 48px;
      height: 100%;
      position: absolute;
      top: 0px;
      right: 0px;
      padding: 9px 15px 9px 15px;
      border-radius: 0px 10px 10px 0px;
    }

    .picker {
      opacity: 0;
      top: 0px;
      position: absolute;
      left: 0px;

      .ant-picker-clear {
        display: none;
      }
    }
  }
}

.slider-wrapper {
  @apply w-full;

  .slider-content {
    .card {
      flex: none;
    }
  }

  @media (max-width: $mobile) {
    @apply px-0;

    .generic-slide-container {
      padding-left: 0;

      .slider-case {
        .slider-body {
          .slider-content {
            max-width: 100%;

            .card {
              flex: none;
            }
          }
        }
      }
    }
  }
}

.popular-template-container,
.wizard-category-container {
  margin-left: -52px;

  @media (max-width: $mobile) {
    margin-left: -20px;
  }

  @media (max-width: $small-mobile) {
    margin-left: -30px;
  }

  .simple-slider {
    height: 175px;

    .slider-body {
      width: calc(100vw - 445px);

      @media (max-width: $mobile) {
        width: calc(100vw - 50px);
      }

      @media (max-width: $small-mobile) {
        width: 100%;

        .slider-content {
          max-width: calc(100vw - 160px);
        }
      }
    }
  }

  .box-selection-container {
    @apply flex;
    height: 120px;

    .box-selection {
      @apply flex flex-col overflow-hidden cursor-pointer;
      width: 220px;
      height: 90px;
      border-radius: 10px;
      background: #6a7a8b;
      padding: 1px;
      margin-right: 15px;
      margin-bottom: 15px;
      flex: none;

      &::before {
        @apply flex w-full;
        content: ' ';
        height: 10px;
        transition: 0.3s all ease-in-out;
        overflow: hidden;
      }

      &.active {
        &::before {
          height: 0px;
        }

        .box-body {
          background: transparent;

          .title,
          .sub-title {
            color: white;
          }
        }
      }

      .box-body {
        @apply flex flex-col bg-white w-full justify-center items-center;
        height: 80px;
        border-radius: 0px 0px 10px 10px;

        .title {
          @apply font-bold text-qureosBlack;
          font-size: 15px;
          text-align: center;
          // padding: 10px;
        }

        .sub-title {
          @apply text-qureosBlack font-light;
          font-size: 14px;
        }
      }
    }
  }

  .template-selection-container {
    @apply flex flex-wrap;
    margin-top: 25px;

    .template-selection {
      @apply flex flex-col overflow-hidden cursor-pointer;
      width: 400px;
      height: 145px;
      border-radius: 10px;
      background: #6a7a8b;
      padding: 1px;
      margin-right: 15px;
      margin-bottom: 15px;
      flex: none;

      &::before {
        @apply flex w-full;
        content: ' ';
        height: 10px;
        transition: 0.3s all ease-in-out;
        overflow: hidden;
      }

      &.active {
        &::before {
          height: 0px;
        }

        .template-body {
          background: transparent;

          .title,
          .description {
            color: white;
          }
        }
      }

      .template-body {
        @apply flex flex-col bg-white w-full;
        height: 163px;
        border-radius: 0px 0px 10px 10px;
        padding: 15px 15px 0px 15px;

        .title {
          @apply font-semibold text-qureosBlack;
          font-size: 15px;
          margin-bottom: 12px;
        }

        .description {
          color: #576271;
          font-size: 15px;
          // white-space: nowrap;
          height: 50px;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        .action-btns {
          @apply flex justify-center;
          margin-top: 10px;

          .btn {
            @apply flex justify-center bg-qureosBlue cursor-pointer;
            width: fit-content;
            min-width: 50px;
            padding: 6px;
            border-radius: 12px;
            color: white;
            margin-right: 10px;

            &.view {
              background: white;
              border: solid 1px #34a5f3;
              color: #34a5f3;
            }
          }
        }
      }
    }
  }
}

// Quick Projects & Tasks

.quick-workspace-container {
  @apply flex w-full;
  padding: 15px 25px;
  max-width: 900px;
  min-height: 500px;
  background: white;
  border-radius: 10px;
  box-shadow: #3e44571a 0 0 20px;

  @media (max-width: $small-mobile) {
    margin-top: 20px;
    padding: 10px;
  }

  .list-table {
    width: 100% !important;

    .floater {
      width: 100% !important;
    }
  }
}

.tab-modal {
  .tab-content {
    padding: 0px !important;

    .content-title {
      @apply font-semibold;
      font-size: 18px;
      margin-bottom: 10px;
    }

    .content-desc {
      textarea {
        resize: none;
        min-height: 200px;
      }

      .edit-btn {
        @apply flex bg-qureosBlue cursor-pointer justify-center;
        width: fit-content;
        min-width: 50px;
        padding: 2px;
        border-radius: 5px;
        color: white;

        &.gray {
          background: #888;
        }
      }
    }
  }
}

.template-preview-popup {
  @apply flex flex-col;

  .header-title {
    @apply flex text-qureosBlue font-semibold;
    font-size: 16px;
  }

  hr {
    @apply flex w-full;
    margin: 10px 0px;
    border-bottom: solid 1px #eaeaea;
  }
}

.new-student-profile-container {
  .profile-details-section {
    .box-container {
      padding: 20px 15px;

      .item-box {
        & > .title {
          @apply text-blackText font-semibold;
          font-size: 15px;
          margin-bottom: 15px;
        }

        & > .body {
          padding-top: 15px;
        }
      }
    }

    .badge-container {
      @apply flex flex-wrap;

      .proficiency-badge-container {
        flex: none;
        margin-right: 15px;
        margin-bottom: 15px;
      }
    }
  }
}

.payable-task-wrapper {
  @apply w-full;
  padding: 20px 15px;

  .payable-task {
    @apply w-full;

    th {
      @apply border-b text-qureosBlue font-thin uppercase py-2;
      border-color: #c5e4fd;
    }

    tr {
      td {
        @apply border-b py-2;
        border-color: #c5e4fd;
      }
    }
  }

  .payment {
    .cards {
      @apply w-full flex flex-wrap;
    }
  }
}
