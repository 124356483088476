@import './variables';

.typeahead {
  @apply w-full relative;

  .chevron {
    display: none;
    position: absolute;
    top: 18px;
    right: 18px;
    transform: rotate(89deg);
    transition: all 0.3s ease;
  }

  .suggestions {
    @apply bg-gray-200 absolute z-10 shadow-xl;
    width: calc(100% + 15px);
    top: calc(100% + 4px);
    left: -4px;

    li {
      @apply p-2 border-gray-300;
      border-top-width: 1px;
      list-style: none;
      cursor: pointer;
      transition: all 0.3s ease;

      &:hover {
        @apply bg-white;
      }
    }
  }

  &.pretend {
    .chevron {
      display: block;
    }

    // .suggestions {
    //   display: none;

    //   &:hover,&:focus-within {
    //     display: block;
    //   }
    // }

    // &:focus-within {
    //   .suggestions {
    //     display: block;
    //   }
    // }
  }

  &:focus-within {
    .chevron {
      transform: rotate(-89deg);
    }

    .suggestions {
      display: block;
    }
  }

  .suggestions {
    &.focused {
      display: block;
    }
  }

  &.disabled {
    &:before {
      @apply rounded-lg;
      content: ' ';
      width: 100%;
      position: absolute;
      height: 100%;
      background: #f2f2f2;
      cursor: not-allowed;
    }

    .suggestions {
      display: none;
    }
  }
}
