@import './variables';

.qrs-main-content {
  &.job-page {
    padding-top: 60px;
    padding-left: 210px !important;
    padding-right: 0;

    @media (max-width: $mobile) {
      padding-left: 0px !important;
    }

    .slider-case {
      .slider-header {
        padding-left: 25px;
      }

      .slider-content {
        padding-left: 25px;
      }
    }
  }
}

.job-templated {
  @apply px-4 w-full pr-0;

  max-width: 1075px;

  @media (max-width: $mobile) {
    padding-left: 0px;
  }
}

.job-widget-wrapper {
  @apply flex-1 pl-6 w-full;

  &:first-child {
    @apply pl-0;
  }

  @media (max-width: $tablet) {
    @apply pl-0 pb-4 flex flex-wrap justify-center;
  }

  &.company {
    &.quick {
      flex: none;
      width: 100%;

      .job-widget {
        @apply w-full relative;
        height: 310px;
        max-width: 700px;

        @media (max-width: $tablet) {
          max-width: 100%;
        }

        &:before {
          content: ' ';
          @apply w-full absolute;
          height: 10px;
          background: #3aa4f3;
          top: 0;
          left: 0;
        }

        .widget-header {
          background: white;

          .header-title {
            @apply text-black;
            padding-top: 11px;
          }
        }

        .job-widget-content {
          padding-bottom: 25px;

          .company-brief {
            height: 200px;
            overflow: auto;
          }
        }
      }
    }
  }
}

.job-widget {
  @apply shadow-lg;
  width: 337px;
  border-radius: 10px;
  overflow: hidden;
  background: white;

  @media (max-width: $tablet) {
    max-width: initial;
    width: 100%;
    margin: 0;
  }
  @media (max-width: $mobile) {
    max-width: initial;
    width: 100%;
    margin: 0px 20px;
  }

  .widget-header {
    background: #3aa4f3;

    &.big {
      min-height: 140px;
      max-height: 140px;
    }

    .company-logo {
      @apply rounded-lg bg-white;
      width: 100px;
      height: 100px;
    }

    .skill,
    .header-title {
      @apply font-semibold;
      font-size: 18px;
      color: white;
    }

    .type,
    .location {
      font-size: 15px;
      color: white;
    }
  }

  .separator {
    @apply w-full border-b py-2;
    border-color: #ebeef1;
  }

  .tag {
    @apply rounded-full;
    padding: 5px 10px;
    border: 1px solid #576271;
    color: #576271;
    margin-bottom: 11px;
    font-size: 13px;
    margin: 5px 5px 5px 0px;

    &:not(:first-child) {
      margin-right: 10px;
    }

    &.filled {
      border: 1px solid transparent;
      background-color: #f5f6f7;
    }
  }
}

.grain-info {
  @apply flex flex-col;

  .title {
    color: #6a7a8b;
    font-size: 15px;
  }

  .value {
    color: black;
    font-size: 15px;
  }

  .action-btn {
    font-size: 15px;
    font-weight: 600;
    color: #3aa4f3;
    height: 36px;
    background: #f2f9ff;
    border-radius: 18px;
    text-align: center;
    padding: 8px 15px;
  }

  .info-counter {
    font-size: 10px;
    color: #100e0e;
    margin-right: 18px;

    &.mini {
      color: #3aa4f3;

      strong {
        font-size: 14px;
        margin-top: 1px;
      }
    }

    strong {
      font-size: 15px;
      font-weight: 600;
      text-align: center;
    }
  }
}

.job-title {
  font-size: 18px;

  .apply-button-wrapper {
    @apply w-full flex justify-center pt-2;

    .apply-button {
      box-shadow: 0px 3px 6px #2c28281c;
      padding-top: 4px;
      display: none;
    }
  }

  @media (max-width: $mobile) {
    @apply flex-col;
    width: 100%;
    margin: 0 auto;

    .apply-button {
      @apply mx-auto mt-4;
    }
  }
}

.job-details-wrapper {
  @apply w-full pr-3;

  @media (max-width: $mobile) {
    @apply pr-0;
  }
}

.job-tabs {
  @apply w-full mt-6;

  @media (max-width: $mobile) {
    @apply mt-0;
    width: 100%;
    padding: 0px 20px;

    .company-details {
      & > div:first-child {
        @apply flex flex-col items-center;
        width: 100% !important;
        padding-right: 0 !important;
        margin-right: 0 !important;
      }

      .ml-24 {
        margin-left: 0 !important;
      }
    }
  }
}

.job-page-wrapper {
  @apply w-full;
  margin-top: 35px;

  &.public {
    padding-top: 0;
    margin-top: 20px;
  }

  .applied {
    padding-top: 30px;
  }

  @media (max-width: $mobile) {
    margin-top: 15px;

    .applied {
      @apply w-full flex justify-center;
    }

    &.public {
      @apply px-0;
    }
  }

  .job-wrapper {
    @apply w-full flex;
    height: 387px;

    &.quick {
      height: auto;

      .job-widget-content {
        border-top: 1px solid #ebeef1;
      }
    }

    .job-widget-content {
      @apply bg-white overflow-auto pb-16;
      max-height: 387px;

      .content-box {
        padding: 20px 20px 0px;

        .header-title {
          margin-bottom: 13px;
          display: block;
        }
      }

      .compensation-box {
        display: flex;

        .label {
          color: #feb200;
          font-size: 15px;

          &.color-2 {
            color: #50d498;
          }
        }

        .icon {
          height: 23px;
          width: 23px;
        }

        .currency-tag {
          width: auto;
          height: 20px;
          background: #50d498;
          border-radius: 5px;
          font-size: 12px;
          font-weight: bold;
          padding: 2px 4px 2px 4px;
          color: white;
          text-align: center;
          margin-right: 3px;
          margin-top: 1px;
        }

        .switcher {
          margin: -3px 0px 0px 7px;
        }
      }
    }

    @media (max-width: $tablet) {
      min-height: 387px;
      height: auto;

      .job-widget-content {
        max-height: initial;
      }
    }

    .fragments {
      @apply flex flex-wrap w-auto py-4 pr-8 h-16;

      .fragment {
        @apply h-16 flex items-center;
      }
    }

    .job-header {
      @apply flex justify-between items-center;

      @media (max-width: $mobile) {
        @apply flex-col;

        & > div {
          @apply mt-4 mr-0;
        }
      }
    }

    .improve-application {
      @apply text-lg uppercase mt-16 mb-6;

      @media (max-width: $mobile) {
        @apply pt-8;
      }
    }

    .job-skills-wrapper {
      @apply w-2/3 pl-3;

      @media (max-width: $mobile) {
        @apply w-full px-0;
      }

      .job-skills {
        @media (max-width: $mobile) {
          .apply-button {
            display: none;
          }
        }

        .job-skill {
          @apply flex items-center px-2;
          border-bottom: 1px solid #e9ecef;
          height: 50px;
          margin-bottom: 13px;
          padding-bottom: 13px;

          @media (max-width: $mobile) {
            .name {
              @apply flex-1;
              font-size: 0.875rem !important;
            }

            .line {
              margin-right: 6px !important;
              margin-left: -8px !important;
            }
          }

          .line {
            @apply w-1 mr-6 ml-2;
            height: inherit;
          }

          .name {
            @apply w-2/4 text-base uppercase font-semibold;
          }

          .level {
            @apply w-1/4 text-base font-thin;
          }

          .button {
            @apply w-1/4 flex justify-end;
          }
        }
      }
    }
  }
}

.apply-job-popup {
  @apply py-4 px-8;
  width: 100%;
  max-width: 700px;

  @media (max-width: $mobile) {
    .text-2xl {
      @apply text-xl;
    }

    textarea {
      width: 100%;
    }
  }

  &.set-height {
    .ant-modal-body {
      height: calc(100vh - 200px);
    }
  }
}

.job-card-shadow {
  box-shadow: 0px 0px 30px #3e445726;
}

.apply-button {
  @apply bg-qureosBlue text-white flex items-center justify-center rounded px-3;
  min-width: 134px;
  height: 40px;
  box-shadow: 0px 3px 6px #2c28281c;

  &:hover {
    cursor: pointer;
    background: #1e96e8;
    color: white !important;
  }
}

.job-action-button {
  background: transparent;
  border: solid 1px $qrs-blue;
  color: $qrs-blue;
  padding: 8px 16px 8px 16px;
  border-radius: 5px;
  box-shadow: 0px 3px 6px #2c28281c;

  @media (max-width: $mobile) {
    padding: 5px 10px 5px 10px;
  }
}

.title-wrapper {
  @apply w-full py-6 -mt-8 flex justify-between items-center;
}

@media (max-width: $mobile) {
  .title-wrapper {
    @apply px-5 flex-col flex-col-reverse;
    margin-top: -20px;

    .apply-button {
      @apply mb-3;
      align-self: flex-end;
    }

    @media (max-width: $almostMobile) {
      // width: 337px;
      @apply mx-auto;
      width: 100%;
      padding-left: 20px;
      padding-right: 20px;
    }
  }

  .job-wrapper {
    @apply flex flex-wrap flex-col;

    .fragments {
      @apply h-auto;

      .fragment {
        @apply h-auto w-full pb-4;

        & > div:last-child {
          display: none;
        }
      }
    }

    .job-skills-wrapper {
      @apply w-full pl-0 pr-2 pt-4;
    }
  }

  .company-details {
    @apply flex-col;

    & > div:first-child {
      @apply w-full border-0;
    }

    & > div:last-child {
      @apply mt-4;
    }
  }
}

@media (max-width: $tablet) {
  .job-wrapper {
    @apply flex flex-wrap flex-col;

    .fragments {
      @apply h-auto;

      .fragment {
        @apply h-auto w-full pb-4;

        & > div:last-child {
          display: none;
        }
      }
    }

    .job-skills-wrapper {
      @apply w-full pl-0 pr-2 pt-4;
    }
  }
}

.verified-project-badge {
  position: absolute;
  top: 17px;
  right: 10px;
}

.job-logo {
  @apply bg-white rounded-lg flex items-center justify-center relative p-3;
  width: 152px;
  height: 73px;
  box-shadow: 0px 3px 6px #3e44571a;
  top: -10px;

  img {
    height: 100%;
    object-fit: scale-down;
  }

  &.plain {
    background: none;
    padding: 10px;
    box-shadow: none;
    margin-top: 20px;

    img {
      @apply rounded-lg;
    }
  }
}

.qrs-job-card {
  width: 300px !important;
  height: 300px;
  position: relative;
  top: -14px;
  display: inline-block;

  .company-logo {
    object-fit: scale-down;
    padding: 10px;
    width: 120px;
    height: 120px;
  }

  .job-title {
    margin-top: 30px;
    font-size: 16px;
    font-weight: 600;
    height: 30px;
    margin-bottom: 20px;
  }

  .job-divider {
    margin-bottom: 10px;
  }

  .job-subtitle {
    font-size: 15px;
    font-weight: 600;
  }

  .job-type-title {
    @apply text-base font-semibold;
    padding-top: 3px;
  }

  .lock-logo {
    margin-left: -12px;
  }
}

.widget-header {
  .company-logo {
    object-fit: scale-down;
  }
}

.qrs-wrapper-no-padding {
  @apply w-full;

  &.menu-open {
    padding-left: 200px;
  }

  padding-left: 80px;
  padding-top: 100px;

  @media (max-width: $mobile) {
    @apply px-0;
  }
}

.job-rank-container {
  max-width: 768px;
  width: 100%;
  @apply mx-auto;

  .profile-badge-box {
    display: inline-block;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 41px;

    .profile-strength {
      width: 41px;
      height: 41px;
      position: absolute;

      .progress-bar-content {
        display: none;
      }
    }

    .profile-dp {
      width: 41px;
      height: 41px;
    }
  }

  @media (max-width: $mobile) {
    padding: 10px;

    .qrs-widget-box {
      margin-right: 0px;
    }
  }

  .rank-box {
    padding: 10px;
    @apply pb-8 rounded-lg;
    box-shadow: 0px 0px 30px #6a70801a;
    min-height: 300px;
    background: #fff;

    .banner {
      box-shadow: none;
      text-align: center;
      margin-top: 20px;

      .logo {
        margin: 0 auto;
        margin-bottom: 10px;
      }

      .text-head,
      .text-subhead {
        font-size: 15px;
        width: 350px;
        font-weight: normal;
        color: #1a1e2c;
        margin: 0 auto;

        @media (max-width: $mobile) {
          width: 100%;
        }
      }

      .text-subhead {
        font-weight: 600;
        margin-top: 10px;

        .percent-value {
          color: #4e9ff3;
          font-weight: 600;
          font-size: 20px;
        }
      }

      .take-challenge-button {
        @apply mt-6 bg-qureosBlue text-white rounded-full px-6 py-2 flex mx-auto;
        max-width: 180px;

        &:hover {
          @apply text-white;
        }
      }
    }

    .body-content {
      margin-top: 20px;
      text-align: center;

      .job-boost-box {
        width: 100%;
        max-width: 685px;
        display: inline-block;
        text-align: left;
        padding-left: 15px;

        .qrs-widget-box {
          margin: 0px;
          margin-bottom: 10px;
          margin-right: 15px;
        }

        @media (max-width: $mobile) {
          text-align: center;
        }
      }
    }
  }
}

.jobs-wrapper {
  @apply -mt-8 overflow-auto w-full flex py-4;
  scroll-behavior: smooth;
}

@media (max-width: $mobile) {
  .jobs-wrapper {
    padding-left: 7px;
  }
}

.job-skill-modal {
  height: auto;
  width: 600px;
  padding: 20px;

  @media (max-width: $mobile) {
    width: 100%;
  }

  .title {
    @apply font-semibold;
    font-size: 18px;
    color: #1a1e2c;
    margin-bottom: 20px;
    text-align: center;
  }

  .skills {
    text-align: center;
  }
}

.job-form-modal {
  max-width: 950px;
}

.job-applicants-modal {
  max-width: 1050px;
}
