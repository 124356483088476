@import './variables';

// Drawer Innert Items Customization

.qrs-right-drawer {
  // @media (min-width: $small-mobile) {
  // COHORT
  .cohort-bar {
    height: auto;
    padding: 10px 0px;
    margin-bottom: 15px;
    flex: none;

    .details-box {
      &.incomplete {
        background: transparent;

        & > .title {
          color: white;
        }

        .text-box {
          .text {
            color: white;
          }
        }
      }

      .right-bar {
        @apply flex flex-col;
        margin-left: 20px;
        margin-top: 10px;
        margin-bottom: 10px;
      }

      .status-box {
        display: none;
      }

      .progress-box {
        .title {
          font-size: 15px;
        }
      }
      .time-box {
        .value {
          font-size: 15px;
        }
      }
    }
  }

  .module-box {
    max-height: none;
    height: auto;
    flex: none;

    .module-details {
      @apply flex-wrap;

      .details-header {
        flex-wrap: wrap;

        .details-item {
          width: 100% !important;
          max-width: 100% !important;
          flex: none;
          border-bottom: solid 1px $qrs-gray;

          &.with-line {
            border-right: none !important;

            .arrow-flow {
              right: auto !important;
              left: 50% !important;
              transform: translateX(-50%) rotate(90deg) !important;
              bottom: -10px !important;
              top: auto !important;
            }
          }
        }
      }
    }
  }

  .drawer-action-box {
    &.cohort {
      margin-top: 20px;

      .action-btn {
        @apply flex w-full justify-center items-center cursor-pointer font-semibold;
        font-size: 15px;
        background: $qrs-blue;
        padding: 7px 0px;
        height: 35px;

        &.warning {
          background: #ffb100;
        }

        &.danger {
          background: #ffb100;
        }
      }
    }
  }
  // END COHORT
  // }
}
