@import './variables';

.competition-wrapper {
  @apply mx-auto;
  max-width: 1060px;
  padding-bottom: 20px;
}

.cohort-index {
  background: #fff;
  min-height: 100vh;
  height: auto;
}

.cohort-preview-company-image {
  max-height: 50px;
  max-width: 50%;
}

.cohort-container {
  @apply flex flex-col w-full h-auto;
  max-width: $qrs-col-9;
  margin: 0 auto;

  @media (max-width: $mobile) {
    padding: 10px;
  }

  .cohort-step {
    @apply flex flex-col w-full;
    margin-top: -5px;

    .cohort-step-header {
      @apply flex;
      height: 25px;

      .step-bullet {
        @apply flex self-end;
        background: $qrs-blue;
        border-radius: 20px;
        width: 20px;
        height: 20px;
        margin-right: 30px;

        @media (max-width: $small-mobile) {
          margin-right: 5px;
          border-radius: 10px;
          width: 10px;
          height: 10px;
          align-self: center;
        }

        &.empty {
          background: 0px;
          border: solid 3px $qrs-blue;
        }
      }

      .step-title {
        @apply flex font-semibold text-blackText;
        font-size: 18px;
      }
    }

    .cohort-step-body {
      @apply flex flex-col w-full;
      border-left: solid 2px $qrs-blue;
      margin-left: 9px;
      padding: 10px 40px;

      @media (max-width: $small-mobile) {
        padding: 10px !important;
        margin-left: 5px;
        border-left: solid 1px $qrs-blue;
      }

      .body-container {
        max-height: 0px;
        height: auto;
        transition: all 0.5s ease-in;
        overflow: hidden;

        .alert-box {
          @apply flex font-semibold;
          margin-top: 20px;
          font-size: 16px;
        }
      }

      &.no-border {
        border-left: none;
      }

      .sub-title {
        @apply flex;
        font-size: 15px;
        color: #505f70;
      }

      .cohort-table {
        margin-top: 20px;
      }

      .module-steps-container {
        margin-top: 25px;
      }
    }

    &.open {
      .cohort-step-body {
        padding: 25px 40px;

        &.with-subtext {
          padding-top: 3px;
        }

        .body-container {
          max-height: 9999px;
        }
      }
    }
  }

  .cohort-table {
    @apply flex flex-col w-full h-auto;
    min-height: 75px;
    border-radius: 10px;
    overflow: hidden;
    border: solid 1px #a7b2c0;

    .table-row {
      display: flex !important;
      @apply w-full justify-between flex-wrap;
      border-top: solid 1px $qrs-gray;
      min-height: 75px;
      height: auto;
      padding: 15px;

      &.no-line {
        border-top: none;
      }

      &:first-child {
        border-top: none;
      }

      .table-col {
        @apply flex flex-col;
        flex: none;
        min-width: $qrs-col-1;

        @media (max-width: $desktop-1) {
          margin-bottom: 10px;
        }

        @media (max-width: $small-mobile) {
          width: 100%;

          &.button-col {
            @apply items-center;
          }
        }

        .label {
          @apply font-light;
          font-size: 15px;
          color: #6d7a89;
          margin-bottom: 5px;
        }

        .value {
          @apply flex font-semibold;
          font-size: 15px;
          color: #505f70;
        }

        &.button-col {
          @apply justify-center;
        }
      }
    }

    .table-button {
      @apply flex justify-center items-center;
      height: 32px;
      width: fit-content;
      cursor: pointer;
      padding: 6px 12px;
      font-size: 15px;
      color: $qrs-blue;
      border: solid 1px $qrs-blue;
      border-radius: 15px;

      &:hover,
      &.active {
        background: $qrs-blue;
        color: white;
      }
    }
  }

  .cohort-footer {
    @apply flex w-full;
    padding: 25px 0px;
  }

  .cohort-btn {
    @apply flex justify-center items-center;
    height: 32px;
    width: fit-content;
    cursor: pointer;
    padding: 6px 24px;
    font-size: 15px;
    background: $qrs-blue;
    color: white;
    border-radius: 15px;

    &:disabled {
      background: $qrs-gray;
    }
  }

  .module-steps-container {
    @apply flex flex-wrap;
    margin-bottom: 25px;
  }

  .banner-msg-container {
    @apply flex flex-col w-full;
    max-width: $qrs-col-4;
    border-radius: 10px;
    border: solid 1px #d8dee6;
    overflow: hidden;
    margin: 0 auto;

    .banner-title {
      @apply flex justify-center items-center text-blackText;
      border-bottom: solid 1px #d8dee6;
      padding: 15px 0px;
      font-size: 15px;
    }

    .banner-body {
      @apply flex flex-col justify-center items-center;
      padding: 12px;

      .icon {
        margin-bottom: 13px;
      }

      .text {
        color: #576271;
        font-size: 15px;
        margin-bottom: 5px;
      }

      .cohort-btn {
        margin: 25px 0px;
      }
    }
  }
}

.cohort-application-container {
  @apply flex flex-col w-full justify-center items-center;
  max-width: 675px;
  margin: 0 auto;

  @media (max-width: $mobile) {
    padding: 10px;
  }

  .application-container {
    @apply flex flex-col w-full;
    border-radius: 10px;
    border: solid 1px #d7dee7;
    background: white;
    min-height: 50px;
    overflow: hidden;

    .application-banner {
      @apply flex flex-col w-full items-start;
      height: 196px;
      background: $qrs-blue;
      padding: 15px;

      @media (max-width: $small-mobile) {
        align-items: center;
      }

      .logo {
        margin-bottom: 15px;
        width: 180px;
      }

      .banner-txt {
        @apply flex font-light justify-center w-full;
        color: white;
        font-size: 35px;
      }
    }

    .application-header {
      @apply flex font-semibold w-full justify-center text-blackText;
      font-size: 18px;
      margin-top: 25px;
      margin-bottom: 15px;
      padding: 15px;
    }

    .application-body {
      padding: 15px;

      textarea {
        resize: none;
      }
    }

    .application-question {
      @apply flex flex-col;

      &.lefty {
        .question-title {
          justify-content: flex-start;
        }

        .question-options {
          margin: 10px;
        }
      }

      .question-title {
        @apply font-semibold text-blackText flex w-full justify-center;
        font-size: 15px;
        margin-bottom: 20px;
      }

      .slide-range {
        @apply flex w-full justify-center;
        margin-top: 20px;
        padding: 0 50px;

        .ant-slider {
          max-width: 600px;
          width: 100%;
        }
      }

      .question-options {
        @apply flex flex-col;
        margin: 15px auto;
        width: fit-content;
        max-width: 100%;

        .answer-item {
          @apply flex w-full  cursor-pointer;
          border: solid 1px $qrs-gray;
          height: auto;
          margin-bottom: 10px;
          color: $qrs-black;
          border-radius: 5px;
          flex: none;
          margin-right: 5px;
          min-width: 120px;

          .letter-block {
            @apply flex font-semibold justify-center items-center h-auto;
            border-right: solid 1px $qrs-gray;
            padding: 6px 12px;

            img {
              width: 18px;
            }
          }

          .text {
            @apply flex w-full;
            padding: 6px 12px;
          }

          &:hover,
          &.active {
            border: $qrs-blue solid 1px;
            color: $qrs-blue;

            .letter-block {
              background: $qrs-blue;
              color: white;
              border-right-color: $qrs-blue;
            }
          }
        }
      }

      .question-counter {
        @apply flex ml-auto font-semibold;
        margin-top: 15px;
        font-size: 20px;
      }
    }
  }

  .result-container {
    @apply flex flex-col w-full;

    .result-header {
      @apply flex flex-col justify-center;
      padding: 15px;

      .logo {
        margin-bottom: 7px;
        height: 70px;
      }

      .text {
        @apply flex flex-col items-center font-semibold justify-center;
        color: $qrs-blue;
        font-size: 15px;
        margin-bottom: 20px;
      }

      .sub-text {
        @apply flex flex-col;
        font-size: 14px;
        color: #505f70;
        max-width: 600px;
        margin: 0 auto;

        span {
          margin-bottom: 30px;
        }
      }
    }

    .result-body {
      @apply flex flex-col w-full;
      margin-top: 30px;
      padding: 0px 25px;

      .heading {
        @apply flex w-full font-semibold text-blackText;
        font-size: 15px;
      }

      .list {
        @apply flex flex-col w-full;
        margin: 7px 0px 30px 25px;
        font-size: 14px;
        color: #505f70;
        list-style: disc;
      }

      .list-box-container {
        @apply flex justify-between flex-wrap;
        padding: 15px;

        .list-box {
          @apply flex w-full relative items-center;
          max-width: 276px;
          height: 60px;
          padding: 10px 15px;
          border-radius: 5px;
          border: solid 1px #b2bbc7;
          margin-bottom: 12px;
          flex: none;

          .dot {
            margin-right: 10px;
            position: absolute;
            left: -8px;
          }

          .text {
            @apply flex;
            font-size: 14px;
            color: #505f70;
          }
        }
      }
    }
  }

  .application-footer {
    @apply flex w-full;
    margin-top: 36px;

    @media (max-width: $mobile) {
      &.finished-result {
        @apply flex-col;

        .application-btn {
          @apply w-full ml-0;

          &:first-child {
            margin-bottom: 10px;
          }
        }
      }
    }
  }

  .application-btn {
    @apply flex justify-center items-center cursor-pointer;
    height: 32px;
    wdith: fit-content;
    background: $qrs-blue;
    color: white;
    font-size: 15px;
    padding: 6px 36px;

    border-radius: 23px;

    &:disabled {
      background: $qrs-gray;
    }
  }
}

.talenthub-landing-container {
  @apply flex w-full justify-center;

  @media (max-width: $small-mobile) {
    padding: 10px;
  }

  .talenthub-landing {
    @apply flex flex-col w-full;
    max-width: 837px;

    .banner {
      @apply flex flex-col relative;
      height: 336px;
      border-radius: 10px;
      margin-bottom: 15px;
      background: $qrs-blue;
      padding: 15px 20px;

      @media (max-width: $small-mobile) {
        @apply justify-center;
        height: auto;

        .bg {
          display: none;
        }
      }

      .bg {
        position: absolute;
        top: 0px;
        left: 0px;
        object-fit: cover;
        z-index: 1;
      }

      .logo {
        @apply self-start relative;
        z-index: 2;
      }
    }

    .content-info {
      @apply flex flex-col w-full;

      .item-info {
        @apply flex flex-col w-full;
        margin-bottom: 20px;

        .title {
          @apply flex font-semibold;
          color: $qrs-blue;
          font-size: 18px;
          margin-bottom: 12px;
        }

        .desc {
          @apply flex;
          color: $qrs-black;
        }
      }
    }

    .list-info {
      @apply flex w-full items-center;
      border-radius: 10px;
      padding: 15px 25px;

      &.banner-type {
        @apply bg-qureosSecondary;
        margin-bottom: 15px;

        .text {
          @apply flex font-semibold;
          color: white;
          font-size: 18px;
        }
      }

      &.with-icon {
        padding: 20px;
        margin-bottom: 10px;
        border: solid 1px $qrs-gray;

        .icon {
          margin-right: 24px;
        }

        .text-details {
          .title {
            @apply font-bold text-blackText;
            font-size: 15px;
            margin-bottom: 4px;
          }

          .desc {
            @apply text-blackText;
            font-size: 15px;
          }
        }
      }
    }

    .footer-box {
      @apply flex w-full;
      margin-top: 20px;

      .footer-btn {
        @apply flex justify-center items-center cursor-pointer;
        height: 36px;
        width: fit-content;
        padding: 6px 20px;
        font-size: 18px;
        @apply bg-qureosPrimary;
        color: white;
        border-radius: 23px;
      }
    }
  }
}

.talenthub-overview-container {
  @apply flex w-full justify-center;

  @media (max-width: $mobile) {
    padding: 10px;
  }

  .talenthub-overview {
    @apply flex flex-col w-full;
    max-width: $qrs-col-8;

    .overview-header {
      @apply flex flex-wrap sm:flex-nowrap;
      margin-bottom: 40px;

      .overview-details {
        @apply flex flex-col w-full flex-none sm:flex-auto;
        max-width: $qrs-col-6;
        margin-right: 12px;
        margin-bottom: 10px;

        .title {
          @apply flex font-semibold text-blackText;
          font-size: 18px;
          margin-bottom: 3px;
        }

        .sub-title {
          font-size: 15px;
          color: #505f70;
        }
      }

      .header-action {
        @apply flex w-full items-center justify-end;
        max-width: $qrs-col-2;
        flex: none;

        @media (max-width: $desktop-1) {
          width: fit-content;
        }

        .icon {
          margin-right: 5px;
        }

        .action-btn {
          @apply flex justify-center items-center font-semibold text-white cursor-pointer;
          height: 30px;
          border-radius: 33px;
          font-size: 15px;
          padding: 5px 15px;
        }
      }
    }

    .overview-body {
      @apply flex flex-col w-full;

      .heading {
        @apply flex w-full;
        margin-bottom: 25px;

        .title {
          @apply font-semibold text-blackText;
          font-size: 18px;
        }

        .overview-tab {
          @apply flex  ml-auto relative;
          background: var(--theme-primary-light);
          border-radius: 40px;
          height: 25px;
          overflow: hidden;

          .tab-item {
            @apply flex relative;
            width: fit-content;
            height: 100%;
            z-index: 1;
            border-radius: 40px;
            padding: 2px 10px;
            cursor: pointer;
            color: var(--theme-primary);

            &.active {
              background: var(--theme-primary);
              color: white;
              z-index: 2;
              cursor: default;
            }
          }
        }
      }

      .body-item {
        @apply flex flex-col;

        .cohort-bar {
          margin-bottom: 16px;
        }
      }
    }
  }
}

.call-landing-page {
  @apply flex flex-col w-full justify-center items-center;

  .call-landing {
    @apply flex flex-col w-full;
    max-width: $qrs-col-5;

    .call-landing-box {
      @apply flex flex-col w-full justify-center items-center;
      border-radius: 10px;
      border: solid 1px $qrs-gray;
      padding: 15px 0px;
      margin-bottom: 15px;

      .icon {
        margin-bottom: 7px;
      }

      .message {
        @apply flex font-semibold text-center;
        width: fit-content;
        font-size: 15px;
        color: $qrs-blue;
        margin-bottom: 20px;
      }

      .sub-message {
        font-size: 14px;
        color: #505f70;
      }

      .list-message {
        @apply flex flex-col;

        .title {
          @apply font-semibold;
          color: #505f70;
          font-size: 14px;
        }

        ul {
          @apply flex flex-col;
          list-style: disc;
          padding-left: 30px;

          li {
          }
        }
      }
    }

    .action-btn {
      @apply flex justify-center items-center cursor-pointer;
      height: 32px;
      width: fit-content;
      background: $qrs-blue;
      color: white;
      font-size: 15px;
      padding: 6px 25px;
      border-radius: 23px;
    }
  }
}

.createEventSidebar {
  background-color: #fcfcfc;
  height: calc(100vh - 64px);
  transition: 0.5s;
  @media only screen and (max-width: 667px) {
    width: 0px !important;
    position: fixed;
    overflow: auto;
    z-index: 100;
    left: -40px;
  }
  &.expand {
    left: 0;
    transition: 0.5s;
    width: 18rem !important;
  }
  & .eventActiveStep {
    background-color: #f3f4f6;
  }
  & .eventStep {
    cursor: pointer;
    border-radius: 5px;
    &:hover {
      background-color: #f3f4f6;
    }
    &:focus {
      transform: scale(0.9);
    }
    & .eventNotCompleteIcon {
      color: #8d98a6;
    }
  }
  & .text-purple-event {
    color: $qrs-purple;
  }
  & .saveEventSidebar {
    color: #555f6d;
    font-size: 14px;
    cursor: pointer;
  }
}
.eventStepContent {
  & p {
    color: #535f6e;
  }
  overflow: auto;
  height: calc(100vh - 141px);
  & ~ .flex .eventStepButton {
    border-radius: 20px;
    padding: 5px;
    cursor: pointer;
    border: 1px solid $qrs-purple;
    &:last-child {
      color: white;
    }
  }
  & ~ .eventContentProgressBar {
    position: relative;
    & .eventContentButtonHr {
      position: absolute;
      z-index: 10;
      border-color: $qrs-purple;
      border-width: 2px;
    }
    & .eventContentButtonHrIncomplete {
      border-color: #edeef0;
      z-index: 5;
      border-width: 2px;
      position: absolute;
      top: 0;
    }
  }
}
.eventCardParent {
  padding: 10px;
  border-radius: 10px;
  border: 2px solid #d7dee7;
  &.active {
    border: 2px solid $qrs-purple;
  }
  &.disabled {
    &:hover {
      border-color: #d7dee7;
      cursor: initial;
    }
  }
  &:hover {
    cursor: pointer;
    border-color: $qrs-purple;
  }
  & .eventCardAvatar {
    background: #f5effd;
    border-radius: 8px;
    padding: 7px;
    color: $qrs-purple;
    font-weight: 600;
    font-size: 18px;
  }
}
.radioButtonInputParent {
  border: 2px solid #d7dee7;
  height: 60px;
  border-radius: 5px;
  position: relative;
  &.active {
    border: 2px solid $qrs-purple;
  }
  & .radiobuttonIcon {
    position: absolute;
    width: 20px;
    background: #e7ecf2;
    left: 13px;
    color: #8f98a5;
  }
  & .radioButtonInput {
    background: #f5effd;
    padding: 11px 15px;
    align-items: center;
    display: flex;
    height: 100%;
    border-radius: 5px 0 0 5px;
    &.disabledOrNotSelected {
      background: #e7ecf2;
    }
  }
}
.cohortTextarea {
  border: 2px solid #d7dee7 !important;
  background: #fff !important;
  min-height: initial !important;
}

.label-light {
  color: $qrs-label-light;
}

.customInputField {
  & .relative.rounded-md.shadow-sm {
    border: 2px solid $qrs-purple;
  }
  & .inputPrepend {
    background: $qrs-purple-light;
    padding: 5px;
    border-radius: 5px 0 0 5px;
  }
  & #apprenticeValue {
    padding-left: 3rem;
    background: white;
    border-color: transparent;
  }
}
.customCounter {
  border: 2px solid $qrs-border-light;
  border-radius: 5px;
  padding: 10px;
  color: #6d7a89;
  & svg {
    color: #8d98a6;
  }
}
.cohortRightSidebar {
  @media only screen and (min-width: 667px) {
    padding: 20px 20px 20px 40px;
    border-left: 2px solid #edeef0;
    height: calc(100vh - 183px);
  }
  & .tableHeader {
    background: #f4f5f7;
    padding: 10px 15px;
    border: 1px solid $qrs-border-light;
    border-radius: 5px;
  }
  & .tableData {
    & .tableValue {
      border: 0.5px solid $qrs-border-light;
      border-radius: 5px;
      padding: 5px;
    }
  }
}
.cohortSidebarValue {
  border: 0.5px solid $qrs-border-light;
  border-radius: 5px;
  padding: 10px;
  color: #535f6e;
}
.cohortVideoCallButtons {
  transition: 0.3s;
  border-radius: 5px;
  border: 1px solid;
  padding: 5px 10px;
  &:hover {
    transform: scale(1.05);
    transition: 0.3s;
  }
  &.active {
    transform: scale(1.05);
    transition: 0.3s;
  }
}
.dateTimePickerDisabled {
  & input {
    background: transparent !important;
  }
}
.cohortFeedbackCommentEllipsis {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
.cohortCompanyDetailAvatar {
  background: #43347a;
  border-radius: 50%;
  width: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.cohortCompanyOverlayParentDiv {
  & .pill {
    border: 1px solid;
    border-radius: 17px;
    padding: 5px 20px;
  }
}
.projectTasksAccordion {
  border: 1px solid $qrs-border-light;
  border-radius: 5px;
  & .ant-collapse-item {
    border: 1px solid $qrs-border-light;
  }
  & .ant-collapse-header {
    @media only screen and (min-width: 667px) {
      height: 48px;
    }
    font-weight: 600;
    border: none;
    border-radius: 5px 5px 0 0 !important;
    background-color: white;
    & .ant-collapse-extra {
      @media only screen and (max-width: 667px) {
        float: unset !important;
        margin-top: 10px;
      }
      & .duration {
        background-color: var(--theme-primary-light);
        border-radius: 5px;
        color: $qrs-purple;
        padding: 2px 8px;
      }
      & .edit {
        color: $qrs-label-light;
      }
    }
  }
}
.link-purple {
  color: $qrs-purple;
}
.submitReviewParentDiv {
  & .header {
    background: var(--theme-primary);
    padding: 10px 20px;
  }
  & .stepsParent {
    & .ant-steps-item-title {
      margin-bottom: 13px;
      width: 100%;
      & .details {
        height: 28px;
      }
    }
    & .ant-steps-item-description {
      border-bottom: 2px solid $qrs-border-light;
      margin-bottom: 30px;
      padding-bottom: 34px;
    }
    & .ant-steps-item {
      &:last-child {
        & .ant-steps-item-description {
          border-bottom: none;
        }
      }
    }
    & .guide {
      background-color: #d1fae5;
      border-radius: 5px;
      padding: 2px 10px;
      color: #065f46;
    }
    & .duration {
      background-color: var(--theme-primary-light);
      border-radius: 5px;
      color: $qrs-purple;
      padding: 2px 10px;
    }
    & .ant-steps-item-tail {
      &:after {
        background-color: $qrs-border-light;
      }
    }
  }
  & .stepsIcon {
    background: var(--theme-primary-light);
    padding: 5px;
    border-radius: 5px;
  }
  & .contentSection {
    padding: 10px 20px;
  }
  & .submitReviewImageMobile {
    & img {
      max-width: 100px;
      border-radius: 10px;
    }
    & .userImageCohortFinalStep {
      &.avatar {
        background: #1e3a8a;
        color: white;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 44px;
        font-weight: 600;
        text-transform: uppercase;
        width: 100px;
        height: 100px;
        border-radius: 10px;
      }
    }
  }
  & .submitReviewImage {
    & .userImageCohortFinalStep {
      &.ava tar {
        background: #1e3a8a;
        color: white;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 44px;
        font-weight: 600;
        text-transform: uppercase;
      }
      border-radius: 10px;
      position: absolute;
      width: 100px;
      height: 100px;
      top: 22px;
      right: 25px;
      @media only screen and (max-width: 667px) {
        top: 71px;
        right: -92px;
      }
    }
    & div {
      position: absolute;
      top: 65px;
      right: -178px;
      @media only screen and (max-width: 667px) {
        top: 112px;
        right: -283px;
      }
    }
  }
  & .socialShare {
    & div {
      cursor: pointer;
      border-radius: 5px;
      padding: 0px 10px;
    }
  }
}
.certificateImageParentDivPublic {
  border: 1px solid;
  border-radius: 10px;
  width: fit-content;
  & div {
    border-radius: 7px 0 0 7px;
    & img {
      max-width: 100px;
      @media only screen and (max-width: 667px) {
        max-width: 60px;
      }
    }
  }
}
.certificateImageParentDiv {
  border: 1px solid $qrs-purple;
  border-radius: 10px;
  width: fit-content;
  & div {
    background: $qrs-purple;
    border-radius: 7px 0 0 7px;
    & img {
      max-width: 100px;
      @media only screen and (max-width: 667px) {
        max-width: 60px;
      }
    }
  }
}
.ck-editor__editable_inline {
  min-height: 100px;
}
.ant-picker,
.ant-select-selector {
  border-radius: 5px !important;
  border-color: #d7dee7 !important;
}

.dateTimePicker {
  & input {
    color: #6d7a89;
  }
}
.antdSelectUI {
  & input {
    &:focus {
      --tw-ring-color: none;
    }
  }
  & .ant-select-single:not(.ant-select-customize-input),
  & .ant-select-selector,
  & input {
    height: 50px !important;
    align-items: center;
  }
}
.languageSelect {
  @extend .antdSelectUI;
}
.customAntdArrow {
  & .ant-select-arrow {
    top: 38%;
    right: 8px;
    width: 20px;
  }
}
@media screen and (max-width: 767px) {
  .sidebarStyle {
    -webkit-transform: translate3d(0px, 0px, 0px);
    transform: translate3d(0px, 0px, 0px);
    visibility: visible;
    transition: all 0.3s ease-in-out 0s;
    -webkit-transition: all 0.3s ease-in-out 0s;
  }
  .closed.sidebarStyle {
    -webkit-transform: translate3d(-100%, 0px, 0px);
    transform: translate3d(-100%, 0px, 0px);
    visibility: visible;
  }
}
