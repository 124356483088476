$qrs-blue: var(--theme-primary);
$qrs-light-blue: var(--theme-primary-light);
$qrs-black: #1a1e2c;
$qrs-black-2: #1c1f2a;
$qrs-black-3: #1b1f2b;
$qrs-gray: #d7dee7;
$qrs-red: #dd3d53;
$qrs-dirty-white: #f8f8f8;
$qrs-white-blue: #f7fbff;

$qrs-green: #0a1a70;
$qrs-grey-200: #e5e7eb;

$qrs-primary: var(--theme-primary);

$almostMobile: 512px;

$tablet: 1024px;
$big-tablet: 1056px;
$medium-tablet: 960px;
$mobile: 812px;
$small-mobile: 420px;
$xs-mobile: 360px;
$landscape-min: 320px;
$landscape-max: 411px;
$long-screen: 768px;

$screen-h1: 768px;
$screen-h2: 824px;
$screen-h3: 974px;

$screen-w3: 1430px;

$desktop-1: 1024px;
$desktop-2: 1280px;
$desktop-3: 1360px;
$desktop-4: 1366px;
$desktop-5: 1440px;
$desktop-6: 1600px;
$desktop-7: 1680px;
$desktop-8: 1920px;

$delay: 0.3s;

$qrs-col-1: 122px;
$qrs-col-2: 256px;
$qrs-col-3: 390px;
$qrs-col-4: 524px;
$qrs-col-5: 658px;
$qrs-col-6: 792px;
$qrs-col-7: 926px;
$qrs-col-8: 1060px;
$qrs-col-9: 1194px;
$qrs-col-10: 1328px;
$qrs-col-11: 1462px;
$qrs-col-12: 1600px;

// Drawer Global: Student & Corporate HTML structure difference
@mixin workspace-drawer {
  .qrs-right-drawer-overlay {
    position: fixed;
    top: -9999px;
    left: 0px;
    left: 0px;
    height: 100%;
    width: 100%;
    background: #0000004d;
    z-index: 199;
    opacity: 0;
    transition: opacity 0.3s ease-in;
  }

  .qrs-right-drawer {
    position: fixed;
    top: 0px;
    right: -100%;
    height: 100%;
    width: 100%;
    // max-width: 0px;
    z-index: 200;
    transition: all 0.3s ease-in;

    .right-drawer {
      @apply flex flex-col w-full h-full bg-white;
      transition: all 0.3s ease-in;
      position: absolute;
      top: 0px;
      right: -$qrs-col-4;
      max-width: $qrs-col-4;
      border: solid 1px $qrs-gray;
      box-shadow: #3e445726 -10 0 10;
      z-index: 2;

      .drawer-close {
        position: absolute;
        top: 24px;
        right: 12px;
        cursor: pointer;
      }

      .drawer-header {
        @apply flex w-full items-center;
        height: 50px;
        padding: 12px;

        &.full {
          height: 85px;
          @apply items-end;
          border-bottom: solid 1px $qrs-gray;
          flex: none;
        }

        .title {
          @apply block font-semibold text-blackText;
          font-size: 15px;
          width: calc(100% - 50px);
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
        }

        .drawer-close {
          @apply flex ml-auto cursor-pointer;
        }
      }

      .drawer-body {
        @apply flex flex-col w-full items-center;

        textarea {
          resize: none;
          min-height: auto;
          background: transparent;

          &:focus {
            border: 1px solid #c5e4fd;
          }
        }

        .drawer-tab-container {
          @apply flex flex-col w-full;

          .tab-header {
            @apply flex w-full;
            border-bottom: solid 1px $qrs-gray;
            height: 35px;

            .tab-menu {
              @apply flex items-center text-blackText;
              width: fit-content;
              margin: 0px 12px -1px 12px;
              cursor: pointer;
              font-size: 15px;

              &.selected {
                cursor: default;
                color: $qrs-blue;
                border-bottom: solid 2px $qrs-blue;
              }
            }
          }

          .tab-body {
            @apply flex flex-col relative;
            padding: 12px;
            overflow: auto;
            height: calc(100vh - 85px);

            .text-header {
              @apply flex  text-blackText;
              font-size: 15px;
              margin-bottom: 10px;
            }

            .expectations-box {
              border: solid 1px #d7dee7;
              border-radius: 10px;
              padding: 10px;
            }

            .text-content {
              @apply flex;
              font-size: 13px;
              color: #576271;
            }

            .table-content {
              @apply flex flex-col;
              flex: none;

              &.table-container {
                border-radius: 10px;
                border: solid 1px $qrs-gray;
                overflow: hidden;
                margin-bottom: 20px;

                .table-item {
                  border-bottom: none !important;
                  border-top: solid 0.5px #d7dee7;
                  padding: 12px;

                  &:first-child {
                    border: none !important;
                  }
                }
              }

              .table-item {
                @apply flex items-center;
                border-bottom: solid 0.5px $qrs-gray;
                padding: 12px 0px;

                .icon {
                  &.file {
                    width: 13px;
                    height: 16px;
                    margin-right: 6px;
                  }
                }

                .time {
                  @apply flex;
                  width: 75px;
                  margin-right: 12px;
                  flex: none;

                  .icon {
                    margin-right: 5px;
                  }

                  span {
                    font-size: 12px;
                    color: $qrs-black;
                  }
                }

                .activity-info {
                  @apply ml-auto;

                  .status-item {
                    @apply flex justify-center items-center;
                    width: 75px;
                    height: 25px;
                    font-size: 11px;
                    color: white;

                    &.inprogress {
                      background: $inprogress;
                    }
                    &.revise {
                      background: $revise;
                    }
                    &.submitted {
                      background: $submitted;
                    }
                    &.stuck {
                      background: $stuck;
                    }
                    &.completed {
                      background: $completed;

                      .rate-stars {
                        margin: 0px 2px;
                      }
                    }
                  }

                  .file-item {
                    @apply flex cursor-pointer;
                    max-width: 180px;

                    @media (max-width: $small-mobile) {
                      max-width: 80px;
                    }

                    .icon {
                      margin-right: 10px;
                    }

                    .file-name {
                      @apply block;
                      width: 200px;
                      white-space: nowrap;
                      overflow: hidden;
                      text-overflow: ellipsis;

                      @media (max-width: $small-mobile) {
                        width: 125px;
                      }
                    }
                  }
                }

                .text {
                  @apply block text-blackText;
                  font-size: 14px;
                  overflow: hidden;
                  text-overflow: ellipsis;
                  white-space: nowrap;
                  width: 250px;

                  @media (max-width: $small-mobile) {
                    width: 140px;
                  }
                }

                .dp-item {
                  margin-right: 18px;
                }

                .date-item {
                  @apply flex justify-center items-center text-blackText;
                  font-size: 14px;
                  width: 100px;
                  margin-right: 18px;
                }

                .push-right {
                  @apply ml-auto;
                }
              }
            }
          }
        }

        .wizard-container {
          padding: 12px;

          .wizard-content {
            .form-input {
              margin-top: 0px !important;
              height: fit-content;

              textarea {
                max-height: 300px;
              }
            }

            .content-btn {
              margin-top: 20px;
              align-self: flex-start;
              justify-content: center;
              height: 30px;
              font-size: 15px;
              padding: 0px;
              width: fit-content;
              min-width: 100px;
            }
          }
        }

        .updates-container {
          width: 100%;
        }
      }
    }
  }
}

@mixin workspace-drawer-open {
  .qrs-right-drawer-overlay {
    opacity: 1;
    top: 0px;

    @media (max-width: $mobile) {
      opacity: 0;
    }
  }

  .qrs-right-drawer {
    right: 0px;
    // max-width: 100%;
    .right-drawer {
      right: 0px;
    }
  }
}
