.pipeline-drawer {
  .ant-drawer-content-wrapper {
    .ant-drawer-content {
      .ant-drawer-wrapper-body {
        .ant-drawer-header {
          padding-bottom: 0;
          border-bottom: none;
        }
        .ant-drawer-body {
          padding-top: 0.5em;
          padding-bottom: 0.5em;
        }
      }
    }
  }
}

.drawer-panel {
  .ant-collapse-content {
    .ant-collapse-content-box {
      padding: 0;
    }
  }
}
.outreach-title {
  .ant-collapse-content-box {
    padding-top: 0 !important;
  }
}
