@import './variables';

.user-menu-box {
  width: 311px;
  height: auto;
  background: #ffffff;
  box-shadow: 0px 0px 30px #6a708026;
  border-radius: 10px;
  @apply flex flex-col;

  .user-info {
    @apply flex;
    padding: 23px 0px 22px 13px;
    border-bottom: 0.5px solid #d6dee8;

    .profile-dp {
      margin-right: 30px;
      position: relative;
      flex: none;

      img {
        width: 70px;
        height: 70px;
        object-fit: cover;
        border-radius: 70px;
      }

      .upload-icon {
        width: 42px;
        height: 42px;
        background: #ffffff;
        border-radius: 42px;
        overflow: hidden;
        position: absolute;
        left: 50px;
        bottom: -10px;
        padding: 5px;
        cursor: pointer;

        img {
          width: 32px;
          height: 32px;
          box-shadow: 0px 0px 3px rgba(26, 30, 44, 0.16078);
          padding: 5px;
          border-radius: 100%;
          object-fit: scale-down;
        }
      }
    }

    .profile-details {
      @apply flex flex-col w-full;

      .name {
        @apply text-qureosBlack font-semibold;
        font-size: 15px;
        margin-bottom: 8px;
      }

      .sub-info {
        @apply text-qureosBlack font-light;
        margin-bottom: 10px;
        font-size: 15px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        width: calc(100% - 60px);
      }
    }
  }

  .user-setting {
    padding: 20px;
    @apply flex justify-between;
    border-bottom: 0.5px solid #d6dee8;

    .action-btn {
      @apply font-semibold cursor-pointer;
      width: 127px;
      height: 50px;
      background: var(--theme-primary-light);
      border-radius: 25px;
      padding: 15px;
      text-align: center;
      font-size: 15px;
      color: var(--theme-primary);

      &:hover {
        background: var(--theme-primary);
        color: white;
      }
    }
  }

  .footer-box {
    padding: 15px;
    @apply flex justify-center items-center;

    .footer-item {
      font-size: 12px;
      color: #8898aa;

      &.dot {
        width: 4px;
        height: 4px;
        margin: 0px 16px;
        background: #aebbc9;
        border-radius: 4px;
      }
    }
  }
}

.more-option {
  @apply cursor-pointer;

  &.profile {
    flex: none;
    // @apply mr-8;

    img {
      height: 40px;
      width: 40px;
      object-fit: cover;
      border-radius: 40px;
      overflow: hidden;

      @media (max-width: $mobile) {
        // width: 55px;
      }
    }
  }
}

.dummy-account-container {
  @apply flex flex-wrap justify-center;
  padding: 10px;

  .user-box {
    &:hover {
      @apply bg-qureosBlue;

      .user-info {
        color: white;
      }
    }

    @apply flex cursor-pointer;
    padding: 5px;
    background: #eee;
    border-radius: 10px;
    margin: 2px;
    min-width: 200px;

    .user-photo {
      width: 20px;
      height: 20px;
      border-radius: 20px;
      margin-right: 15px;
    }

    .user-info {
      @apply flex font-semibold text-qureosBlack;
      font-size: 15px;
    }
  }
}
