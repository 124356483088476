.generic-slide-container {
  @apply w-full;
  padding-left: 20px;

  @media (max-width: $mobile) {
    padding-left: 20px;
  }

  @media (max-width: $small-mobile) {
    padding-left: 0px !important;
    margin-left: 0px !important;
  }

  .slider-case {
    margin-bottom: 40px;

    .slider-header {
      display: flex;
      margin-bottom: 25px;

      .title {
        font-size: 18px;
        font-weight: 600;
        @apply text-blackText;
        margin-right: 20px;
      }

      .slider-tools {
        .pagination {
          li {
            a {
              width: 28px;
              height: 28px;

              img {
                width: 8px;
              }
            }
          }
        }
      }
    }

    .simple-slider {
      @apply flex;

      .arrow {
        @apply flex items-center justify-center border border-gray-500 rounded-full mx-1 text-sm self-center select-none;
        transition: all 0.3s ease;
        width: 34px;
        height: 34px;
        flex: none;
        margin: 0px 10px;
      }
    }

    .slider-body {
      &.no-slide {
        .slider-content {
          flex-wrap: wrap;

          @media (max-width: $small-mobile) {
            justify-content: center;
            margin-left: -15px;
          }
        }
      }

      .slider-content {
        width: 100%;
        display: flex;
        overflow: auto;
        overflow-y: hidden;
        scroll-behavior: smooth;
        padding-bottom: 20px;
        padding-top: 25px;
        margin-top: -10px;
        max-width: calc(100vw - 220px);

        @media (max-width: $big-tablet) and (min-width: $medium-tablet) {
          max-width: calc(100vw - 100px);
        }
      }
    }
  }

  &.dashboard {
    .slider-case {
      overflow: hidden;
      height: 280px;

      .slider-body {
        padding: 0px 0px 10px 0px;

        .slider-content {
          padding-top: 22px !important;
          margin-top: -27px;
          padding-bottom: 20px !important;
        }
      }
    }
  }
}

.x-overflow {
  overflow-y: hidden;
  overflow-x: auto;
}
